import Phaser from 'phaser';
export default class Background extends Phaser.GameObjects.Group {

    constructor(config) {
        super(config.scene);

        config.scene.add.existing(this);

        this.centreX =config.x;
        this.centreY =config.y;
        this.layers = [];

        this.layers[3] = this.create(this.centreX, this.centreY, 'bg'+4);
        this.layers[2] = this.create(this.centreX, this.centreY, 'bg'+3);

        this.layers[4] = this.scene.add.tileSprite(this.centreX , 160, 1080, 32, "bg_prop1");
        this.layers[5] = this.scene.add.tileSprite(this.centreX , 200, 1080, 32, "bg_prop2");
        this.add(this.layers[4]);
        this.add(this.layers[5]);

        this.layers[1] = this.create(this.centreX, this.centreY, 'bg'+2);
        this.layers[0] = this.create(this.centreX, this.centreY, 'bg'+1);



        // this.create(this.centreX, this.centreY, "screen_screenadjust");
        this.superFinish = this.create(this.centreX, this.centreY, "bg_super2").setScale(2);
        this.superFinish.setAlpha(0);


    }
//

    update(time, delta, cameraPos, scene) {
            let midX = cameraPos.x - this.centreX;
            let midY = cameraPos.y - this.centreY;

            this.layers[0].x =  this.centreX; 
            //ground, doesn't slide. optional line.

            this.layers[1].x =  this.centreX + midX * 0.2;
            this.layers[1].y =  this.centreY + midY * 0.2;

            this.layers[2].x =  this.centreX + midX * 0.4;
            this.layers[2].y =  this.centreY + midY * 0.4;

            this.layers[3].x =  this.centreX + midX * 0.6;
            this.layers[3].y =  this.centreY + midY * 0.6;


            this.layers[4].x -= 0.25;
            if(this.layers[4].x <= this.centreX - 36){
                this.layers[4].x = this.centreX;
            }

            this.layers[5].x += 0.25;
            if(this.layers[5].x >= this.centreX + 36){
                this.layers[5].x = this.centreX;
            }


            this.superFinish.x = cameraPos.x;
            this.superFinish.y = cameraPos.y;
            // this.superFinish.setScale(2.4/this.scene.cameras.main.zoom);
            // console.log(this.scene.cameras.main.zoom);





    }
    superFinishBg() {

        this.superFinish.setAlpha(1);
        this.superFinish.setScale(2.4/this.scene.cameras.main.zoom);
          this.scene.tweens.add({
            // paused: true,
            targets: this.superFinish,
            
            // alpha: 0,
            // duration: 600,
            // ease: 'Power2',


            scaleX: { value: 6, duration: 1000 },
            scaleY: { value: 6, duration: 1000 },
            rotation: { value: 1, duration: 800 },
            alpha: { value: 0, duration: 300, ease: 'Power2', delay: 700 },
            onComplete: function() {

            }


        // this.scene.time.delayedCall(1 * 500, function() {
        //     // this.superFinish.setAlpha(0);

        //     this.scene.tweens.add({
        //     // paused: true,
        //     targets: this.superFinish,
            
        //     // alpha: 0,
        //     // duration: 600,
        //     // ease: 'Power2',


        //     scaleX: { value: 10, duration: 1000, ease: 'Power2' },
        //     scaleY: { value: 10, duration: 1000, ease: 'Power2' },
        //     alpha: { value: 0, duration: 1000, ease: 'Power2' },
        //     onComplete: function() {

        //     }

        // });

        }, [], this);


    }

}
