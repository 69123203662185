import Phaser from 'phaser';
import {
  CST
} from '../CST';
import makeAnimations from '../Helpers/animations';

export class PreloadScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.LOAD
    });
  }

  init(passedData) {}

  preload() {

    this.HEIGHT = this.sys.game.config.height;
    this.WIDTH = this.sys.game.config.width;
    this.CENTER_POINT_X = this.WIDTH / 2;
    this.CENTER_POINT_Y = this.HEIGHT / 2;
    this.PRELOAD_TIMER = 100;

    //Add black BG
    const background = this.add.graphics();
    background.fillStyle(0x000000, 1);
    background.fillRect(0, 0, this.WIDTH, this.HEIGHT);

    let quad = {
      topLeftX: 150,
      topLeftY: 170,
      topRightX: 660 - 150,
      topRightY: 170,
      bottomLeftX: 150,
      bottomLeftY: 660 - 170,
      bottomRightX: 660 - 150,
      bottomRightY: 660 - 170
    };

    let preloader_graphic = this.add.image(330, 330, 'preloader');

    let preloader_graphic_height = preloader_graphic.height;

    /* Scene screens */
    this.load.spritesheet('throwback', './game/generic/throwback_title_uk.png', {
      frameWidth: 660,
      frameHeight: 660
    });
    this.load.spritesheet('press_btn', './game/generic/press.png', {
      frameWidth: 660,
      frameHeight: 48
    });

    this.load.spritesheet('screen_bg', './game/fairybread/images/screens/screen_bg.png', {
      frameWidth: 330,
      frameHeight: 330
    });
    this.load.image('screen_instruction1', './game/fairybread/images/screens/screen_instruction1.png');
    this.load.image('screen_instruction2', './game/fairybread/images/screens/screen_instruction2.png');
    this.load.image('screen_title', './game/fairybread/images/screens/screen_title.png');
    this.load.image('screen_ending', './game/fairybread/images/screens/screen_ending.png');
    this.load.spritesheet('title_croissant', './game/fairybread/images/screens/title_croissant.png', {
      frameWidth: 340,
      frameHeight: 130
    });
    this.load.spritesheet('title_hotlight', './game/fairybread/images/screens/title_hotlight.png', {
      frameWidth: 340,
      frameHeight: 140
    });


    // reference

    /* Spritesheet characters */
    this.load.spritesheet('donut_small', './game/fairybread/images/character/donut_small.png', {
      frameWidth: 40,
      frameHeight: 40
    });
    this.load.spritesheet('donut_big', './game/fairybread/images/character/donut_big.png', {
      frameWidth: 40,
      frameHeight: 40
    });
    this.load.spritesheet('donut_mega', './game/fairybread/images/character/donut_mega.png', {
      frameWidth: 40,
      frameHeight: 40
    });

    this.load.spritesheet('chocolate', './game/fairybread/images/character/chocolate.png', {
      frameWidth: 16,
      frameHeight: 24
    });
    this.load.spritesheet('cupcake', './game/fairybread/images/character/cupcake.png', {
      frameWidth: 16,
      frameHeight: 28
    });
    this.load.spritesheet('icecream', './game/fairybread/images/character/icecream.png', {
      frameWidth: 14,
      frameHeight: 49
    });
    this.load.spritesheet('cookie', './game/fairybread/images/character/cookie.png', {
      frameWidth: 23,
      frameHeight: 17
    });

    this.load.spritesheet('crossboss', './game/fairybread/images/character/crossboss.png', {
      frameWidth: 100,
      frameHeight: 80
    });



    /* Items */
    // this.load.image('powerup_choctop', './game/fairybread/images/item/powerup_choctop.png');
    // this.load.image('powerup_rainbow', './game/fairybread/images/item/powerup_rainbow.png');
    // this.load.image('powerup_heart', './game/fairybread/images/item/powerup_heart.png');
    this.load.image('coin', './game/fairybread/images/item/coin.png');
    this.load.spritesheet('brickpushed', './game/fairybread/images/item/brickpushed.png', {
      frameWidth: 64,
      frameHeight: 64
    });
    this.load.spritesheet('items', './game/fairybread/images/item/items.png', {
      frameWidth: 16,
      frameHeight: 18
    });

    //platforms
    this.load.spritesheet('cloud', './game/fairybread/images/item/cloud.png', {
      frameWidth: 48,
      frameHeight: 16
    });
    this.load.image('moving', './game/fairybread/images/item/moving.png');

    /* Intro Dialogue */
    this.load.image('intro_bg', './game/fairybread/images/intro/intro_bg.png');
    this.load.image('intro_speech', './game/fairybread/images/intro/intro_speech.png');
    this.load.image('intro_letterbox', './game/fairybread/images/intro/intro_letterbox.png');
    this.load.image('intro_press', './game/fairybread/images/intro/intro_press.png');
    this.load.spritesheet('intro_people', './game/fairybread/images/intro/intro_extras.png', {
      frameWidth: 78,
      frameHeight: 22
    });
    this.load.spritesheet('intro_boi', './game/fairybread/images/intro/intro_boi.png', {
      frameWidth: 22,
      frameHeight: 22
    });



    /* Effects */
    // this.load.image('impact_particle', './game/fairybread/images/fx/impact_particle.png');
    this.load.image('broken_particle', './game/fairybread/images/fx/broken_particle.png');
    this.load.image('impact_particle', './game/fairybread/images/fx/impact_particle.png');

    this.load.image('bg1', './game/fairybread/images/bg/bg1.png');
    this.load.image('bg2', './game/fairybread/images/bg/bg2.png');
    this.load.image('bg3', './game/fairybread/images/bg/bg3.png');
    this.load.spritesheet('sprinkle_ball', './game/fairybread/images/fx/sprinkle_ball.png', {
      frameWidth: 25,
      frameHeight: 25
    });
    this.load.spritesheet('sprinkle_explode', './game/fairybread/images/fx/sprinkle_explode.png', {
      frameWidth: 25,
      frameHeight: 25
    });
    this.load.spritesheet('sprinkle_particle', './game/fairybread/images/fx/sprinkle_particle.png', {
      frameWidth: 8,
      frameHeight: 8
    });

    this.load.spritesheet('hotlight', './game/fairybread/images/fx/hotlight.png', {
      frameWidth: 32,
      frameHeight: 32
    });
    this.load.image('hotlight2', './game/fairybread/images/fx/hotlight2.png');

    //

    /* From boilerplate */


    // Tilemap with a lot of objects and tile-properties tricks
    // This contains atlas and tile map.

    this.load.tilemapTiledJSON('map1', './game/fairybread/images/tilemap/map1.json');
    this.load.tilemapTiledJSON('map2', './game/fairybread/images/tilemap/map2.json');
    this.load.tilemapTiledJSON('map3', './game/fairybread/images/tilemap/map3.json');


    // I load the tiles as a spritesheet so I can use it for both sprites and tiles,
    // // Normally you should load it as an image.
    this.load.spritesheet('tiles', './game/fairybread/images/tilemap/fbf_tile.png', {
      frameWidth: 64,
      frameHeight: 64
    });

    // this.load.spritesheet('tiles', './game/fairybread/images/tilemap/tiles.png', {
    //     frameWidth: 16,
    //     frameHeight: 16,
    //     spacing: 2
    // });




    //


    /* Hud */

    /* Font */
    this.load.bitmapFont('HalfBoldPixel-7', './game/generic/fonts/HalfBoldPixel-7.png', './game/generic/fonts/HalfBoldPixel-7.fnt');

    /* BGM */
    this.load.audio('bgm', './game/fairybread/sound_mp3/bgm/POL-magical-sun-long.mp3');

    this.load.audio('sad', './game/fairybread/sound_mp3/bgm/POL-spirits-dance-short.mp3');
    /* SFX */


    this.load.audio('stomp', './game/fairybread/sound_mp3/battle/plop1.mp3');
    this.load.audio('itemOut', './game/fairybread/sound_mp3/battle/plop2.mp3');
    this.load.audio('shoot', './game/fairybread/sound_mp3/battle/shoot.mp3');
    this.load.audio('jump', './game/fairybread/sound_mp3/battle/jump.mp3');
    this.load.audio('hop', './game/fairybread/sound_mp3/battle/hop.mp3');
    this.load.audio('grow', './game/fairybread/sound_mp3/battle/grow.mp3');
    this.load.audio('hurt', './game/fairybread/sound_mp3/battle/hurt.mp3');
    this.load.audio('die', './game/fairybread/sound_mp3/battle/die.mp3');
    this.load.audio('hit', './game/fairybread/sound_mp3/battle/hit.mp3');
    this.load.audio('diding', './game/fairybread/sound_mp3/battle/diding.mp3');
    this.load.audio('coin', './game/fairybread/sound_mp3/battle/coin.mp3');


    /* UI */


    this.load.on('progress', (value) => {
      //
      let preloader_mesh = this.make.mesh({
        key: 'preloader_mask',
        x: 0,
        y: (value * 320) - 320,
        //-320 ~ 0,
        vertices: [
          quad.topLeftX, quad.topLeftY,
          quad.bottomLeftX, quad.bottomLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topLeftX, quad.topLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topRightX, quad.topRightY
        ],
        uv: [0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0],
        add: false
      });

      preloader_graphic.mask = new Phaser.Display.Masks.GeometryMask(this, preloader_mesh);

    });

    this.load.on('complete', () => {
      //console.log('load complete');
      makeAnimations(this);
    });
  }

  create() {
    setTimeout(() => {
      this.preloaded();
    }, this.PRELOAD_TIMER);
    // console.log(this.cache);
  }

  preloaded() {
    this.scene.start(CST.SCENES.THROWBACK_TITLE, 'Throwback Title Scene');
  }
}
