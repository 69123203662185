import Phaser from 'phaser';
import { CST } from '../CST';

export class FighterbunTitleScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.GAME_TITLE });
    }

    create() {

        // this.mainImage = this.add.image(330,330,'screen_title');
        this.mainImage = this.add.sprite(330, 330, 'screen_title_spritesheet');
        this.mainChar = this.add.sprite(330, 440, 'fingerbun_v2').setScale(4);



        this.control = this.scene.get(CST.SCENES.CONTROL);


        this.anims.create({
            key: "intro",
            repeat: -1,
            frameRate: 15,
            delay: 100,
            frames: this.anims.generateFrameNumbers('screen_title_spritesheet', {
                frames: [0, 1, 2, 3, 4, 0, 0, 0, 5, 6, 7, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ]

            })

        });


        var m = {
            idle: [0, 0, 1, 2, 3, 4, 4, 5, 6, 7],
            sit: [9],
            jump: [8],
            i_punch: [40, 41, 42, 40, 0],
            i_kick: [43, 44, 45, 43, 0],
            s_punch: [50, 51, 52, 53],
            s_kick: [54, 55, 56, 57],
            j_punch: [47, 46, 8],
            j_kick: [49, 48, 8],
            sp1: [70, 71, 70, 71, 70, 71, 70, 71, 74, 72, 73, 72, 73, 72, 73, 72, 73, 72, 73, 72, 73],
            sp2: [0, 75, 76, 77, 78, 79, 78, 79, 78, 79, 78, 8, 8, 8],
            sp3: [80, 81, 81, 81, 82, 83, 84, 84, 1, 81, 81, 82, 83, 84, 84, 81, 81]

        };
        var intro_allmoves_frames = []
            .concat(m.idle)
            .concat(m.i_punch)
            .concat(m.idle)
            .concat(m.i_kick)
            .concat(m.idle)
            .concat(m.i_punch)
            .concat(m.i_kick)
            .concat(m.sit)
            .concat(m.s_punch)
            .concat(m.sit)
            .concat(m.s_kick)
            .concat(m.sit)
            // .concat(m.jump)
            // .concat(m.j_punch)
            // .concat(m.j_kick)
            .concat(m.idle)
            .concat(m.sp1)
            .concat(m.idle)
            .concat(m.sp2)
            .concat(m.idle)
            .concat(m.sp3)
            .concat(m.idle);

        this.anims.create({
            key: 'intro_allmoves',
            frames: this.anims.generateFrameNumbers('fingerbun_v2', {
                frames: intro_allmoves_frames
            }),
            frameRate: 15,
            repeat: -1
        });




        this.mainImage.anims.play("intro");
        this.mainChar.anims.play("intro_allmoves");

        // this.pressAnyKey();    
        // this.understood();    

    }

    update() {
        if (this.control.keys.punch.isDown || this.control.keys.kick.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;
                this.understood();

            }
        }
    }

    understood() {
        // this.scene.start(CST.SCENES.VS, this.keys);
        this.scene.start(CST.SCENES.INSTRUCTION, { isFromBeginning: true });

    }
    pressAnyKey() {
        this.input.keyboard.on('keydown', function(event) {
            this.understood();
        }, this);

    }
}