import React, { Component } from 'react';
import Row from './Row';
import commaNumber from 'comma-number';
import Close from '../Close';
import { toJS } from 'mobx';

export default class Leaderboard extends Component {

    constructor(props) {
        super(props);
    }

    render() {      
        return (
            <div className="window">
                <div className={`application ${this.props.window_state ? '' : 'application_close'}`}>

                    <div className="header">
                    <div className="lines"><hr/><hr/><hr/></div>
                    <div className="header_text">
                        <p>{this.props.header}</p>
                    </div>
                    { this.props.close ? <Close/> : null }
                    </div>

                    <div className="application_body">  
                        <div className="inner_application">
                            <h3 className="inner_application_headline_varient">High Scores</h3>

                            <table className="leaderboard" width="80%">
                                <tbody>
                                    {toJS(this.props.scores).map((e, i) => 
                                        <Row key={i} rank={e.rank} user={e.user} tag={e.gamer_tag} id={i} score={commaNumber(e.gamer_score)} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
