import Phaser from 'phaser';
export default class Bun extends Phaser.GameObjects.Sprite {

    // export default class Bun extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.ACCEL = 8000;
        this.MAX_VELOCITY_X = 4000;
        this.MAX_VELOCITY_Y = 4000;
        this.JUMP_VELOCITY_Y = -1400;


        this.prefix = config.prefix;
        this.enemyPrefix = config.enemyPrefix;

        // this.body.maxVelocity.x = this.MAX_VELOCITY_X;
        // this.body.maxVelocity.y = this.MAX_VELOCITY_Y;

        this.body.setCollideWorldBounds(true);
        // this.body.setBounce(0.8, 0.01);
        this.body.setBounce(0, 0.01);

        this.isCrouching = false;
        this.isBlocking = false;
        this.isGround = false;
        this.isControllable = false;
        this.lose = false;

        this.isAI = config.isAI;
        this.isDummy = config.isDummy;
        this.difficulty = config.difficulty;
        this.flipX = this.isAI;

        this.comboed = 0;

        this.isInvincible = false;
        this.isRaged = false;

        this.dPadCommand = ['none', 'none', 'none'];
        //

        this.SPECIAL_DEFAULT = { isExecuted: false, move: 'none', velY: 0, desc: 'none', keepMoving: false };
        this.special = { isExecuted: false, move: 'none', velY: 0, desc: 'none', keepMoving: false };

        //

        this.aiMoveTimer = 0;
        this.aiAttackTimer = 60 * 10;

        this.inRange = false;
        this.isAttacking = false;
        this.myAttackAlreadyLandedThisFrame = false; // true when this frame attack already landed 
        this.aiInput = 'none';
        this.aiPrevMovementInputDirection = 'none';

        this.on('animationstart', this.animStart, this);
        this.on('animationupdate', this.animUpdate, this);
        this.on('animationcomplete', this.animComplete, this);
        this.anims.play(this.prefix + '_jump', true);

        this.setScale(4);
        // this.setOrigin(0.5, 0.5);
        this.normalHitbox();


        //spark
        this.spark = config.scene.add.sprite(this.x, this.y, 'spark');
        this.spark.setScale(4);
        this.spark.setOrigin(0.5, 0.5);
        this.spark.alpha = 0;
        // this.spark.anims.play('sparking', true);

        //shadow
        this.shadow = config.scene.add.image(this.x, 626, 'shadow');
        this.shadow.setScale(2, 3);
        this.shadow.setOrigin(0.5, 0.5);

        this.shadow.setDepth(this.depth);
        this.setDepth(this.depth + 1);

        //beam
        this.beam = config.scene.add.sprite(0, 0, 'beam');
        this.beam.setScale(4);
        this.beam.setOrigin(0.5, 0.5);
        this.beam.setDepth(this.depth + 2);
        this.beam.isAI = this.isAI;
        this.beam.alpha = 0;

        this.beam.on('animationcomplete', function() {
            this.alpha = 0;
            this.scene.physics.world.disable(this);
        }, this.beam);
        this.beam.on('animationupdate', function() {

            this.myAttackAlreadyLandedThisFrame = false;

        }, this);



        var c;
        if (this.isAI) {
            c = 'enemy_';
        } else {
            c = 'player_';

        }

        this.sfx = {
            attack_normal: [ //includes woosh.
                config.scene.sound.add(c + 'atk_1'),
                config.scene.sound.add(c + 'atk_2'),
                config.scene.sound.add('woosh_1'),
                config.scene.sound.add('woosh_2')
            ],
            attack_special: {
                attack51: config.scene.sound.add('beam'),
                attack52: config.scene.sound.add(c + 'atk_3'),
                attack61: config.scene.sound.add(c + 'atk_4'),
                attack62: config.scene.sound.add(c + 'atk_5')
            },

            ouch: [ //includes pow
                config.scene.sound.add('smack_1'),
                config.scene.sound.add('smack_2'),
                config.scene.sound.add(c + 'ouch_3'),
                config.scene.sound.add(c + 'ouch_4'),
            ],
            //includes pow
            scream: config.scene.sound.add(c + 'scream'),

            block: config.scene.sound.add('block'),
            jump: config.scene.sound.add('jump')

        };



    }


    update(keys, time, delta, enemy, scene) {

        this.spark.setPosition(this.x, this.y);
        this.shadow.x = this.x;


        this.beam.flipX = this.flipX;
        if (this.flipX) {
            this.beam.setPosition(this.x - 400, this.y + 24);
        } else {
            this.beam.setPosition(this.x + 400, this.y + 24);

        }

        var ca = this.removePrefix(this.anims.currentAnim.key);

        this.updateFlip(enemy, ca);

        if (this.body.blocked.down) {
            this.isGround = true;
            this.shadow.setScale(4);

        } else {
            this.isGround = false;
            this.shadow.setScale(2, 3);
        }

        this.inRange = false;
        this.scene.physics.world.overlap(this, enemy, function() { this.inRange = true; }, null, this);

        var controlPressed = { direction: "none", button: "none" };
        if (!this.isAI) {
            controlPressed = this.humanControl(keys, time, delta);
            this.archiveDPadCommand(controlPressed);
            this.controlToAnimation(ca, controlPressed, enemy, scene);
            this.clearDPadCommand(controlPressed.button);
        } else {
            controlPressed = this.aiControl(keys, time, delta, enemy);
            this.controlToAnimation(ca, controlPressed, enemy, scene);
        }
        //
        // this.physicsCheck = true;
        this.cameraBound(enemy);

    }



    limitVelocity(vMax) {
        if (this.body.velocity.x > vMax) {
            this.body.velocity.x = vMax;
        } else if (this.body.velocity.x < -vMax) {
            this.body.velocity.x = -vMax;
        }
    }

    archiveDPadCommand(controlPressed) {

        var cd = controlPressed.direction;
        var dp = this.dPadCommand[this.dPadCommand.length - 1];
        if ((cd == 'left' && this.flipX) || (cd == 'right' && !this.flipX)) {
            cd = 'fwd';
        }
        if ((cd == 'right' && this.flipX) || (cd == 'left' && !this.flipX)) {
            cd = 'back';
        }

        if (dp != cd && cd != 'none') {
            this.dPadCommand.push(cd);
            this.dPadCommand.shift();
        }
    }

    clearDPadCommand(btn) {
        if (btn != 'none') {
            this.dPadCommand = ['none', 'none', 'none'];
        }
    }


    checkCommandDown(dir) {
        return dir == 'down';
    }
    checkCommandUp(dir) {
        return dir == 'up';
    }


    checkCommandRight(dir) {
        return dir == 'right';
    }
    checkCommandLeft(dir) {
        return dir == 'left';
    }

    checkPlayerGroundSpecialMoveCommand(btn) {

        var ad = this.scene.game.attackData;
        var r = this.SPECIAL_DEFAULT;
        var moveCode = 'none';

        if (this.isRaged && this.dPadCommand[0] == ['back'] && this.dPadCommand[1] == ['down'] && this.dPadCommand[2] == ['fwd']) {
            moveCode = 'attack62';
        }
        //
        else if (this.dPadCommand[1] == ['down'] && this.dPadCommand[2] == ['fwd']) {
            moveCode = 'attack52'; //Rocket Punch
        }
        //
        else if (this.dPadCommand[1] == ['down'] && this.dPadCommand[2] == ['back']) {
            moveCode = 'attack61'; //Box swing 
        }
        //
        else if (this.dPadCommand[1] == ['back'] && this.dPadCommand[2] == ['fwd']) {
            moveCode = 'attack51'; //Sprinkle Canon
        }
        //

        if (moveCode != 'none') {
            r = {
                isExecuted: true,
                move: moveCode,
                velX: ad[moveCode].travel[btn][0],
                velY: ad[moveCode].travel[btn][1],
                desc: ad[moveCode].moveName + ' : ' + ad[moveCode].desc,
                keepMoving: ad[moveCode].travel.keepMoving
            };
        }
        //
        return r;
    }

    currentAnim() {
        var r = this.removePrefix(this.anims.currentAnim.key);
        return r;
    }


    pickAIGroundSpecialMove(btn) {

        var ad = this.scene.game.attackData;
        var r = this.SPECIAL_DEFAULT;
        var moveCode = 'none';

        var chance = Phaser.Math.Between(0, 10);

        if (chance > 6 && this.isRaged) {
            moveCode = 'attack62';
        }
        //
        else if (chance == 1) {
            moveCode = 'attack61';
        }
        //
        else if (chance == 2) {
            moveCode = 'attack52';
        }
        //
        else if (chance == 3) {
            moveCode = 'attack51';
        }
        //

        if (moveCode != 'none') {
            r = {
                isExecuted: true,
                move: moveCode,
                velX: ad[moveCode].travel[btn][0],
                velY: ad[moveCode].travel[btn][1],
                desc: ad[moveCode].moveName + ' : ' + ad[moveCode].desc,
                keepMoving: ad[moveCode].travel.keepMoving
            };
        }
        //
        return r;
    }


    pushedByAttack(ca, reaction, impactX, impactY, enemy) {
        this.stopMovement();
        if (enemy.flipX) {
            this.body.setVelocity(-impactX, -impactY);
            enemy.x += 20;
        } else if (!enemy.flipX){
            this.body.setVelocity(impactX, -impactY);
            enemy.x -= 20;
        } else {
            // console.log('enemy not defected!?', enemy)
        }

        if (reaction == 'hurt2') {

            if (!this.lose) {
                this.sfx.ouch[Phaser.Math.Between(2, 3)].play();
            }
        } else {
            this.sfx.ouch[Phaser.Math.Between(0, 1)].play();
        }
        this.playAnim(reaction, false); //don't ignore and restart if already playing hurt.

    }
    blockedAnAttack(impactX, impactY, enemy) {

        if (enemy.flipX) {
            this.body.setVelocity(-impactX, -impactY);
            // if(enemy.body.velocity.x < -100)
            // enemy.body.setVelocity(impactX/5, -impactY);
            var evx = enemy.body.velocity.x;
            enemy.x += 32;
            enemy.body.setVelocityX(evx);
        } else {
            this.body.setVelocity(impactX, -impactY);
            // if(enemy.body.velocity.x > 100)
            // enemy.body.setVelocity(-impactX/5, -impactY);
            var evx = enemy.body.velocity.x;
            enemy.x -= 32;
            enemy.body.setVelocityX(evx);

        }

        if (!this.lose) {
            this.sfx.block.play();
            this.playAnim('blockshake', true);
        }
    }

    isBackingFromAttack(directionPressed, enemy) {
        var r = false;
        if (enemy.isAttacking && this.isGround) {
            if (directionPressed == "left" && this.flipX == false) {
                r = true;
            } else if (directionPressed == "right" && this.flipX == true) {
                r = true;
            }
        }
        return r;
    }


    isOnCrouchingNormalAttack(ak) {
        var r = false;
        if (ak == 'attack21' ||
            ak == 'attack22'
        ) {
            r = true;;
        }
        return r;
    }
    isOnJumpingNormalAttack(ak) {
        var r = false;
        if (ak == 'attack31' ||
            ak == 'attack32'
        ) {
            r = true;;
        }
        return r;
    }
    isOnStandingNormalAttack(ak) {
        var r = false;
        if (ak == 'attack11' ||
            ak == 'attack12'
        ) {
            r = true;;
        }
        return r;
    }

    isOnNormalAttack(ak) {
        var r = false;
        if (
            this.isOnStandingNormalAttack(ak) ||
            this.isOnCrouchingNormalAttack(ak) ||
            this.isOnJumpingNormalAttack(ak)
        ) {
            // if (ak == 'attack11' ||
            //     ak == 'attack12' ||
            //     ak == 'attack21' ||
            //     ak == 'attack22' ||
            //     ak == 'attack31' ||
            //     ak == 'attack32'
            // ) {
            r = true;;
        }
        return r;
    }

    isHurt(ak) {
        var r = false;
        if (ak == 'hurt1' ||
            ak == 'hurt2' ||
            ak == 'falling' ||
            ak == 'fallen' ||
            ak == 'wakeup'
        ) {
            r = true;;
        }
        return r;
    }


    isOnSpecialAttack(ak) {
        var r = false;
        if (ak == 'attack51' ||
            ak == 'attack52' ||
            ak == 'attack61' ||
            ak == 'attack62'
        ) {
            r = true;;
        }
        return r;
    }

    canMoveOnGroundStanding(ca) {
        var r = false;
        if (ca == 'temp' ||
            ca == 'idle' ||
            ca == 'block' ||
            ca == 'moveF' ||
            ca == 'moveB'
        ) {
            r = true;;
        }
        return r;
    }

    controlToAnimation(ca, controlPressed, enemy, scene) {

        //cannot be controlled because bun just got hit
        var hb = this.hurtBy(enemy, ca);
        if (hb.isAttacked) {

            //BEAM START
            if (this.beam.alpha == 1) {
                // been hurt while shooting beam
                // beam gets removed by animationcomplete event.
                this.beam.anims.stop();
            }
            //BEAM END

            enemy.myAttackAlreadyLandedThisFrame = true;
            //

            if (this.isBlocking) {
                this.blockedAnAttack(200, 0, enemy);
            }
            //
            else {
                var py = hb.push % 10;
                var px = hb.push - py;

                // var impactX = Phaser.Math.Between(11, 13) * px;
                // var impactY = Phaser.Math.Between(800, 1000) * py;
                var impactX = 28 * px;
                var impactY = 1100 * py;

                if (ca == 'hurt2' || !this.isGround) {

                    // When hit with small attack while already in heavy hit stun,
                    // or when you're on air with light hit stun,
                    // you get another heavy hit stun instead of light one.

                    hb.reaction = 'hurt2';
                }
                // if ( ca == 'idle' || ca == 'hurt1') {
                if (this.comboed == 0 || (ca == 'falling' || ca == 'hurt1' || ca == 'hurt2' || ca == 'temp')) {
                    this.comboed += 1;

                } else {}
                this.pushedByAttack(ca, hb.reaction, impactX, impactY, enemy);

                scene.generateImpact(this);
                scene.events.emit('took_damage', { damage: hb.damage, comboed: this.comboed, victimIsAI: this.isAI });
            }
            //


        }
        //cannot be controlled because bun is hurt
        else if (this.isHurt(ca)) {

            if (ca == 'falling') {
                if (this.isGround) {
                    //ground and falling
                    this.playAnim('fallen', true);
                    this.stopMovement();
                    // this.body.setVelocityX(0);
                }

            } else {
                // HURT1 OR HURT2

            }


        }
        //cannot be controlled because bun is on special move
        else if (this.isOnSpecialAttack(ca)) {
            if (this.special.keepMoving) {
                this.moveWhileOnSpecial();
            }

        }
        //
        else {

            if (this.isGround) {
                if (this.canMoveOnGroundStanding(ca)) {

                    this.animateStandingDirection(ca, controlPressed.direction, scene, enemy);
                    this.animateStandingNormalAttack(ca, controlPressed.button, scene);

                } else if (ca == 'crouch') {
                    this.animateCrouchingDirection(ca, controlPressed.direction, scene);

                    this.animateCrouchingNormalAttack(ca, controlPressed.button, scene);
                } else if (ca == 'jump') {
                    //ground and jumping
                    this.playAnim('idle', true);
                    this.stopMovement();
                } else if (ca == 'attack31' || ca == 'attack32') {
                    //ground and not hurt and not falling/fallen, not idle and not jumping.
                    //ground and air normal attacking.
                    this.playAnim('idle', true);
                    this.stopMovement();
                }
            } else if (!this.isGround) {
                // on air, isGound == falase
                if (ca == 'jump' || this.isOnJumpingNormalAttack(ca)) {
                    this.animateJumpingNormalAttackAndDirection(ca, controlPressed, scene);
                }
                // else if (this.isOnNormalAttack(ca)) {
                //     this.body.setAccelerationX(0);
                //     // this.stopMovement();

                // }
            }

        }


    }

    animateCrouchingDirection(ca, directionPressed, scene) {
        if (directionPressed == 'down') {
            this.isCrouching = true;
            this.playAnim('crouch', true);
            this.stopMovement();

        } else {
            this.isCrouching = false;
            this.playAnim('idle', true);
            this.stopMovement();

        }
    }
    animateCrouchingNormalAttack(ca, buttonPressed, scene) {
        if (buttonPressed == 'punch') {
            this.playAnim('attack21', true);
            this.stopMovement();
            this.sfx.attack_normal[Phaser.Math.Between(0, 3)].play();

            scene.events.emit('building_rage', { rage: 2, isAI: this.isAI });


        } else if (buttonPressed == 'kick') {
            this.playAnim('attack22', true);
            this.stopMovement();
            this.sfx.attack_normal[Phaser.Math.Between(0, 3)].play();

            scene.events.emit('building_rage', { rage: 2, isAI: this.isAI });


        }
    }
    executeSpecialMoveFrom(ca) {
        //only works if not on special attack (prevents double execution or special-cancel)
        if (!this.isOnSpecialAttack(ca)) {
            if (this.special.move == 'attack62') {
                this.scene.events.emit('building_rage', { rage: -2000, isAI: this.isAI });
            }
            this.playAnim(this.special.move, true);
            this.sfx.attack_special[this.special.move].play();

            this.moveWhileOnSpecial();

            if (!this.isAI) {
                if (this.special.move == 'attack62') {
                    this.scene.hudscene.superMoveCount++;
                }
                //
                else {
                    this.scene.hudscene.specialMoveCount++;

                }

            }

        }
    }
    animateJumpingNormalAttackAndDirection(ca, controlPressed, scene) {

        if (controlPressed.direction == 'left') {
            this.jumpHorizontal(-this.ACCEL);
        } else if (controlPressed.direction == 'right') {
            this.jumpHorizontal(this.ACCEL);

        } else {
            this.body.setAccelerationX(0);
            // this.stopMovement();
        }
        //   
        if (!this.isOnJumpingNormalAttack(ca)) {
            if (controlPressed.button == 'punch') {
                // this.stopMovement();
                this.playAnim('attack31', true);
                this.sfx.attack_normal[Phaser.Math.Between(0, 3)].play();
                scene.events.emit('building_rage', { rage: 3, isAI: this.isAI });

            } else if (controlPressed.button == 'kick') {
                // this.stopMovement();
                this.playAnim('attack32', true);
                this.sfx.attack_normal[Phaser.Math.Between(0, 3)].play();
                scene.events.emit('building_rage', { rage: 5, isAI: this.isAI });
            } else {
                // this.body.setAccelerationX(0);
                this.playAnim('jump', true);
            }
        }
    }

    animateStandingNormalAttack(ca, buttonPressed, scene) {

        if (buttonPressed == 'punch') {
            this.special = this.isAI ? this.pickAIGroundSpecialMove(buttonPressed) : this.checkPlayerGroundSpecialMoveCommand(buttonPressed);

            if (this.special.isExecuted) {
                this.executeSpecialMoveFrom(ca);
                scene.events.emit('building_rage', { rage: 5, isAI: this.isAI });

            } else {

                this.playAnim('attack11', true);
                this.stopMovement();
                this.sfx.attack_normal[Phaser.Math.Between(0, 3)].play();

                scene.events.emit('building_rage', { rage: 5, isAI: this.isAI });
            }

        } else if (buttonPressed == 'kick') {
            this.special = this.isAI ? this.pickAIGroundSpecialMove(buttonPressed) : this.checkPlayerGroundSpecialMoveCommand(buttonPressed);

            if (this.special.isExecuted) {
                this.executeSpecialMoveFrom(ca);
                scene.events.emit('building_rage', { rage: 6, isAI: this.isAI });

            } else {
                this.playAnim('attack12', true);
                this.stopMovement();
                this.sfx.attack_normal[Phaser.Math.Between(0, 3)].play();

                scene.events.emit('building_rage', { rage: 5, isAI: this.isAI });
            }

        }

    }

    animateStandingDirection(ca, directionPressed, scene, enemy) {

        if (directionPressed == 'left') {
            if (this.isBackingFromAttack(directionPressed, enemy)) {
                this.isBlocking = true;
                this.playAnim('block', true);
                this.stopMovement();

            } else {
                this.isBlocking = false;
                if (this.flipX) {
                    this.playAnim('moveF', true);
                    scene.events.emit('building_rage', { rage: 0.1, isAI: this.isAI });
                } else {
                    this.playAnim('moveB', true);
                }
                if (this.body.velocity.y === 0) {
                    this.run(-this.ACCEL);
                } else {
                    this.run(-this.ACCEL / 3);
                }

            }
        } else if (directionPressed == 'right') {
            if (this.isBackingFromAttack(directionPressed, enemy)) {
                this.isBlocking = true;
                this.playAnim('block', true);
                this.stopMovement();

            } else {
                this.isBlocking = false;
                if (!this.flipX) {
                    this.playAnim('moveF', true);
                    scene.events.emit('building_rage', { rage: 0.1, isAI: this.isAI });
                } else {
                    this.playAnim('moveB', true);
                }
                if (this.body.velocity.y === 0) {
                    this.run(this.ACCEL);
                } else {
                    this.run(this.ACCEL / 3);
                }

            }

        } else if (directionPressed == 'up') {
            this.isBlocking = false;
            this.playAnim('jump', true);
            this.sfx.jump.play();

            this.body.setVelocityY(this.JUMP_VELOCITY_Y);
            this.isGround = false;
            this.shadow.setScale(2, 3);
        } else if (directionPressed == 'down') {
            this.isBlocking = false;
            this.isCrouching = true;
            this.playAnim('crouch', true);
        } else {
            this.isBlocking = false;
            this.playAnim('idle', true);
            this.stopMovement();

        }


    }

    humanControl(keys, time, delta) {

        if (!this.isControllable) {
            return { direction: 'none', button: 'none' };
        }

        var input = {
            left: keys.left.isDown,
            right: keys.right.isDown,
            down: keys.down.isDown,
            up: keys.up.isDown,
            punch: keys.punch.isDown,
            kick: keys.kick.isDown

        };
        var dir = 'none';
        var btn = 'none';

        if (input.left) {
            dir = 'left';
        } else if (input.right) {
            dir = 'right';
        } else if (input.up) {
            dir = 'up';
        } else if (input.down) {
            dir = 'down';
        }

        if (input.punch) {
            btn = 'punch';
        } else if (input.kick) {
            btn = 'kick';
        }
        var r = { direction: dir, button: btn };
        return r;

    }
    aiControl(keys, time, delta, enemy) {

        if (!this.isControllable) {
            return { direction: 'none', button: 'none' };
        }
        //
        else if (this.isDummy) {
            return { direction: 'none', button: 'none' };
        }

        if (this.aiMoveTimer <= 0) {

            this.aiMoveTimer = Phaser.Math.Between(400, 800);
            var aii = Phaser.Math.Between(1, 10)

            if (aii <= 7) // 70% chance to chase (unless too close)

            {
                if (enemy.x > this.x + 120) {
                    // enemy on his right
                    this.aiInputDirection = 'right';
                } else if (this.x > enemy.x + 120) {
                    // enemy on his left
                    this.aiInputDirection = 'left';
                }
            } else if (aii == 8) { // 10% chance to run away
                if (enemy.x > this.x) {
                    // enemy on his right
                    this.aiInputDirection = 'left';
                } else if (this.x > enemy.x) {
                    // enemy on his left
                    this.aiInputDirection = 'right';
                }

            } else if (aii == 9) { // 10% chance to jump
                this.aiInputDirection = 'up';
            } else { // 10% chance to stay
                this.aiInputDirection = 'none';

            }

            //store current input to aiPrevMovementInputDirection
            this.aiPrevMovementInputDirection = this.aiInputDirection;


        } else {
            this.aiInputDirection = this.aiPrevMovementInputDirection;
            this.aiMoveTimer -= delta;
        }

        if (this.aiAttackTimer <= 0) {

            this.aiAttackTimer = Phaser.Math.Between(400, 1000);

            var aii = Phaser.Math.Between(0, 1)
            switch (aii) {
                case 0:
                    this.aiInputButton = 'punch';
                    break;
                case 1:
                    this.aiInputButton = 'kick';
                    break;
            }

        } else {
            this.aiInputButton = 'none';
            this.aiAttackTimer -= delta;
        }

        // return { direction: 'none', button: 'none' };

        return { direction: this.aiInputDirection, button: this.aiInputButton };

    }

    updateFlip(enemy, ca) {

        if (ca == 'temp' ||
            ca == 'idle' ||
            ca == 'moveF' ||
            ca == 'moveB'
        ) {
            if (this.x > enemy.x) {
                this.flipX = true;
            } else {
                this.flipX = false;
            }
        }
    }
    run(accel) {
        this.body.setAccelerationX(accel);
        this.limitVelocity(400);
    }
    moveWhileOnSpecial() {
        var velX = this.special.velX;
        var velY = this.special.velY;

        this.body.setVelocityX(0);
        this.body.setAccelerationX(0);

        if (this.flipX) {
            this.body.setVelocityX(-velX);

        } else {
            this.body.setVelocityX(velX);
        }
        if (velY < 0) {
            this.body.setVelocityY(velY);
            this.isGround = false;
        }
    }

    jumpHorizontal(accel) {
        this.body.setAccelerationX(accel);
        this.limitVelocity(600);

    }

    stopMovement() {

        this.body.setVelocityX(0);

        this.run(0);
    }

    normalHitbox() {
        var ws = 80 / 4,
            hs = 140 / 4;
        this.body.setSize(ws, hs, false);
        this.body.setOffset((55 - ws) / 2, (55 - hs) / 2);

    }
    attackHitbox(w, h) {
        var ws = w / 4,
            hs = h / 4;
        this.body.setSize(ws, hs, false);
        this.body.setOffset((55 - ws) / 2, (55 - hs) / 2);
    }

    animStart(anim, frame) {

        var ak = this.removePrefix(anim.key);
        if (this.isOnNormalAttack(ak) || this.isOnSpecialAttack(ak)) {
            this.isAttacking = true;
            this.attackHitbox(200, 140);
        }
        if (this.comboed > 0) {
            if (ak == 'idle' || ak == 'block' || ak == 'block' || ak == 'blockshake' || ak == 'jump' || ak == 'crouch' || ak == 'fallen') {

                //display combo
                if (this.comboed >= 2) {
                    this.scene.events.emit('show_combo', { victimIsAI: this.isAI, combo: this.comboed });
                }
                this.comboed = 0;

            }
        }

    }

    animComplete(anim, frame) { // WARNING. stop or replay from beginning may also trigger animComplete.

        this.isAttacking = false;
        this.myAttackAlreadyLandedThisFrame = false;
        // console.log(ak)

        this.normalHitbox();

        var ak = this.removePrefix(anim.key);

        if (this.isOnNormalAttack(ak) || this.isOnSpecialAttack(ak)) {
            if (this.isGround) {
                if (this.isCrouching) {
                    this.playAnim('crouch', true);

                } else {
                    this.playAnim('idle', true);

                }
            } else {
                this.playAnim('jump', true);
            }

        }
        //
        else if (
            ak == 'hurt1' && frame.progress == 1 //completed hurt1
        ) {
            // hurt1 -> idle/jump
            if (this.isGround) {
                this.playAnim('idle', true);
            } else {
                this.playAnim('jump', true);
            }

        }
        // 
        else if (
            ak == 'wakeup'
        ) {
            this.playAnim('idle', true);
        }
        //
        else if (
            ak == 'hurt2' && frame.progress == 1
        ) {
            // hurt2 -> falling -> fallen -> wakeup -> temp -> idle
            this.playAnim('falling', true);
        }
        //
        else if (
            ak == 'falling'
        ) {
            // this.playAnim('fallen', true);
            // this.stopMovement();
        }
        //
        else if (
            ak == 'fallen'
        ) {

            if (!this.getLose()) {
                this.playAnim('wakeup', true);
                this.stopMovement();
            } else {}


            this.stopMovement();
        } else if (
            ak == 'blockshake'
        ) {

            this.playAnim('block', true);
            // this.stopMovement();
        }


    }
    animUpdate(anim, frame) {

        this.myAttackAlreadyLandedThisFrame = false; // true when this frame attack already landed 

        // beam spawn on firing frame
        var ad = this.scene.game.attackData.attack51;

        if (frame.textureFrame == ad.hitFrames[0]) {
            this.beam.alpha = 1;
            this.beam.anims.play('beam_fire', true, 0);
            this.scene.physics.world.enable(this.beam);
            this.beam.body.setSize(200, 25);
            this.beam.body.allowGravity = false;
        }
    }
    rageOn() {
        this.isRaged = true;
        this.spark.alpha = 1;
        this.spark.anims.play('sparking', true);
    }
    rageOff() {
        this.isRaged = false;
        this.spark.alpha = 0;
        this.spark.anims.stop();
    }

    addPrefix(suf) {
        var a = this.prefix.concat('_', suf);
        return a;

    }
    removePrefix(al) {
        var a = al.replace(this.prefix + '_', '');
        return a;
    }
    removeEnemyPrefix(al) {
        var a = al.replace(this.enemyPrefix + '_', '');
        return a;
    }

    playAnim(fr, isForcing) {
        var a = this.addPrefix(fr);
        this.anims.play(a, isForcing);

    }

    getLose() {
        return this.lose;
    }

    checkAttackHitFrame(bun) {
        var ca = this.removeEnemyPrefix(bun.anims.currentAnim.key);
        var ef = bun.anims.currentFrame.textureFrame;

        var r = { isAttacked: false, push: 0, damage: 0, reaction: 'none' };
        var i = null;
        var ad = this.scene.game.attackData[ca];
        ad.hitFrames.some(function(elem, index, arr) {

            if (ef == elem) {
                i = index;
                r.isAttacked = true;
                r.push = ad.push[i];
                r.damage = ad.damage[i];
                r.reaction = ad.reaction[i];
                // console.log(r);
                return true;
            }
        });

        return r;

    }

    hurtBy(enemy, ca) {

        var r = { isAttacked: false, push: 0, damage: 0, reaction: 'none' };

        var ef = enemy.anims.currentFrame.textureFrame;

        var beamf = enemy.beam.anims.currentFrame;

        if (ca != "fallen" && ca != "wakeup") {

            if (enemy.isAttacking && this.inRange && !enemy.myAttackAlreadyLandedThisFrame) {

                r = this.checkAttackHitFrame(enemy);

            } else if (beamf != null && beamf.textureFrame == 1 && !enemy.myAttackAlreadyLandedThisFrame) {
                this.scene.physics.world.overlap(this, enemy.beam, function() {
                    r = { isAttacked: true, push: 10, damage: 1, reaction: 'hurt1' };
                }, null, this);
            }

        }

        return r;

    }
    cameraBound(enemy) {
        if (Math.abs(this.x - enemy.x) > 580) {
            if (this.x > enemy.x) {
                this.x -= 2;
                enemy.x += 2;
            } else {
                this.x += 2;
                enemy.x -= 2;

            }

        }
    }

    roundWin() {
        this.isControllable = false;
        this.scene.time.delayedCall(3 * 1000, function() {
            this.playAnim('win', true);

        }, [], this);


    }
    roundLose() {
        this.lose = true;
        this.isControllable = false;
        this.rageOff();
        // this.scene.time.delayedCall(100, function() {
        //     this.pushedByAttack(this.removePrefix(this.anims.currentAnim.key), 'hurt2', 600, 1200, !this.flipX);
        // }, [], this);
        var enemy;
        if(this.isAI){
            enemy = this.scene.playerbun;
        }
        else{
            enemy = this.scene.enemybun;
        }
        this.pushedByAttack(this.removePrefix(this.anims.currentAnim.key), 'hurt2', 600, 1200, enemy);
        this.sfx.scream.play();

    }

}