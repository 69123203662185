import React, { Component } from 'react'
import SignUp from './Signup/Signup';
import GameOver from './GameOver/GameOver';
import Share from './Share/Share';
import Leaderboard from './Leaderboard/Leaderboard';
import Social from './Social';
import Squishy from './SquishyButton';
import { toJS } from 'mobx';

export default class ApplicationWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
    
      sign_up: !this.props.datastore.signed,
      share: false,
      leaderboard: this.props.datastore.signed,
      scores: this.props.datastore.scores,
      sign_up_render:!this.props.datastore.signed,
      share_render:false,
      leaderboard_render:this.props.datastore.signed
    }

    this.progress_to_signup = this.progress_to_signup.bind(this);
    this.progress_to_share = this.progress_to_share.bind(this);
    this.progress_to_leaderboard = this.progress_to_leaderboard.bind(this);
    this.close_signup = this.close_signup.bind(this);
    this.close_share = this.close_share.bind(this);
    this.replay_game = this.replay_game.bind(this);
  }

  componentDidMount() {
    if(this.props.datastore.signed) {

      this.props.datastore.sendScore((response) => {
        this.setState({
          scores: response
        })
      })
    } else {

    }
  }

  progress_to_signup() {

    if(!this.props.datastore.signed) {
      this.setState({
        sign_up: true,
        sign_up_render:true,
        game_over: false
      });

      setTimeout(() => {
        this.setState({ 
          game_over_render : false
        })
      }, 700 );


    } else {
      this.setState({
        share: true,
        share_render: true,
        game_over: false        
      });

      setTimeout(() => {
          this.setState({ 
            game_over_render : false
          })
      }, 700 );
    }
  }

  progress_to_leaderboard() {
      this.setState({
        scores: toJS(this.props.datastore.scores),
        leaderboard: true,
        leaderboard_render: true,
        share: false,
        sign_up: false
      });

      setTimeout(() => {
        this.setState({ 
          share_render : false,
          sign_up_render : false
        })
    }, 700 );

      this.props.datastore.loaderboardPixel();
  }

  progress_to_share() {
    this.setState({
      scores: toJS(this.props.datastore.scores),
      share: true,
      share_render: true,
      sign_up: false
    });

    setTimeout(() => {
      this.setState({ 
        sign_up_render : false
      })
  }, 700 );
    
  }

  close_share() {
    this.setState({
      share: false
    });

    setTimeout(() => {
      this.setState({ 
        share_render : false
      })
  }, 700 );

  }

  close_signup() {
    this.setState({
      sign_up: false,
      game_over: true,
      game_over_render : true
    });

    setTimeout(() => {
      this.setState({ 
        sign_up_render : false
      })
  }, 700 );
  }

  

  replay_game() {
    this.props.datastore.getSigned();
    this.props.datastore.setVisibility(false);
  }

  render() {
    return (
      <div>
          {this.state.game_over_render ? <GameOver window_state={this.state.game_over} header="Game over" close={false} score={this.props.datastore.score} replay={this.replay_game} signup={this.progress_to_signup} button_text={'Enter your score'} /> : null}
          {this.state.sign_up_render ? <SignUp window_state={this.state.sign_up} header="Submit your score" close={true} close_point={this.replay_game} signedup={this.progress_to_share} /> : null}
          {this.state.share_render ? <Share window_state={this.state.share} header="Sharing is caring" close={false} close_point={this.close_share} next={this.progress_to_leaderboard} /> : null}
          {this.state.leaderboard_render ? <Leaderboard window_state={this.state.leaderboard} header={`${this.props.datastore.game_name}`} scores={this.state.scores} close={false} /> : null}
          <div className="squishy_wrapper" onClick={this.replay_game}>
            {this.state.sign_up || this.state.share || this.state.game_over ? null : <Squishy  />  }
          </div>
      </div>
    )
  }
}
