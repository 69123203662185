export const CST = {
    SCENES: {
        INIT:               "INIT",
        LOAD:               "LOAD",
        CONTROL:            "CONTROL",
        THROWBACK_TITLE:    "THROWBACK_TITLE",
        GAME_TITLE:         "GAME_TITLE",
        INSTRUCTION:        "INSTRUCTION",
        INTRO:              "INTRO",
        GAME:               "GAME",
        HUD:                "HUD",
        INTERMISSION:       "INTERMISSION",
        SCORE:              "SCORE",
        END:                "END"
    }
}