import Phaser from 'phaser';
export default class Fire extends Phaser.GameObjects.Sprite {

    constructor(scene) {
        super(scene);
        // super(config.scene, config.x, config.y, config.key);

        /* switch(config.type) {
            case "shot":
            case "obstacle":

        } */
        this.scene.physics.world.enable(this);
        this.setScale(4);
        this.VELOCITY_Y = -380;
        this.body.setSize(9, 9);
        this.body.offset.set(8, 8);
        this.isExploding = false;
        // break;

        this.on('animationcomplete', () => {
            if (this.anims.currentAnim.key === 'fireExplode') {
                // this.setActive(false);
                // this.setVisible(false);

                //
                this.destroy();

            }
        }, this);
        this.alive = true;
        // this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer, () => this.collided());
        // this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer, this.collided());

    }

    fire(x, y, left, up) {
        // this.setActive(true);
        // this.setVisible(true);
        this.alive = true;
        this.isExploding = false;
        
        //ready to shoot UP
        // console.log('fire', this.scene.fireballs.children.entries)

        // this.scene.add.existing(this);
        this.body.allowGravity = true;

        this.setPosition(x, y);
        this.body.velocity.x = 500 * (left ? -1 : 1);
        // this.body.velocity.y = this.VELOCITY_Y * 0.5;
        this.body.velocity.x = up ? 200 * (left ? -1 : 1) : 500 * (left ? -1 : 1);
        this.body.velocity.y = up ? this.VELOCITY_Y * 2 : this.VELOCITY_Y * 0.6;

        this.flipX = left;


        this.anims.play('fireFly');
        this.scene.sfx.shoot.play();





    }

    update(time, delta) {
        if (!this.active || !this.alive) {
            return;
        }
        this.scene.physics.world.collide(this, this.scene.groundLayer, () => this.collided());
        this.scene.physics.world.overlap(this, this.scene.enemyGroup, (me, enemy) => {
            if (enemy.type == 'cookie') {
                me.explode();

                enemy.body.gravity.y = 0;
                enemy.getFlipped(enemy);

                this.alive = false;

            }
            //
            else if (enemy.type == 'cupcake') {
                me.explode();

                enemy.igniteCandle();
                this.alive = false;

            }
            //
            else if (enemy.type == 'crossboss') {
                // bounces off fire
                // this.body.velocity.x *= -2;
                // this.body.velocity.y = this.VELOCITY_Y * 3;

                me.explode();
                enemy.getToasted(enemy);
                this.alive = false;

            }
            //
            else {
                me.explode();

                enemy.getFlipped(enemy);
                this.alive = false;

            }

        });


        if (
            (this.x > this.scene.cameras.main.scrollX + this.scene.sys.game.canvas.width + 32) ||
            (this.x < this.scene.cameras.main.scrollX - 32) ||
            this.y > 1024 + 64 || this.y < 0
        ) {
            //gone outside
            this.destroy();
        }

    }

    collided() {
        // if (this.body.velocity.y === 0) {
        if (this.body.blocked.down) {
            this.body.velocity.y = this.VELOCITY_Y;
        } // 
        // else if (this.body.blocked.up) {
        //     this.scene.sfx.hit.play();
        //     this.explode();
        // }
        if (this.body.velocity.x === 0) {
            this.scene.sfx.hit.play();
            this.explode();
        }
    }

    explode() {
        if(this.isExploding){
            return;
        }
        this.isExploding = true;
        this.body.allowGravity = false;

        this.body.setVelocity(0, 0);
        // this.destroy();

        //error heresometimes
        if(this.anims.animationManager){
        this.anims.play('fireExplode');

        }
        else{
            // console.log('ERROR',this);

        }



    }
}