import Phaser from 'phaser';

export default class Jam extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.setBounce(1, 1);
        this.setMaxVelocity(10, 10);
        this.displayWidth = 80;
        this.displayHeight = 80;
        this.setCollideWorldBounds(true);
    }
};
