import React, { Component } from 'react'

export default class Row extends Component {
  render(props) {
    return(
        <tr className={`leaderboard_row ${this.props.id === 10 || this.props.user ? 'current_user' : ''}`}>
            <td width="15%" className="place">{this.props.rank}.</td>
            <td width="45%" className="tag">{this.props.tag}</td>
            <td width="40%" className="leader_score">{this.props.score}</td>
        </tr>
    )
  }
}
