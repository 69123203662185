import Phaser from 'phaser';
import {CST} from '../CST';
import DataStore from '../../../Components/Store/DataStore';

export class SoundManagerScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.SOUND, active: false });
    }
    
    init() {
        document.addEventListener("mute", function (event) {
            this.game.sound.mute = true;
        }.bind(this));

        document.addEventListener("unmute", function (event) {
            this.game.sound.mute = false;
        }.bind(this));
    }

    create() {

        this.game = this.scene.get(CST.SCENES.GAME);
        
        this.vrom = this.sound.add('vrm_j', {volume: 1});
        this.vrom.loop = true;

        this.vrooomm = this.sound.add('vrm_y', {volume: 1});

        this.coin = this.sound.add('ching', {volume: 0.5});
        this.box = this.sound.add('item', {volume: 0.5});

        this.one = this.sound.add('one', {volume: 0.5});
        this.two = this.sound.add('two', {volume: 0.5});
        this.three = this.sound.add('three', {volume: 0.5});
        this.go = this.sound.add('go', {volume: 0.5});

        this.game.events.on('hotlight_sound', function (data) {
            this.vrooomm.play();
        }, this);

        this.game.events.on('hotlight_sound_over', function (data) {
            this.vrooomm.stop();
        }, this);

        this.game.events.on('normal_sound', function (data) {
            this.vrom.play();
        }, this);

        this.game.events.on('one_sound', function (data) {
            this.one.play();
        }, this);

        this.game.events.on('two_sound', function (data) {
            this.two.play();
        }, this);

        this.game.events.on('three_sound', function (data) {
            this.three.play();
        }, this);

        this.game.events.on('go_sound', function (data) {
            this.go.play();
        }, this);

        this.game.events.on('coin_sound', function (data) {
            this.coin.play();
        }, this);

        this.game.events.on('box_sound', function (data) {
            this.box.play();
        }, this);
    }
}