import React, { Component } from 'react'
import Fairybread from './Fairybread';
import DataStore from '../../Components/Store/DataStore';

export default class FairybreadComponent extends Component {
    render() {
        DataStore.game_id = 3;
        DataStore.game_name = "Sprinkle Fantasy";
        DataStore.initPixel();
        let fairybread = new Fairybread();
        return (
            <div></div>
        )
    }
}
