import Phaser from 'phaser';
import { CST } from '../CST';

export class ControlScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.CONTROL });
    }

    create() {
        this.gamescene = this.scene.get(CST.SCENES.GAME);
        this.gamePaused = false;
        
        // Audio
        this.bgm = this.sound.add('bgm', { loop: true, volume: 0.6 });
        this.sad = this.sound.add('sad', { loop: true, volume: 0.6 });

        this.bgm.play();
        // this.sad.play();

        /* Keyboard Input*/
        this.keys = {
            left: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT, false),
            right: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT, false),
            down: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.DOWN, false),
            up: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.UP, false),
            fire: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Z, false),
            jump: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.X, false),
            test: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.T, false)
        };
        this.keyPressed = false;

        /* Touch to Keyboard */
        document.addEventListener("left", function(event) {
            this.keys.left.isDown = true;
            this.keys.right.isDown = false;
        }.bind(this));

        document.addEventListener("right", function(event) {
            this.keys.right.isDown = true;
            this.keys.left.isDown = false;
        }.bind(this));

        document.addEventListener("down", function(event) {
            this.keys.down.isDown = true;
            this.keys.right.isDown = false;
            this.keys.up.isDown = false;
            this.keys.left.isDown = false;
        }.bind(this));

        document.addEventListener("up", function(event) {
            this.keys.down.isDown = false;
            this.keys.right.isDown = false;
            this.keys.up.isDown = true;
            this.keys.left.isDown = false;
        }.bind(this));

        document.addEventListener("dpad_up", function(event) {
            this.keys.right.isDown = false;
            this.keys.left.isDown = false;
            this.keys.up.isDown = false;
            this.keys.down.isDown = false;
        }.bind(this));

        document.addEventListener("button_a", function(event) {
            this.keys.fire.isDown = true;
        }.bind(this));
        document.addEventListener("button_a_up", function(event) {
            this.keys.fire.isDown = false;
        }.bind(this));
        document.addEventListener("button_b", function(event) {
            this.keys.jump.isDown = true;
        }.bind(this));

        document.addEventListener("button_b_up", function(event) {
            this.keys.jump.isDown = false;
        }.bind(this));


        /*  help and unhelp */
        document.addEventListener("help", function(event) {
            this.helpOn();
        }.bind(this));

        document.addEventListener("unhelp", function(event) {
            this.helpOff();
        }.bind(this));
        /*  mute and unmute */
        document.addEventListener("mute", function (event) {
            this.sound.mute = true;
        }.bind(this));
        document.addEventListener("unmute", function (event) {
            this.sound.mute = false;
        }.bind(this));
    }

    update() {

    }
    helpOn() {
            if(!this.gamescene.scene.isActive()){
                return;
            }
            // console.log('help on successful')
            this.scene.launch(CST.SCENES.INSTRUCTION, {isFromBeginning: false});
            this.scene.bringToTop(CST.SCENES.INSTRUCTION);
            this.scene.pause(CST.SCENES.GAME);
            this.scene.pause(CST.SCENES.HUD);
            this.gamePaused = true;

    }


    helpOff() {

            if(!this.gamePaused){
                return;
            }
            // console.log('help off successful')
            this.scene.stop(CST.SCENES.INSTRUCTION);

            this.scene.resume(CST.SCENES.GAME);
            this.scene.resume(CST.SCENES.HUD);
            this.gamePaused = false;


    }

}