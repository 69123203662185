import Phaser from 'phaser';
import { InitScene } from './Init/InitScene';
import { PreloadScene } from './Preload/PreloadScene';
import { ThrowbackTitleScene } from './ThrowbackTitle/ThrowbackTitleScene';
import { KrispyTitleScene } from './KrispyTitle/KrispyTitleScene';
import { KartScene } from './Game/KartScene';
import { IntructionScene } from './Instructions/IntructionScene';
import { HUD } from './HUD/HUD';
import { ScoreScene } from './End/HighScore';
import { SoundManagerScene } from './Sound/SoundManagerScene';
import { HelpScene } from './Instructions/Help';

export default class Kart {

    constructor() {
        const STAGE_PADDING = 60;
        const STAGE_WIDTH = (window.innerWidth - STAGE_PADDING);
        const STAGE_HEIGHT = STAGE_WIDTH;
        const STAGE_COLOUR = "#5D5D5D";
        const CANVAS = "CanvasWrapper";
        const GAME_CONFIG = {
            scale: {
                parent: CANVAS,
                mode: Phaser.Scale.FIT,
                width: 660,
                height: 660
            },
            type: Phaser.AUTO,
            width: STAGE_WIDTH,
            height: STAGE_HEIGHT,
            backgroundColor: STAGE_COLOUR,
            parent: CANVAS,
            fps: {
                target: 60,
            },
            render: {
                pixelArt: true
            },
            physics: {
                default: 'arcade',
                arcade: {
                    debug : false
                }
            },
            scene: [
                InitScene,
                PreloadScene,
                ThrowbackTitleScene,
                KrispyTitleScene,
                IntructionScene,
                KartScene, 
                HUD,
                ScoreScene,
                SoundManagerScene,
                HelpScene
            ],
            audio: {
                disableWebAudio: false
            }
        };
        
        var game = new Phaser.Game(GAME_CONFIG);
    }
}

