import Phaser from 'phaser';
import { CST } from '../CST';

export class HUD extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.HUD, active: false });

    }

    create(passedData) {

        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;


        this.MAX_DISPLAY_TIME = 99;
        this.MAX_REAL_TIME = 60;

        this.MAX_HEALTH = 100;
        this.MAX_SUPER = 100;

        this.MAX_COMBO = 99;

        this.current_round = passedData.round;

        // console.log("round", this.current_round);
        this.current_win = passedData.win;
        this.current_lose = passedData.lose;

        this.specialMoveCount = 0;
        this.superMoveCount = 0;

        this.prevRoundScore = passedData.score;
        this.pauseTime = true;

        this.resultIndex = 0;


        /* Game Reference */
        this.gamescene = this.scene.get(CST.SCENES.GAME);

        this.bg = this.add.image(this.SCREEN_CENTRE_W, 0, 'hud_bg').setOrigin(0.5, 0);

        this.prefight = {
            ready: this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_ready'),
            fight: this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_fight'),
            round: [
                this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_r1'),
                this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_r2'),
                this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_r3')
            ]
        };

        this.postfight = {
            ko: this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_ko'),
            result: [
                this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_youwon'),
                this.add.image(this.SCREEN_CENTRE_W, -this.SCREEN_CENTRE_H, 'hud_youlost')
            ]
        };

        this.player_stat = {
            health: this.MAX_HEALTH,
            healthbar: this.add.image(16, 40, 'hud_healthbar_pink').setOrigin(0, 0),
            healthbar_white: this.add.image(16, 40, 'hud_healthbar_grey').setOrigin(0, 0),
            healthbar_black: this.add.image(16, 40, 'hud_healthbar_black').setOrigin(0, 0),
            super: 0,
            superbar: this.add.image(this.SCREEN_CENTRE_W - 46, 76, 'hud_superbar').setOrigin(1, 0),
            badge: [this.add.image(40, 76, 'hud_badge').setOrigin(0, 0), this.add.image(16, 76, 'hud_badge').setOrigin(0, 0)],
            combo: 0,
            comboTextObject_black: this.add.bitmapText(16, this.SCREEN_H - 40 + 4, 'HalfBoldPixel-7', 'x9', 44).setOrigin(0, 1),
            comboTextObject: this.add.bitmapText(16, this.SCREEN_H - 40, 'HalfBoldPixel-7', 'x9', 44).setOrigin(0, 1),
            comboTextObject_black2: this.add.bitmapText(16, this.SCREEN_H - 40 + 4, 'HalfBoldPixel-7', 'COMBO', 20).setOrigin(0, 0),
            comboTextObject2: this.add.bitmapText(16, this.SCREEN_H - 40, 'HalfBoldPixel-7', 'COMBO', 20).setOrigin(0, 0),

            total_combo: 0,
            max_combo: 0,
            won: 0,
            lost: 0,
            etc: 0


        };

        this.player_stat.healthbar_black.scaleX = 0;
        this.player_stat.healthbar_white.scaleX = 0;
        this.player_stat.superbar.scaleX = 0;

        this.player_stat.badge[0].setAlpha(0);
        this.player_stat.badge[1].setAlpha(0);

        this.player_stat.comboTextObject_black.setTint(0x000000);
        this.player_stat.comboTextObject_black2.setTint(0x000000);
        this.player_stat.comboTextObject.setTint(0xfae98c);

        this.enemy_stat = {
            health: this.MAX_HEALTH,
            healthbar: this.add.image(this.SCREEN_W - 16, 40, 'hud_healthbar_beige').setOrigin(1, 0),
            healthbar_white: this.add.image(this.SCREEN_W - 16, 40, 'hud_healthbar_grey').setOrigin(1, 0),
            healthbar_black: this.add.image(this.SCREEN_W - 16, 40, 'hud_healthbar_black').setOrigin(1, 0),
            super: 0,
            superbar: this.add.image(this.SCREEN_CENTRE_W + 46, 76, 'hud_superbar').setOrigin(0, 0),
            badge: [this.add.image(this.SCREEN_W - 40, 76, 'hud_badge').setOrigin(1, 0), this.add.image(this.SCREEN_W - 16, 76, 'hud_badge').setOrigin(1, 0)],
            combo: 0,
            comboTextObject_black: this.add.bitmapText(this.SCREEN_W - 16, this.SCREEN_H - 40 + 4, 'HalfBoldPixel-7', 'x9', 44).setOrigin(1, 1),
            comboTextObject: this.add.bitmapText(this.SCREEN_W - 16, this.SCREEN_H - 40, 'HalfBoldPixel-7', 'x9', 44).setOrigin(1, 1),
            comboTextObject_black2: this.add.bitmapText(this.SCREEN_W - 16, this.SCREEN_H - 40 + 4, 'HalfBoldPixel-7', 'COMBO', 20).setOrigin(1, 0),
            comboTextObject2: this.add.bitmapText(this.SCREEN_W - 16, this.SCREEN_H - 40, 'HalfBoldPixel-7', 'COMBO', 20).setOrigin(1, 0),

            total_combo: 0,
            max_combo: 0,
            won: 0,
            lost: 0,
            etc: 0

        };

        this.enemy_stat.healthbar_black.scaleX = 0;
        this.enemy_stat.healthbar_white.scaleX = 0;
        this.enemy_stat.superbar.scaleX = 0;

        this.enemy_stat.badge[0].setAlpha(0);
        this.enemy_stat.badge[1].setAlpha(0);

        this.enemy_stat.comboTextObject_black.setTint(0x000000);
        this.enemy_stat.comboTextObject_black2.setTint(0x000000);
        this.enemy_stat.comboTextObject.setTint(0xfae98c);

        //
        this.enemy_stat.comboTextObject.setAlpha(0);
        this.enemy_stat.comboTextObject_black.setAlpha(0);
        this.enemy_stat.comboTextObject2.setAlpha(0);
        this.enemy_stat.comboTextObject_black2.setAlpha(0);
        this.player_stat.comboTextObject.setAlpha(0);
        this.player_stat.comboTextObject_black.setAlpha(0);
        this.player_stat.comboTextObject2.setAlpha(0);
        this.player_stat.comboTextObject_black2.setAlpha(0);

        //
        this.updateBadge(this.current_win, this.current_lose);

        //

        this.barTweenData = {
            // paused: true,
            duration: 200,
            ease: 'Power2',
            onComplete: function() {

            }

        };
        //

        this.roundTimetext = {
            textObject_black: this.add.bitmapText(this.SCREEN_CENTRE_W, 31 + 4, 'HalfBoldPixel-7', '99', 44).setOrigin(0.5, 0),
            textObject: this.add.bitmapText(this.SCREEN_CENTRE_W, 31, 'HalfBoldPixel-7', '99', 44).setOrigin(0.5, 0),
            time: this.MAX_REAL_TIME * 1000,
            displayedTime: this.MAX_DISPLAY_TIME,
            hurry: false
        };

        this.roundTimetext.textObject_black.setTint(0x000000);


        this.prefightTweenData = [

            {
                targets: this.prefight.round[this.current_round],
                y: this.SCREEN_CENTRE_H,
                duration: 200,
                ease: 'Power2',
                yoyo: true,
                hold: 1000,
                onStart: function() {
                    this.sfx.round[this.current_round].play();
                },
                onStartScope: this,
                onComplete: function() {
                    this.tweens.add(this.prefightTweenData[1]);
                },
                onCompleteScope: this

            },
            {
                targets: this.prefight.ready,
                y: this.SCREEN_CENTRE_H,
                duration: 200,
                ease: 'Power2',
                yoyo: true,
                hold: 500,
                onStart: function() {
                    this.sfx.ready.play();
                },
                onStartScope: this,
                onComplete: function() {
                    this.tweens.add(this.prefightTweenData[2]);
                },
                onCompleteScope: this
            },
            {
                targets: this.prefight.fight,
                y: this.SCREEN_CENTRE_H,
                duration: 100,
                ease: 'Power2',
                yoyo: true,
                hold: 400,
                onStart: function() {
                    this.sfx.fight.play();
                    this.gamescene.cameras.main.shake(60, 0.02);


                },
                onStartScope: this,
                onComplete: function() {
                    this.pauseTime = false;
                    this.gamescene.playerbun.isControllable = true;
                    this.gamescene.enemybun.isControllable = true;

                },
                onCompleteScope: this
            }


        ];

        this.postfightTweenData = [

            {
                targets: this.postfight.ko,
                y: this.SCREEN_CENTRE_H,
                duration: 200,
                ease: 'Power2',
                yoyo: true,
                hold: 1000,
                onStart: function() {
                    this.sfx.ko.play();
                },
                onStartScope: this,
                onComplete: function() {
                    this.postfightTweenData[1].targets = this.postfight.result[this.resultIndex]
                    this.tweens.add(this.postfightTweenData[1]);
                },
                onCompleteScope: this

            },

            {
                // targets: this.postfight.result[this.resultIndex],
                y: this.SCREEN_CENTRE_H,
                duration: 200,
                ease: 'Power2',
                yoyo: true,
                hold: 1000,
                onStart: function() {
                    this.sfx.result[this.resultIndex].play();
                },
                onStartScope: this,
                onComplete: function() {},
                onCompleteScope: this
            }

            //score



        ];

        this.hudTextTween = this.tweens.add(this.prefightTweenData[0]);

        this.comboTweenData2 = [

            {
                targets: [
                    this.player_stat.comboTextObject,
                    this.player_stat.comboTextObject2,
                    this.player_stat.comboTextObject_black,
                    this.player_stat.comboTextObject_black2
                ],

                paused: true,
                alpha: 1,
                duration: 60,
                ease: 'Power2',
                hold: 1500,
                yoyo: true,
                onStart: function() {
                    // console.log('start',this.y);
                }

            },

            {
                targets: [
                    this.enemy_stat.comboTextObject,
                    this.enemy_stat.comboTextObject2,
                    this.enemy_stat.comboTextObject_black,
                    this.enemy_stat.comboTextObject_black2
                ],

                paused: true,
                alpha: 1,
                duration: 60,
                ease: 'Power2',
                hold: 1500,
                yoyo: true,
                onStart: function() {
                    // console.log('start',this.y);
                }

            }

        ];
        this.playerComboTween2 = this.tweens.add(this.comboTweenData2[0]);
        this.enemyComboTween2 = this.tweens.add(this.comboTweenData2[1]);



        /* Damage */
        this.gamescene.events.on('took_damage', function(passedData) {

            var barOwnerStat, comboScorerStat;
            if (passedData.victimIsAI) {
                barOwnerStat = this.enemy_stat;
                comboScorerStat = this.player_stat;

            } else {
                barOwnerStat = this.player_stat;
                comboScorerStat = this.enemy_stat;

            }
            /*            if (passedData.victimIsAI && this.gamescene.enemybun.isDummy) {
                            // Deals no damage if 'dummy' mode.
                        } else */
            //
            {
                // Ease combo damages. the higher the combo, the less damage each hit inflicts.
                // (Prevents infinite combo and cheap strategy.)
                // processed damage = 45% proposed damage + 55% toned down damage
                // combo damage gets rounded to the nearest integer.

                var processedDamage;
                if (passedData.comboed > 0) {
                    processedDamage = (passedData.damage * 0.45) + (passedData.damage * (1 / passedData.comboed)) * 0.55;
                    processedDamage = Math.round(processedDamage);
                }
                //
                else {
                    processedDamage = passedData.damage;
                }
                // console.log("DAMAGE", processedDamage)
                this.updateHealth(barOwnerStat, processedDamage);

            }

            // this.updateCombo(comboScorerStat, passedData.comboed, comboTween);

            this.checkForKO();

        }, this);

        /* Combo End*/
        this.gamescene.events.on('show_combo', function(passedData) {
            this.showCombo(passedData.combo, passedData.victimIsAI);
        }, this);


        /* Super */
        this.gamescene.events.on('building_rage', function(passedData) {
            var barOwnerStat;
            if (passedData.isAI) {
                barOwnerStat = this.enemy_stat;
            } else {
                barOwnerStat = this.player_stat;
            }
            this.updateSuper(barOwnerStat, passedData.rage, passedData.isAI);
        }, this);


        /* Sound */

        this.sfx = {
            round: [
                this.sound.add('ui_r1'),
                this.sound.add('ui_r2'),
                this.sound.add('ui_r3')
            ],

            ready: this.sound.add('ui_ready'),
            fight: this.sound.add('ui_fight'),

            ko: this.sound.add('ui_ko'),
            result: [
                this.sound.add('ui_youwin'),
                this.sound.add('ui_youlose')
            ],
            gameover: this.sound.add('ui_gameover')

        };



        // this.time.delayedCall(300, function() {

        //     this.gamescene.cameras.main.shake(60, 0.01);

        // }, [], this);



    }



    update(time, delta) {

        if (this.pauseTime == false) {
            this.updateTimer(delta);
        }



    }

    updateBadge(l, r) {
        for (var i = 0; i < l; i++) {
            this.player_stat.badge[i].setAlpha(1);
        }
        for (var j = 0; j < r; j++) {
            this.enemy_stat.badge[j].setAlpha(1);
        }

    }

    showCombo(combo, victimIsAI) {
        var stat, comboTween;
        if (victimIsAI) {
            stat = this.player_stat;
            comboTween = this.playerComboTween2;
        } else {
            stat = this.enemy_stat;
            comboTween = this.enemyComboTween2;

        }

        stat.total_combo += combo;

        stat.comboTextObject.setText('x' + combo);
        stat.comboTextObject_black.setText('x' + combo);

        stat.comboTextObject.setAlpha(0);
        stat.comboTextObject_black.setAlpha(0);
        stat.comboTextObject2.setAlpha(0);
        stat.comboTextObject_black2.setAlpha(0);

        comboTween.restart();



    }
    executeTimeOut() {
        var winner, loser;
        var playerWon;

        if (this.player_stat.health >= this.enemy_stat.health) {
            winner = this.gamescene.playerbun;
            loser = this.gamescene.enemybun;
            this.current_win++;
            playerWon = true;
            this.resultIndex = 0;

        } //
        else {
            winner = this.gamescene.enemybun;
            loser = this.gamescene.playerbun;
            this.current_lose++;
            playerWon = false;
            this.resultIndex = 1;

        }

        this.events.emit('end_round', winner, loser, playerWon);
        this.roundEnd(winner, loser, playerWon);
    }

    checkForKO() {
        var winner, loser;
        var playerWon;

        if (this.player_stat.health == 0 || this.enemy_stat.health == 0) {
            if (this.enemy_stat.health == 0) {
                winner = this.gamescene.playerbun;
                loser = this.gamescene.enemybun;
                this.current_win++;
                playerWon = true;
                this.resultIndex = 0;


            } //
            else if (this.player_stat.health == 0) {
                winner = this.gamescene.enemybun;
                loser = this.gamescene.playerbun;
                this.current_lose++;
                playerWon = false;
                this.resultIndex = 1;


            }
            //super finish

            if (winner.currentAnim() == 'attack62') {
                this.gamescene.bg.superFinishBg();
            }

            //winner, loser
            this.events.emit('end_round', winner, loser, playerWon);
            this.roundEnd(winner, loser, playerWon);
        }

    }
    roundEnd(winner, loser, playerWon) {

        this.cameras.main.fadeIn(200, 255, 255, 255);


        this.updateBadge(this.current_win, this.current_lose);

        this.time.delayedCall(1 * 1000, function() {
            if (this.player_stat.health > 0 && this.enemy_stat.health > 0) {
                this.postfightTweenData[1].targets = this.postfight.result[this.resultIndex]
                this.tweens.add(this.postfightTweenData[1]);
            } //
            else {
                this.tweens.add(this.postfightTweenData[0]);

            }


        }, [], this);

        this.pauseTime = true;
        var tb = this.roundTimetext.displayedTime * 1000 + this.prevRoundScore.timeBonus,
            vb = this.player_stat.health * 1000 + this.prevRoundScore.vitalBonus,
            cb = this.player_stat.total_combo * 8000 + this.prevRoundScore.comboBonus,
            ob = (8000 * this.specialMoveCount) + (50000 * this.superMoveCount) + this.prevRoundScore.otherBonus;
        if (!playerWon) {
            tb = vb = 0;
        }
        var tba = tb + vb + cb + ob;
        var roundResult = {
            round: this.current_round,
            win: this.current_win,
            lose: this.current_lose,
            score: {
                timeBonus: tb,
                vitalBonus: vb,
                comboBonus: cb,
                otherBonus: ob,
                total: tba
            }
        };
        this.time.delayedCall(8 * 1000, function() {

            this.scene.stop(CST.SCENES.GAME);
            this.scene.start(CST.SCENES.INTERMISSION, roundResult);

        }, [], this);


    }
    updateHealth(barOwnerStat, damage) {

        barOwnerStat.health -= damage;
        if (barOwnerStat.health < 0) {
            barOwnerStat.health = 0;
        }
        if (barOwnerStat.health > this.MAX_HEALTH) {
            barOwnerStat.health = this.MAX_HEALTH;
        }

        var dest_scaleX = 1 - (barOwnerStat.health * 0.01);
        barOwnerStat.healthbar_white.scaleX = dest_scaleX;

        this.barTweenData.targets = barOwnerStat.healthbar_black;
        this.barTweenData.scaleX = dest_scaleX;
        this.tweens.add(this.barTweenData);

    }

    updateSuper(barOwnerStat, topUp, barOwnerIsAI) {

        barOwnerStat.super += topUp;
        if (barOwnerStat.super <= 0) {
            // console.log("rage is zero")
            barOwnerStat.super = 0;
            if (barOwnerIsAI) {
                this.gamescene.enemybun.rageOff();
            } else {
                this.gamescene.playerbun.rageOff();
            }
        }
        if (barOwnerStat.super >= this.MAX_SUPER) {
            barOwnerStat.super = this.MAX_SUPER;
            if (barOwnerIsAI) {
                this.gamescene.enemybun.rageOn();
            } else {
                this.gamescene.playerbun.rageOn();
                // this.gamescene.playerbun.tint = 0xFF69B4;
            }
        }

        // var dest_scaleX = 1 - (barOwnerStat.super * 0.01);
        barOwnerStat.superbar.scaleX = barOwnerStat.super * 0.01;

        // this.barTweenData.targets = barOwnerStat.superbar_black;
        // this.barTweenData.scaleX = dest_scaleX;
        // this.tweens.add(this.barTweenData);

    }

    // updateCombo(comboScorerStat, combo, comboTween) {
    //     var t = combo + ' Combo';

    //     comboScorerStat.combo = combo;

    //     if (combo < 2) {
    //         comboScorerStat.comboTextObject.setText('');
    //     }
    //     //
    //     else {

    //         comboScorerStat.comboTextObject.setText(t);
    //     }

    //     comboTween.restart();


    // }



    updateTimer(delta) {

        if (this.roundTimetext.time === 0) { return; }

        if (this.roundTimetext.displayedTime >= 1) {

            this.roundTimetext.time -= delta;
            var remainingSec = (this.roundTimetext.time / 1000 / this.MAX_REAL_TIME) * this.MAX_DISPLAY_TIME;
            this.roundTimetext.displayedTime = Math.round(remainingSec);
            this.roundTimetext.textObject_black.setText('' + this.roundTimetext.displayedTime);
            this.roundTimetext.textObject.setText('' + this.roundTimetext.displayedTime);


        } else {
            this.roundTimetext.time = 0;
            this.roundTimetext.displayedTime = 0;
            this.roundTimetext.textObject_black.setText('' + this.roundTimetext.displayedTime);
            this.roundTimetext.textObject.setText('' + this.roundTimetext.displayedTime);
            // Round end by time over

            this.executeTimeOut();

        }




    }

};