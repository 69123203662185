import Phaser from 'phaser';
import { CST } from '../CST';

export class HUD extends Phaser.Scene {

    constructor(config) {
        super({ key: CST.SCENES.HUD, active: false });
    }

    create() {

        this.count_down = 0;
        this.race_time = 123;
        this.total_time = 0;
        this.coin_count = 0;
        this.total_laps = 2;
        this.current_lap = 0;
        this.current_power = '';
        this.power_reference = null;
        this.position = "1st";
        this.position_data = 1;
        this.wrong_way = "";
        this.stroke = 3;

        /* Game Reference */
        this.game = this.scene.get(CST.SCENES.GAME);

        this.anims.create({
            key: 'mysterybox',
            frames: [
                { key: 'shell' },
                { key: 'star' },
                { key: 'slick' , duration: 50 },
            ],
            frameRate: 15,
            repeat: -1
        });
        
        this.one = this.add.image(this.sys.game.config.width / 2, -150, '1');
        this.one.alpha = 0;
        this.two = this.add.image(this.sys.game.config.width / 2, -150, '2');
        this.two.alpha = 0;
        this.three = this.add.image(this.sys.game.config.width / 2, -150, '3');
        this.three.alpha = 1;
        this.go = this.add.image(this.sys.game.config.width / 2, -150, 'go');
        this.go.alpha = 0;
        this.countdown_wrapper = [this.three, this.two, this.one, this.go];
        this.countdown_wrapper_sounds = ['three_sound', 'two_sound', 'one_sound', 'go_sound'];
        this.count_down_event = this.time.addEvent({ delay: 1000, callback: this.update_timer, callbackScope: this, repeat: 4, startAt: 1 });

        /* Lap time clock */
        this.race_time_text = this.add.text((this.sys.game.config.width / 2)  - 50, -10, this.race_time, {  fontFamily: 'VT323', fontSize: 100, color: '#9462A9', align: 'center' });
        this.race_time_text.setStroke('#663f87', this.stroke);
        this.race_time_event = this.time.addEvent({ delay: 1000, callback: this.update_race_time, callbackScope: this, repeat: 123, startAt: 0 });

        /* Coin updates */
        this.coin_text = this.add.text(10, 0, this.coin_count + "x", {  fontFamily: 'VT323', fontSize: 60, color: '#9462A9', align: 'left' });
        this.coin_text.setStroke('#663f87', this.stroke);
        this.coin = this.add.image(80, 35, 'coin_hud').setScale(2);


        this.time_out = this.add.image(this.sys.game.config.width / 2, -150, 'time_out');
        this.time_out.alpha = 0;

        this.left_flag = this.add.image(-150, this.sys.game.config.height/2 - 100, 'left_flag');
        this.left_flag.alpha = 0;

        this.right_flag = this.add.image(this.sys.game.config.width + 150, this.sys.game.config.height/2 - 100, 'right_flag');
        this.right_flag.alpha = 0;

        this.finished = this.add.image(this.sys.game.config.width / 2, -150, 'finished');
        this.finished.alpha = 0;

        this.wrong_way_image = this.add.image(this.sys.game.config.width / 2, -150, 'wrong');
        this.wrong_way_image.alpha = 0;

        /* Position updates */
        this.position_text = this.add.text(this.sys.game.config.width - 80, this.sys.game.config.height - 60, this.position, {  fontFamily: 'VT323', fontSize: 60, color: '#9462A9', align: 'left' });
        this.position_text.setStroke('#663f87', this.stroke);

        /* Lap Indicator */
        this.lap_indicator = this.add.text(10, this.sys.game.config.height - 60, `Lap ${this.current_lap} of ${this.total_laps}`, { fontFamily: 'VT323', fontSize: 60, color: '#9462A9', align: 'left' });
        this.lap_indicator.setStroke('#663f87', this.stroke);

        /* Power ups */
        this.power_reference = this.add.image(this.sys.game.config.width - 50, 50, 'empty').setScale(2);        
       
        var count = this.game.events.listenerCount('lap_complete');

        if(count === 0) {
        
            this.game.events.on('update_position', function (data) {
                this.updatePosition(data);
            }, this);

            this.game.events.on('update_direction', function (data) {
                this.updateDirection(data);
            }, this);

            this.game.events.on('finish_race', function () {
                this.endRace();
            }, this);
                    
            this.game.events.on('lap_complete', function () {
                this.lapComplete();
            }, this);

             /* Power Ups */
            this.game.events.on('add_mushroom', function () {
                this.updatePower('mushroom');
            }, this);

            this.game.events.on('add_jam', function () {
                this.updatePower('slick');
            }, this);

            this.game.events.on('add_starpower', function () {
                this.updatePower('star');
            }, this);

            this.game.events.on('add_shell', function () {
                this.updatePower('shell');
            }, this);

            this.game.events.on('used_power', function () {
                this.removePower();
            }, this);

            this.game.events.on('add_coin', function () {
                this.coin_count += 1;
            }, this);
    
        }
        
        this.super_particles = this.add.particles('spark');
        this.super_emiitter = this.super_particles.createEmitter({
            x: { min: 0, max: this.sys.game.config.width  },
            y: -50,
            width: 100,
            speed: 50,
            lifespan: 5000,
            gravityY: 150,
            scale: { start: 5, end: 6, random: false },
            rotate: { onEmit: function() { return Math.random() * 360 } },
            particleAngle: { start: 0, end: 360 },
            tint: [0x8b67a1, 0xe6b1c2, 0xe9abbf, 0xf8e898, 0x457b9d, 0xd5a169, 0x8b67a1]
        });

        this.super_emiitter.active = false;
        this.super_emiitter.on = false;
    }

    update() {
        
        //this.wrong_way_text.setText(this.wrong_way);

        if(this.race_time > 0 && this.race_time < 120) {
            this.race_time_text.setText(this.race_time + this.race_time_event.getProgress().toString().substr(0, 0));
        } else if(this.race_time >= 120) {
            this.race_time_text.setText(120);
        } else if (this.race_time === 0) {
            this.endRace();
        }

        //Position
        this.position_text.setText(this.position);

        //Coins
        this.coin_text.setText(this.coin_count + "x");
        if(this.coin_count > 9) {
            this.coin.x = 105;
        }

        //Laps
        this.lap_indicator.setText(`Lap ${this.current_lap}/${this.total_laps}`);
    }

    updatePosition(data) {
        // console.log(data);
        let readout = "";
        let pos = 0;
        switch (data) {
            case 4:
                readout = "1st"; 
                pos = 1;
                break;
            case 3:
                readout = "2nd"; 
                pos = 2;
                break;
            case 2:
                readout = "3rd"; 
                pos = 3;
                break;
            case 1:
                readout = "4th"; 
                pos = 4;
                break;
            case 0:
                readout = "5th"; 
                pos = 5;
                break;
            default:
                break;
        }

        this.position = readout;
        this.position_data = pos;
    }

    updateDirection(iswrong) {
        // console.log(iswrong);
        if(iswrong) {

            this.tweens.timeline({

                ease: 'Power2',
                duration: 300,
    
                tweens: [{
                        targets: this.wrong_way_image,
                        y: this.sys.game.config.height / 2,
                        alpha: 1,
                        offset: 0,
                    }
                ]
    
            });

        } else {

            this.tweens.timeline({

                ease: 'Power2',
                duration: 300,
    
                tweens: [{
                        targets: this.wrong_way_image,
                        y: -150,
                        alpha: 0,
                        offset: 0,
                    }
                ]
    
            });
        }
    }
 
    updatePower(power) {

        this.power_animation = this.add.sprite(660 - 50, 50, 'empty').setScale(2); ;
        this.power_animation.play('mysterybox');

        let timer = setTimeout(() => {
            
            this.power_animation.destroy();

            if(this.power_reference != null) {
                this.power_reference.destroy();
            }       
            this.power_reference = this.add.image(660 - 50, 50, power).setScale(2);
        }, 1000);

    }

    removePower() {
        if (this.power_reference != null) {
            this.power_reference.destroy();
            this.power_reference = this.add.image(660 - 50, 50, 'empty').setScale(2); 
        }
    }

    lapComplete() {
        this.current_lap += 1;
        
        if (this.current_lap === this.total_laps) {
            this.endRace();
        }
    }

    endRace() {
        this.total_time = this.race_time + this.race_time_event.getProgress().toString().substr(1, 3);
        let complete = false;
        if (this.current_lap === this.total_laps) {
            complete = true;
            this.super_emiitter.active = true;
            this.super_emiitter.on = true;

            this.tweens.timeline({

                ease: 'Power2',
                duration: 400,
    
                tweens: [{
                        targets: this.finished,
                        y: this.sys.game.config.height / 2,
                        alpha: 1,
                        offset: 0,
                    },
                    {
                        targets: this.left_flag,
                        x: this.sys.game.config.width/2 - 50,
                        angle: 360,
                        alpha: 1,
                        offset: 250
                    },{
                        targets: this.right_flag,
                        x: this.sys.game.config.width/2 + 50,
                        angle: 360,
                        alpha: 1,
                        offset: 250
                    }
                ]
    
            });

        } else {

            this.wrong_way_image.alpha = 0;

            this.tweens.timeline({

                ease: 'Power2',
                duration: 300,
    
                tweens: [{
                        targets: this.time_out,
                        y: this.sys.game.config.height / 2,
                        alpha: 1,
                        offset: 3000,
                    }
                ]
    
            });
        }
        this.game.disable(this.total_time, this.position_data, complete);
    }

    update_race_time() {
        this.race_time -= 1;
    }

    update_timer() {

        if(this.count_down === 4) {
            this.tweens.timeline({

                ease: 'Power2',
                duration: 200,

                tweens: [{
                        targets: this.countdown_wrapper[3],
                        y: this.sys.game.config.height + 150,
                        alpha: 1,
                        offset: 0,
                    }
                ]

            });
        } else {
            for(var i = 0; i < this.countdown_wrapper.length; i++) {
                this.countdown_wrapper[i].alpha = 0;
             }
        }

        if(this.count_down < this.countdown_wrapper.length) {
            this.game.events.emit(this.countdown_wrapper_sounds[this.count_down]);

            this.tweens.timeline({

                ease: 'Power2',
                duration: 200,
    
                tweens: [{
                        targets: this.countdown_wrapper[this.count_down],
                        y: this.sys.game.config.height / 2,
                        alpha: 1,
                        offset: 0,
                    }
                ]
    
            });


            //this.countdown_wrapper[this.count_down].alpha = 1;
            this.count_down += 1;  
        }

        if(this.count_down === 4) {
            this.game.enable();
            this.game.events.emit('normal_sound');
        } else if (this.count_down === 5) {
            this.count_down_event.destroy()
        }
    }
};
