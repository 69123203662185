export default function makeAnimations(scene) {
    // TONS of animations. Everything animation-related is ugly and stupid below.
    // TODO:  maybe use JSON to load animations



    let config = {
        key: 'brickTile',
        frames: scene.anims.generateFrameNumbers('tiles', {
            start: 23,
            end: 23,
            first: 23
        })
    };
    scene.anims.create(config);
    config = {
        key: 'blockTile',
        frames: scene.anims.generateFrameNumbers('tiles', {
            start: 16, //GOLD:14, BOX:15, BROWN:16
            end: 16,
            first: 16
        })
    };
    scene.anims.create(config);

    config = {
        key: 'boxTile',
        frames: scene.anims.generateFrameNumbers('tiles', {
            start: 15, //GOLD:14, BOX:15, BROWN:16
            end: 15,
            first: 15
        })
    };
    scene.anims.create(config);


    //cloud

    config = {
        key: 'cloud_pink',
        frames: scene.anims.generateFrameNumbers('cloud', {
            start: 0,
            end: 0,
            first: 0
        })
    };
    scene.anims.create(config);

    config = {
        key: 'cloud_peach',
        frames: scene.anims.generateFrameNumbers('cloud', {
            start: 1,
            end: 1,
            first: 1
        })
    };
    scene.anims.create(config);

    config = {
        key: 'cloud_brown',
        frames: scene.anims.generateFrameNumbers('cloud', {
            start: 2,
            end: 2,
            first: 2
        })
    };
    scene.anims.create(config);


    /////
    /////
    /////
    /////
    /////

    // ALL DONUT ANIMATIONS

    var char_state = ['small', 'big', 'mega'];
    var char_base = [

        //==========

        {
            key: "stand",
            repeat: -1,
            frameRate: 5,
            frameData: [0, 1, 2, 3]
        },

        {
            key: "jump",
            repeat: -1,
            frameData: [5]
        },

        {
            key: "bend",
            repeat: -1,
            frameData: [6]
        },

        {
            key: "run",
            repeat: -1,
            frameRate: 30,
            frameData: [10, 11, 12, 13]
        },

        {
            key: "turn",
            frameData: [7]
        },
        {
            key: "grow",
            frameRate: 10,
            frameData: [8, 9, 8, 9]
        },
        // {
        //     key: "hurt",
        //     frameData: [15]
        // },

        // {
        //     key: "attack",
        //     frameData: [5]
        // },
        {
            key: "climb",
            frameData: [18, 19],
            repeat: -1,
            frameRate: 10

        }
        // ,


        // {
        //     key: "win",
        //     frameData: [17]
        // }
        // ,

        // {
        //     key: "die",
        //     frameData: [16]
        // }

    ];

    //=====


    //====
    var clone = function(obj) {
        var copy;
        if (null == obj || "object" != typeof obj) return obj;
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = clone(obj[i]);
            }
            return copy;
        }
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
            }
            return copy;
        }
        throw new Error("Unable to copy obj! Its type isn't supported.");
    };


    //====

    var char_small = clone(char_base);
    var char_big = clone(char_base);
    var char_mega = clone(char_base);


    char_small.forEach(function(elem) {
        elem.key = elem.key + char_state[0];
        elem.frames = scene.anims.generateFrameNumbers('donut_' + char_state[0], {
            frames: elem.frameData
        });
        scene.anims.create(elem);
    });

    char_big.forEach(function(elem) {
        elem.key = elem.key + char_state[1];
        elem.frames = scene.anims.generateFrameNumbers('donut_' + char_state[1], {
            frames: elem.frameData
        });
        scene.anims.create(elem);
    });

    char_mega.forEach(function(elem) {
        elem.key = elem.key + char_state[2];
        elem.frames = scene.anims.generateFrameNumbers('donut_' + char_state[2], {
            frames: elem.frameData
        });
        scene.anims.create(elem);
    });



    config = {
        key: 'hurt',
        frames: scene.anims.generateFrameNumbers('donut_small', {
            frames: [14, 15, 14, 15]
        }),
        frameRate: 10
    };
    scene.anims.create(config);

    config = {
        key: 'attack',
        frames: scene.anims.generateFrameNumbers('donut_mega', {
            frames: [14, 4]

        }),
        frameRate: 10
        // repeat: -1
    };
    scene.anims.create(config);
    config = {
        key: 'die',
        frames: scene.anims.generateFrameNumbers('donut_small', {
            frames: [16]
        })
        // repeat: -1
    };
    scene.anims.create(config);

    /////

    config = {
        key: 'winsmall',
        frames: scene.anims.generateFrameNumbers('donut_small', {
            frames: [17, 8, 16, 16, 16, 16]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'winbig',
        frames: scene.anims.generateFrameNumbers('donut_big', {
            frames: [17, 15, 16, 16, 16, 16]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'winmega',
        frames: scene.anims.generateFrameNumbers('donut_mega', {
            frames: [17, 15, 16, 16, 16, 16]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);

    /////
    /////
    /////
    /////


    //==========
    // Other animations
    //==========


    config = {
        key: 'chocolate',
        frames: scene.anims.generateFrameNumbers('chocolate', {
            frames: [0, 1, 2, 3]
        }),
        frameRate: 5,
        repeat: -1
    };
    scene.anims.create(config);
    config = {
        key: 'chocolateFlat',
        frames: scene.anims.generateFrameNumbers('chocolate', {
            frames: [4]
        })
    };
    scene.anims.create(config);


    config = {
        key: 'cupcake',
        frames: scene.anims.generateFrameNumbers('cupcake', {
            frames: [0, 1]
        }),
        frameRate: 5,
        repeat: -1
    };
    scene.anims.create(config);
    config = {
        key: 'cupcake_lit1',
        frames: scene.anims.generateFrameNumbers('cupcake', {
            frames: [4, 5]
        }),
        frameRate: 5,
        repeat: -1
    };
    scene.anims.create(config);
    config = {
        key: 'cupcake_lit2',
        frames: scene.anims.generateFrameNumbers('cupcake', {
            frames: [6, 7]
        }),
        frameRate: 5,
        repeat: -1
    };
    scene.anims.create(config);
    config = {
        key: 'cupcake_lit3',
        frames: scene.anims.generateFrameNumbers('cupcake', {
            frames: [8, 9]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);
    config = {
        key: 'cupcake_lit4',
        frames: scene.anims.generateFrameNumbers('cupcake', {
            frames: [10, 11]
        }),
        frameRate: 20,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'cupcakeBurnt',
        frames: scene.anims.generateFrameNumbers('cupcake', {
            frames: [2]
        })
    };
    scene.anims.create(config);

    config = {
        key: 'cupcakeFlat',
        frames: scene.anims.generateFrameNumbers('cupcake', {
            frames: [3]
        })
    };
    scene.anims.create(config);

    config = {
        key: 'cookie',
        frames: scene.anims.generateFrameNumbers('cookie', {
            frames: [1, 2]
        }),
        frameRate: 5,
        repeat: -1
    };
    scene.anims.create(config);
    config = {
        key: 'cookieStop',
        frames: scene.anims.generateFrameNumbers('cookie', {
            frames: [0]
        })

    };

    scene.anims.create(config);

    config = {
        key: 'icecream',
        frames: scene.anims.generateFrameNumbers('icecream', {
            frames: [0, 8]
        }),
        frameRate: 5,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'icecream_3',
        frameRate: 30,
        frames: scene.anims.generateFrameNumbers('icecream', {
            frames: [1, 2, 1, 2, 1, 2, 1, 2, 1, 2]
        })

    };
    scene.anims.create(config);

    config = {
        key: 'icecream_2',
        frameRate: 30,
        frames: scene.anims.generateFrameNumbers('icecream', {
            frames: [3, 4, 3, 4, 3, 4, 3, 4, 3, 4]
        })

    };
    scene.anims.create(config);
    config = {
        key: 'icecream_1',
        frameRate: 30,
        frames: scene.anims.generateFrameNumbers('icecream', {
            frames: [5, 6, 5, 6, 5, 6, 5, 6, 5, 6]
        })

    };
    scene.anims.create(config);

    config = {
        key: 'icecreamFlat',
        frames: scene.anims.generateFrameNumbers('icecream', {
            frames: [7]
        })

    };
    scene.anims.create(config);



    // config = {
    //     key: 'flag',
    //     frames: [{
    //         key: 'mario-sprites',
    //         frame: 'flag'
    //     }],
    //     repeat: -1
    // };
    // scene.anims.create(config);



    config = {
        key: 'choctop',
        frames: scene.anims.generateFrameNumbers('items', {
            frames: [0, 0, 0, 0, 0, 1]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'coin',
        frames: scene.anims.generateFrameNumbers('items', {
            frames: [6, 7, 8, 9, 8, 7]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'heart',
        frames: scene.anims.generateFrameNumbers('items', {
            frames: [4, 4, 4, 4, 4, 5]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'rainbow',
        frames: scene.anims.generateFrameNumbers('items', {
            frames: [2, 2, 2, 2, 2, 3]
        }),
        frameRate: 10,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'star',
        frames: scene.anims.generateFrameNames('mario-sprites', {
            prefix: 'powerup/star',
            start: 1,
            end: 4
        }),
        frameRate: 30,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'fireFly',
        frames: scene.anims.generateFrameNames('sprinkle_ball', {
            frames: [0, 1, 2, 3, 4, 5, 6, 7]
        }),
        frameRate: 20,
        repeat: -1
    };
    scene.anims.create(config);

    config = {
        key: 'fireExplode',
        frames: scene.anims.generateFrameNames('sprinkle_explode', {
            frames: [0, 1, 2, 3, 4]
        }),
        frameRate: 30,
        repeat: 0
    };

    scene.anims.create(config);


    //==========

    scene.anims.create({
        key: "hotlight",
        frameRate: 20,
        repeat: -1,
        frames: scene.anims.generateFrameNumbers('hotlight', {

            frames: [0, 1, 2, 3, 4]

        })

    });


    scene.anims.create({
        key: "bg_animation",
        frameRate: 3,
        repeat: -1,
        frames: scene.anims.generateFrameNumbers('screen_bg', {
            frames: [0, 1]
        })
    });
    //==========
    scene.anims.create({
        key: "title_hotlight",
        frameRate: 10,
        frames: scene.anims.generateFrameNumbers('title_hotlight', {
            frames: [0, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 0, 2]
        })

    });
    scene.anims.create({
        key: "title_croissant",
        frameRate: 10,
        frames: scene.anims.generateFrameNumbers('title_croissant', {
            frames: [0, 1, 2]
        })

    });
    //==========

    //CROSSBOSS

    //==========

    scene.anims.create({
        key: "crossboss_idle",
        frames: scene.anims.generateFrameNumbers('crossboss', {
            frames: [1,0]
        }),
        frameRate: 8,

    });
    scene.anims.create({
        key: "crossboss_crouch",
        frames: scene.anims.generateFrameNumbers('crossboss', {
            frames: [1,2]
        }),
        frameRate: 8
    });
    scene.anims.create({
        key: "crossboss_launch",
        frames: scene.anims.generateFrameNumbers('crossboss', {
            frames: [4,5,6,7,6,7,6,7,6,7,6,7]
        }),
        frameRate: 15,
        // repeat: -1
    });

    scene.anims.create({
        key: "crossboss_jump",
        frames: scene.anims.generateFrameNumbers('crossboss', {
            frames: [3]
        }),
        repeat: -1
    });

    scene.anims.create({
        key: "crossboss_drill",
        frames: scene.anims.generateFrameNumbers('crossboss', {
            frames: [6]

            // frames: [7,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6]
        })
        // frameRate: 8
        // repeat: -1

    });
    scene.anims.create({
        key: "crossboss_hurt",
        frames: scene.anims.generateFrameNumbers('crossboss', {
            frames: [4]
        }),
        frameRate: 15,
        repeat: -1

    });
    scene.anims.create({
        key: "crossboss_died",
        frames: scene.anims.generateFrameNumbers('crossboss', {
            frames: [4]
        }),
        frameRate: 8,
        repeat: -1

    });




}