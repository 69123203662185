import Phaser from 'phaser';
import { CST } from '../CST';

export class IntermissionScene extends Phaser.Scene {

    constructor() {
        super({
            key: CST.SCENES.INTERMISSION
        });
    }

    preload() {

    }

    create(passedData) {
        // console.log("intermission");
        // console.log(passedData);
        passedData.round++;
        if (passedData.win >= 2 || passedData.lose >= 2) {

            this.scene.start(CST.SCENES.SCORE, passedData);
        } else {
            this.scene.start(CST.SCENES.GAME);
            this.scene.launch(CST.SCENES.HUD, passedData);


        }

    }

    update() {

    }



}