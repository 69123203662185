import Phaser from 'phaser';

import Enemy from './Enemy';

export default class Cupcake extends Enemy {
    constructor(config) {
        super(config);
        this.body.setVelocity(0, 0).setBounce(0, 0).setCollideWorldBounds(false);
        this.anims.play('cupcake');
        this.killAt = 0;
        this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer);

        this.body.setSize(16, 18, false);
        this.body.offset.y += 10

        this.isIgnited = false;
        this.burnLevel = 0;
        this.burnTimer = 1000;
        this.EXPLOSION_DISTANCE = 128;

        // Base horizontal velocity / direction.
        this.direction = -70;
        this.jumpTimer = 200;
    }

    update(time, delta) {
        // If it's not activated, then just skip the update method (see Enemy.js)
        if (!this.activated()) {
            return;
        }
        if(this.scene.killAllEnemies){
            // console.log('kill');
            this.kill();
        }
        //

        if (this.killAt !== 0) {
            // The killtimer is set, keep the flat Cupcake then kill it for good.
            this.body.setVelocityX(0);
            this.killAt -= delta;
            if (this.killAt < 0) {
                this.kill();
            }
            return;
        }

        if (this.isIgnited) {
            this.burnTimer -= delta;
            if (this.burnTimer <= 0) {
                this.burnTimer = 800;
                this.burnLevel++;



                if (this.burnLevel == 5) {
                    this.isIgnited = false;
                    // this.body.setSize(80, 80, false);
                    this.explode();

                } // 
                else {
                    this.play('cupcake_lit' + this.burnLevel);
                }
            }
            if (this.burnLevel != 5) {
                this.jumpTimer -= delta;
                if (this.jumpTimer <= 0) {
                    let dx = (this.body.x < this.scene.donut.body.x)?1:-1

                    this.body.setVelocity(dx*400,-250);
                    this.jumpTimer = Phaser.Math.Between(300, 700);

                }
            }

        }


        // Collide with Donut!
        this.scene.physics.world.overlap(this, this.scene.donut, this.donutHit);

        // The Cupcake stopped, better try to walk in the other direction.
        if (this.body.velocity.x === 0) {
            this.direction = -this.direction;
            this.body.velocity.x = this.direction;
        }


    }

    donutHit(enemy, donut) {
        if (enemy.verticalHit(enemy, donut)) {
            // Donut jumps on the enemy
            donut.enemyBounce(enemy);
            // enemy.scene.sound.playAudioSprite('sfx', 'smb_stomp');
            enemy.scene.sfx.stomp.play();

            enemy.getFlat(enemy, donut);
        } else {
            // Donut collides with the enemy
            enemy.hurtDonut(enemy, donut);
        }
    }

    explode() {
        this.scene.hudscene.updateEnemyKillHUD(this.type);
        
        this.getFlipped(this);
        this.play('cupcakeBurnt');

        this.scene.explodeParticleEmitter.explode(12, this.x, this.y);

        let dist = Phaser.Math.Distance.Between(this.x, this.y, this.scene.donut.x, this.scene.donut.y);
        //<static> Between(x1, y1, x2, y2)

        if (dist < this.EXPLOSION_DISTANCE) {
            this.hurtDonut(this, this.scene.donut);

        }

    }

    igniteCandle() {
        if (this.isIgnited) {
            return;
        }
        this.isIgnited = true;
        this.burnLevel = 1;

        this.play('cupcake_lit' + this.burnLevel);
    }

    getFlat(enemy, donut) {
        this.scene.hudscene.updateEnemyKillHUD(this.type);
        this.scene.impactParticleEmitter.explode(8, enemy.body.x, enemy.body.y + 64);

        enemy.play('cupcakeFlat');
        enemy.body.setVelocityX(0);
        enemy.body.acceleration.x = 0;
        // Keep cupcake flat for 500ms, then remove it.
        enemy.killAt = 2000;
    }
}