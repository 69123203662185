import React from 'react';

const Stickies = (props) => (
    <div className={'stickies'}>
        <div className="crack sticker"></div>
        {/* <div className="haha sticker"></div>
        <div className="doughnut sticker"></div> */}
        <div className="wow sticker"></div>
        <div className="radical sticker"></div>
        <div className="sheen sticker"></div>
        <div className="krispy_kreme sticker"></div>
        <div className="scuff_right_bevel sticker"></div>
        <div className="scuff_top_right sticker"></div>
        <div className="scuff_bottom_left sticker"></div>
        <div className="scuff_bottom_right sticker"></div>
    </div>
);

export default Stickies;