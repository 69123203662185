import Phaser from 'phaser';

export default class MovingPlatform extends Phaser.GameObjects.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, 'cloud');
        config.scene.add.existing(this);
        this.scene = config.scene;
        this.anims.play(config.key);

        this.scene.physics.world.enable(this);
        this.body.setAllowGravity(false);
        this.body.setImmovable(true);
        this.setScale(4);
        this.setOrigin(0, 0);
        this.scene.movingPlatformGroup.add(this);
        this.tweenData = config;
        this.tweenPlatform = null;

        this.beenSeen = false;



    }

    update() {
        if (!this.activated()) {
            return;
        }


    }
    activated(){
            // console.log('running activated')

        if (!this.beenSeen) {
            // console.log('mp NOT seen')
            // check if it's being seen now and if so, activate it
            if (this.x < this.scene.cameras.main.scrollX + this.scene.sys.game.canvas.width + 32 + 64) {
                this.beenSeen = true;
                // console.log('mp been seen')
                this.startMoving();

                return true;
            }
            return false;
        }

    }
    startMoving() {
        this.setPosition(this.tweenData.x, this.tweenData.y);
        //tween the platform's velocity
        this.tweenPlatform = this.scene.tweens.timeline({
            targets: this.body.velocity,
            loop: -1,
            tweens: [
                { x: 0, y: 0, duration: this.tweenData.hold, ease: 'Stepped' },
                { x: this.tweenData.velX, y: this.tweenData.velY, duration: this.tweenData.duration1, ease: 'Stepped' },
                { x: 0, y: 0, duration: this.tweenData.hold, ease: 'Stepped' },
                { x: -this.tweenData.velX * (this.tweenData.duration1 / this.tweenData.duration2), y: -this.tweenData.velY * (this.tweenData.duration1 / this.tweenData.duration2), duration: this.tweenData.duration2, ease: 'Stepped' },

            ]
        });


    }

}