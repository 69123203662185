import Phaser from 'phaser';
import { InitScene } from './Init/InitScene';
import { PreloadScene } from './Preload/PreloadScene';
import { ControlScene } from './Control/ControlScene';
import { ThrowbackTitleScene } from './ThrowbackTitle/ThrowbackTitleScene';
import { BrownieTitleScene } from './BrownieTitle/BrownieTitleScene';
import { BrownieScene } from './Game/BrownieScene';
import { InstructionScene } from './Instruction/InstructionScene';
import { HUD } from './HUD/HUD';
import { ScoreScene } from './End/ScoreScene';
import { SoundManagerScene } from './Sound/SoundManagerScene';

export default class Brownie {

    constructor() {
        const STAGE_PADDING = 60;
        const STAGE_WIDTH = (window.innerWidth - STAGE_PADDING);
        const STAGE_HEIGHT = STAGE_WIDTH;
        const STAGE_COLOUR = "#5D5D5D";
        const CANVAS = "CanvasWrapper";
        const GAME_CONFIG = {
            scale: {
                parent: CANVAS,
                mode: Phaser.Scale.FIT,
                width: 660,
                height: 660
            },
            type: Phaser.AUTO,
            width: STAGE_WIDTH,
            height: STAGE_HEIGHT,
            backgroundColor: STAGE_COLOUR,
            parent: CANVAS,
            fps: {
                target: 60,
            },
            render: {
                pixelArt: true
            },
            physics: {
                default: 'arcade',
                arcade: {
                    // debug : true
                }
            },
            scene: [
                InitScene,
                PreloadScene,
                ControlScene,
                ThrowbackTitleScene,
                BrownieTitleScene,
                InstructionScene,
                BrownieScene,
                HUD,
                ScoreScene,
                SoundManagerScene
            ],
            audio: {
                disableWebAudio: false
            }
        };

        var game = new Phaser.Game(GAME_CONFIG);
    }
}