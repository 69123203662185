import Phaser from 'phaser';
import { CST } from '../CST';

export class BrownieTitleScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.BROWNIE_TITLE });
    }

    preload() {

        this.anims.create({
            key: 'bash_bounce',
            frames: this.anims.generateFrameNumbers('bash_bounce', {
                frames: [0,1,2,3,4,5,6,7,8,9,9,8,7,6,5,4,3,2,1]
            }),
            frameRate: 20,
            repeat: -1
        });




    }

    create() {
        this.mainImage = this.add.image(330, 330, 'brownie_batter_intro');



        this.character = this.add.sprite(108, 404, 'bash_bounce').setScale(4);
        this.character.play('bash_bounce');


        this.control = this.scene.get(CST.SCENES.CONTROL);
    }

    update() {
        if (this.control.keys.green.isDown || this.control.keys.pink.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;
                this.understood();

            }
        }
    }

    understood() {
        this.scene.start(CST.SCENES.INSTRUCTION, { isFromBeginning: true });
    }
}