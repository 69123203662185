import React, { Component } from 'react'

export default class SquishyButton extends Component {
  render(props) {
    return (
      <div>
        <a className={`squishy ${this.props.pink} ${this.props.game_over}`}>Replay Game</a>
      </div>
    )
  }
}
