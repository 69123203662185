import Phaser from 'phaser';

import Enemy from './Enemy';

export default class Crossboss extends Enemy {
    constructor(config) {
        super(config);
        this.body.setVelocity(0, 0).setBounce(0, 0).setCollideWorldBounds(false);
        this.anims.play('crossboss_idle');
        this.killAt = 0;
        this.health = 3;
        this.sprinkleShield = 10;
        this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer);

        // this.body.setSize(60, 60, false);

        this.setBodySize('normal');


        this.currentState = 'idle';

        this.DRILL_VELOCITY_X = 700;
        this.JUMP_VELOCITY_Y = -950;
        this.JUMP_VELOCITY_X = 300;

        this.MAX_ATTACK_TIMER = 1200;
        this.MIN_ATTACK_TIMER = 100;

        this.DRILL_DURATION = 900;

        this.HURT_DURATION = 3000;
        this.CROUCH_TIMER = 700;
        this.LAUNCH_TIMER = 700;
        this.attackTimer = 800;
        this.crouchTimer = 700;
        this.launchTimer = 700;

        // Base horizontal velocity.
        this.direction = 0;
        this.drillDuration = 1000;

        this.flash = {
            active: false,
            timer: 0,
            step: 0
        }

    }
    setBodySize(a) {
        let ws = 52,
            hs = 52;

        // let ws = 52,
        //     hs = 56;
        // switch (a) {
        //     case 'drilling':
        //         ws = 88;
        //         hs = 56;
        //         break;
        //     case 'normal':
        //         ws = 52;
        //         hs = 56;
        //         break;
        // }

        this.body.setSize(ws, hs, false);
        this.body.setOffset((100 - ws) / 2, (80 - hs) / 2);
        this.body.offset.y += 14;

    }



    activateFlash(a) {
        //console.log('activate flash')
        this.flash.active = true;
        this.flash.timer = a;

    }

    deactivateFlash() {
        //console.log('deactivate flash')
        this.flash.active = false;
        this.flash.timer = 0;

    }

    flashedBySprinkle(delta) {

        if (this.flash.timer <= 0) {
            this.tint = 0xFFFFFF;
            this.flash.active = false;
            //console.log('flash ended')

        } else {
            this.flash.timer -= delta;
            this.flash.step = (this.flash.step === 5) ? 0 : this.flash.step + 1;
            this.tint = [0xFFFFFF, 0xFF0000, 0xFFFFFF, 0x00FF00, 0xFFFFFF, 0x0000FF][this.flash.step];
            //console.log('tint change')

        }
    }


    update(time, delta) {



        if (this.flash.active) {
            this.flashedBySprinkle(delta);
        }
        
        if (this.currentState == 'hurt') {
            return;
        }
        // If it's not activated, then just skip the update method (see Enemy.js)
        if (!this.activated()) {
            return;
        }
        if (this.scene.killAllEnemies) {
            this.kill();
        }
        //

        if (this.currentState == 'dead' && this.killAt !== 0) {
            // The killtimer is set, keep the flat Crossboss then kill it for good.
            this.body.setVelocityX(0);
            this.killAt -= delta;
            if (this.killAt < 0) {
                this.kill();
            }
            return;
        }

        // Collide with Donut!
        this.scene.physics.world.overlap(this, this.scene.donut, this.donutHit);

        if (this.currentState == 'drilling') {
            this.drillDuration -= delta;
            if (this.drillDuration <= 0) {
                this.drillDuration = this.DRILL_DURATION;
                this.endDrilling();
            }
        } //
        else if (this.currentState == 'crouching') {
            this.crouchTimer -= delta;
            if (this.crouchTimer <= 0) {
                this.crouchTimer = this.CROUCH_TIMER;
                this.startJumping();
            }

        } //
        else if (this.currentState == 'launching') {
            this.launchTimer -= delta;
            if (this.launchTimer <= 0) {
                this.launchTimer = this.LAUNCH_TIMER;
                this.startDrilling();
            }

        } // 
        else if (this.currentState == 'jumping') {
            if (this.body.blocked.down) {
                this.endJumping();
            }

        } //
        else if (this.currentState == 'idle') {
            this.attackTimer -= delta;
            if (this.attackTimer <= 0) {
                (Phaser.Math.Between(0, 1) === 0) ? this.startLaunching(): this.startCrouching();
            }

        }


        if (this.body.blocked.left) {
            this.flipX = true;
            this.setVelocityAndDirection();

        } else if (this.body.blocked.right) {
            this.flipX = false;
            this.setVelocityAndDirection();

        }


    }
    resetAttackTimer() {
        this.attackTimer = Phaser.Math.Between(this.MAX_ATTACK_TIMER, this.MIN_ATTACK_TIMER);
        this.drillDuration = this.DRILL_DURATION;
    }

    setVelocityAndDirection() {

        let d = this.flipX ? 1 : -1;

        switch (this.currentState) {
            case 'idle':
                this.body.setVelocityX(0);

                break;
            case 'jumping':
                this.body.setVelocityX(this.JUMP_VELOCITY_X * d);

                break;

            case 'drilling':
                this.body.setVelocityX(this.DRILL_VELOCITY_X * d);

                break;

        }

    }

    getToasted(enemy) {
        // Killed by a star or hit from below with a block, later on also fire
        if (!enemy.alive || enemy.currentState == 'hurt' || enemy.currentState == 'dead') {
            return;
        }
        this.activateFlash(300);

        enemy.sprinkleShield -= 1;
        enemy.scene.sfx.stomp.play();
        //console.log('sprinkle hit', enemy.sprinkleShield);
        if (enemy.sprinkleShield === 0) {
            enemy.sprinkleShield = 10;
            enemy.getFlat(enemy, null);
        }

    }


    startCrouching() {
        this.currentState = 'crouching';
        this.play('crossboss_crouch');

    }
    startLaunching() {
        this.currentState = 'launching';
        this.play('crossboss_launch');

    }
    startJumping() {
        this.currentState = 'jumping';
        this.play('crossboss_jump');

        //console.log('Jump!')

        this.setVelocityAndDirection();
        this.setBodySize('normal');

        this.body.setVelocityY(this.JUMP_VELOCITY_Y);

        // this.resetAttackTimer();


    }
    endJumping() {
        this.currentState = 'idle';

        this.play('crossboss_idle');

        //default crossboss image should look to the left. keep it same as icecream and choc
        //console.log('END Drill')
        this.setBodySize('normal');

        this.setVelocityAndDirection();
        this.setBodySize('normal');

        //reset drill variables
        this.resetAttackTimer();

    }

    startDrilling() {
        this.currentState = 'drilling';
        this.play('crossboss_drill');

        //default crossboss image should look to the left. keep it same as icecream and choc
        //console.log('Drill!')
        this.setVelocityAndDirection();
        this.setBodySize('drilling');

        //reset drill variables
        // this.resetAttackTimer();

    }

    endDrilling() {
        this.currentState = 'idle';
        this.play('crossboss_idle');

        //default crossboss image should look to the left. keep it same as icecream and choc
        //console.log('END Drill')
        this.setVelocityAndDirection();
        this.setBodySize('normal');

        //reset drill variables
        this.resetAttackTimer();
    }

    donutHit(enemy, donut) {

        if (enemy.currentState == 'hurt' || enemy.currentState == 'dead') {
            return;
        }

        if (enemy.verticalHit(enemy, donut)) {

            // Donut jumps on the enemy
            donut.enemyBounce(enemy);
            // enemy.scene.sound.playAudioSprite('sfx', 'smb_stomp');
            enemy.scene.sfx.stomp.play();

            enemy.getFlat(enemy, donut);
        } else {
            // Donut collides with the enemy
            enemy.hurtDonut(enemy, donut);

        }
    }

    getFlat(enemy, donut) {

        enemy.health--;
        enemy.currentState = 'hurt';

        enemy.play('crossboss_hurt');
        enemy.body.setVelocityX(0);

        // enemy.killAt = 2000;

        if (enemy.health == 0) {

            this.deactivateFlash();

            this.scene.impactParticleEmitter.explode(40, enemy.body.x, enemy.body.y + 64);

            enemy.play('crossboss_died');
            this.scene.hudscene.updateEnemyKillHUD(this.type);

            enemy.body.enable = false;
            enemy.currentState = 'dead';

            this.scene.cameras.main.shake(1000, 0.03);
            enemy.killAt = 800;
            // this.scene.ladderReveal();

        } //
        else {
            this.activateFlash(2400);

            this.scene.impactParticleEmitter.explode(10, enemy.body.x, enemy.body.y + 64);

            this.scene.time.delayedCall(this.HURT_DURATION, function() {

                this.flipX = (this.x < this.scene.donut.x) ? true : false;
                enemy.drillDuration = 1000;
                enemy.startCrouching();

            }, [], this);

        }


    }
}