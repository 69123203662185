import Phaser from 'phaser';
import {
  CST
} from '../CST';

export class ThrowbackTitleScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.THROWBACK_TITLE
    });
  }

  preload() {

  }

  create() {
    const background = this.add.graphics();
    background.fillStyle(0x000000, 1);
    background.fillRect(0, 0, 660, 660);
    //Add stars
    this.particles = this.add.particles('particle');

    this.emitter = this.particles.createEmitter({
      x: 330,
      y: 330,
      bounce: true,
      lifespan: 1000,
      alpha: 0.6,
      speed: {
        min: 300,
        max: 360
      },
      gravityY: 0,
      scale: {
        start: 1,
        end: .7
      },
      quantity: 1,
      blendMode: 'ADD',
      timeScale: 0.7
    });

    this.mainImage = this.add.image(330, 330, 'preloader');
    this.add.text(330 - 40, 660 - 110, 'PRESENTS...', {
      fontFamily: 'VT323',
      color: '#41b1e4'
    });

    this.scene.launch(CST.SCENES.CONTROL);
    this.control = this.scene.get(CST.SCENES.CONTROL);

    this.time.delayedCall(1.5 * 1000, function() {
      this.understood();
    }, [], this);

  }

  update() {
    if (this.control.keys.punch.isDown || this.control.keys.kick.isDown) {
      this.control.keyPressed = true;
    } else {
      if (this.control.keyPressed) {
        this.control.keyPressed = false;
        this.understood();

      }
    }
  }

  understood() {
    this.scene.start(CST.SCENES.GAME_TITLE);

  }
  pressAnyKey() {
    this.input.keyboard.on('keydown', function(event) {
      this.understood();
    }, this);

  }
}
