import React, { Component } from 'react'
import DataStore from '../Store/DataStore';

export default class Switch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pressed: false
        }
        this.press = this.press.bind(this);
    }

    press(){
        this.setState({
            pressed: !this.state.pressed
        });

        DataStore.mute = this.state.pressed;
        let event = null;

        if(this.state.pressed) {
            event = new Event(this.props.up_event, { bubbles: true });
        } else {
            event = new Event(this.props.down_event, { bubbles: true });
        }
    
        document.dispatchEvent(event);
    }

    render() {
        return (
            <div className={this.props.type + this.state.pressed} onClick={this.press}></div>
        )
    }
}
