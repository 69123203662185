import Phaser from 'phaser';

export default class Heart extends Phaser.GameObjects.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.scene = config.scene;

        this.body.gravity.y = Phaser.Math.Between(150, 250);

        this.scene.physics.add.collider(this, this.scene.box, this.hitHeart, null, this);

    }


    hitHeart() {
        this.scene.soundscene.yay.play();
        this.scene.hudscene.lifeGained();

        // this.scene.boxBonus = true;
        this.destroy();

    }

};