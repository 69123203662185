import DataStore from '../Store/DataStore';

export default class Easter { 
    constructor() {
        this.last_command = "empty";
        this.easter_container = [];
        this.easter_egg = false;
        this.sequence = ["up", "down", "left", "right", "button_b", "button_a"];

        document.addEventListener("left", function(event) {
            this.update("left");
        }.bind(this));

        document.addEventListener("right", function(event) {
            this.update("right");
        }.bind(this));

        document.addEventListener("down", function(event) {
            this.update("down");
        }.bind(this));

        document.addEventListener("up", function(event) {
            this.update("up");
        }.bind(this));

        document.addEventListener("button_a", function(event) {
            this.update("button_a");
        }.bind(this));
        
        document.addEventListener("button_b", function(event) {
            this.update("button_b");
        }.bind(this));
    }

    update(command) {

        if(command === this.last_command) {

        } else {
            this.last_command = command;
            this.easter_container.push(command);
    
            if(this.easter_container.length >= this.sequence.length) {
                this.easter_egg = true;

                for(var i = 0; i < this.easter_container.length; i++) {
                    if(this.easter_container[i] !== this.sequence[i]) {
                        this.last_command = "empty";
                        this.easter_egg = false;
                        this.easter_container = [];
                        break;
                    }
                }
                
                if(this.easter_egg === true) {
                    DataStore.setEaster();

                } else {
                    setTimeout(() => {
                        //console.log('cleared');
                        this.last_command = "empty";
                        this.easter_egg = false;
                        this.easter_container = [];
                    }, 500);
                }
            }
        }
    }
};
