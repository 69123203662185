import React, { Component } from 'react'

export default class Desktop extends Component {
  render() {
    return (
      <div className="desktop">
        <div className="message">
          <img className="logo" src="/generic/throwback_krispy_kreme_logo.png" />
          <p>Looking to play some rad games?<br/>Krispy Kreme Arcade is for mobile only.<br/>Grab your mobile and play now.</p>
          <img className="desktop_only" src="/generic/desktop.png" />
        </div>
      </div>
    )
  }
}
