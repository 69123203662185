import React, { Component } from 'react'
import Button from './Button';
import Graphics from './Graphics';
import Pad from './Pad';
import Vector2 from './Vector2';
import Switch from './Switch';
import Easter from './Easter'

export default class Canvas extends Component {

    constructor(props) {

        super(props);

        this.easter = new Easter();

        this._animationRequest = null;
        this._canvas = null;
        this._ctx = null;
        this._dpr = 1;
        this.halfWidth = 0;
        this.halfHeight = 0;
        this.touchable = true;
        // this.touchable = 'createTouch' in document;
        this.leftTouchID = -1;
        this.leftTouchPos = new Vector2(0, 0);
        this.leftTouchStartPos = new Vector2(0, 0);
        this.leftVector = new Vector2(0, 0);
        this._touches = [];
        this._hit_dist = 50;

        this.state = {
            _width: window.innerWidth,
            _height: window.innerHeight,
            _started : false
        }

        this._pad = null;
        this._button_a = null;
        this._button_b = null;
        this._button_container = [];
        this._button_bg = null;

        /* Functions */
        this.animate = this.animate.bind(this);
        this.rescale = this.rescale.bind(this);
        this.touch = this.touch.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchEnd.bind(this);
        this.distance = this.distance.bind(this);
    }

    componentDidMount() {

        this.setState({
            _width: window.innerWidth,
            _height: window.innerHeight,
            _muted : false
        });

        this._ctx = this._canvas.getContext('2d');
        let screen_end = this.state._width;
        let d_pad_height = 165;
        let vertical_center = (this.state._width + (this.state._height - screen_end)/2) - d_pad_height/2;
        let button_height = 60;

        //Set up controls
        this._button_a = new Button(this._ctx, this.state._width - 150, vertical_center + d_pad_height/2 - button_height/2, 60, 60, '/controller/console/green_button_up.png', '/controller/console/green_button_down.png', 'button_a');
        this._button_b = new Button(this._ctx, this.state._width - 80, vertical_center + d_pad_height/2 - button_height/2, 60, 60, '/controller/console/pink_button_up.png', '/controller/console/pink_button_down.png', 'button_b');
        this._button_bg = new Graphics(this._ctx, this.state._width - 170, vertical_center + d_pad_height/2 - button_height + 5, 150, 95, '/controller/console/divot.png');
        this._button_container.push(this._button_a, this._button_b);
        
        this._pad = new Pad(this._ctx, 15, vertical_center, d_pad_height, d_pad_height, '/controller/dpad/up.png', '/controller/dpad/up_pressed.png', '/controller/dpad/right_pressed.png', '/controller/dpad/down_pressed.png', '/controller/dpad/left_pressed.png' )

        window.addEventListener('resize', this.rescale);
        this.rescale();

        setTimeout(() => {
            this.rescale();
        }, 3000);

        this._animationRequest = window.requestAnimationFrame(this.animate);

        this.touch();

        document.addEventListener("button_mute", function (event) {
            let mute = this.state._muted;
            this.setState({
                _muted: !mute
            });
        }.bind(this));
    }

    touch() {
        if (this.touchable) {
            this._canvas.addEventListener('touchstart', this.onTouchStart, false);
            this._canvas.addEventListener('touchmove', this.onTouchMove, false);
            this._canvas.addEventListener('touchend', this.onTouchEnd, false);
            window.onorientationchange = this.rescale();
            window.onresize = this.rescale();
        }
    }

    onTouchStart(e) {
        e.preventDefault();
    
        for (var i = 0; i < e.changedTouches.length; i++) {
            var touch = e.changedTouches[i];
            if ((this.leftTouchID < 0) && (touch.clientX < this.halfWidth)) {
                this.leftTouchID = touch.identifier;
                this.leftTouchStartPos.reset(touch.clientX, touch.clientY);
                this.leftTouchPos.copyFrom(this.leftTouchStartPos);
                this.leftVector.reset(0, 0);
                continue;
            }
        }
        this._touches = e.touches;
    }

    onTouchMove(e) {
        e.preventDefault();

        for (var i = 0; i < e.changedTouches.length; i++) {
            var touch = e.changedTouches[i];
            if (this.leftTouchID === touch.identifier) {
                this.leftTouchPos.reset(touch.clientX, touch.clientY);
                this.leftVector.copyFrom(this.leftTouchPos);
                this.leftVector.minusEq(this.leftTouchStartPos);
                break;
            }
        }
        this._touches = e.touches;
    }

    onTouchEnd(e) {
        e.preventDefault();

        this._touches = e.touches;
        for (var i = 0; i < e.changedTouches.length; i++) {
            var touch = e.changedTouches[i];
            if (this.leftTouchID === touch.identifier) {
                this.leftTouchID = -1;
                this.leftVector.reset(0, 0);
                break;
            }
        }
    }

    rescale() {
        window.scrollTo(0, 0); 

        this._dpr = window.devicePixelRatio || 1;

        let rect = this._canvas.getBoundingClientRect();
        let width = Math.round(this._dpr * rect.right) - Math.round(this._dpr * rect.left);
        let height = Math.round(this._dpr * rect.bottom) - Math.round(this._dpr * rect.top);

        this.halfHeight = height/2;
        this.halfWidth = width/2;

        this.setState({
            _width: width,
            _height: height
        });

        this._canvas.setAttribute('width', width);
        this._canvas.setAttribute('height', height);
        
        this.scaleControls();

        window.scrollTo(0, 0); 
    }

    scaleControls() {

        let screen_end = window.innerWidth;
        let d_pad_height = 165;
        let vertical_center = (window.innerWidth + (window.innerHeight - screen_end)/2) - d_pad_height/2;
        let button_height = 60;

        this._button_a._x = window.innerWidth - 150;
        this._button_a._y = vertical_center + d_pad_height/2 - button_height/2;

        this._button_b._x = window.innerWidth - 80;
        this._button_b._y = vertical_center + d_pad_height/2 - button_height/2;

        this._button_bg._x = window.innerWidth - 170;
        this._button_bg._y = vertical_center + d_pad_height/2 - button_height + 5;

        this._button_bg.draw();
        this._button_a.draw();
        this._button_b.draw();

        this._pad._x = 15;
        this._pad._y = vertical_center;

    }

    animate() {

        this._ctx.save();
        this._ctx.scale(this._dpr, this._dpr);
        this._ctx.clearRect(0, 0, this.state._width / this._dpr, this.state._height / this._dpr);

        this._button_bg.draw();
        
        for (var j = 0; j < this._button_container.length; j++) {
            this._button_container[j].draw();
            this._button_container[j]._depressed = false;
        }

        this._pad.draw();
        this._pad._direction = 0;

        if(this.touchable) {

            for(var i = 0; i < this._touches.length; i++) {
                
                var touch = this._touches[i]; 

                this._touchAreaX = touch.clientX;
                this._touchAreaY = touch.clientY;

                if(touch.identifier === this.leftTouchID) {
                    this._ctx.beginPath(); 
                    this._ctx.strokeStyle = "#FFFFFF"; 
                    this._ctx.lineWidth = 6; 
                    this._ctx.arc(this.leftTouchStartPos.x, this.leftTouchStartPos.y, 20, 0, Math.PI*2, true); 
                    this._ctx.stroke();
                    this._ctx.beginPath(); 
                    this._ctx.strokeStyle = "#FFFFFF"; 
                    this._ctx.arc(this.leftTouchPos.x, this.leftTouchPos.y, 20, 0, Math.PI*2, true); 
                    this._ctx.stroke(); 
                } else {
                    this._ctx.beginPath(); 
                    this._ctx.fillStyle = "white";
                    this._touchposx = touch.clientX;
                    this._touchposy = touch.clientY;
                    this._ctx.beginPath(); 
                    this._ctx.strokeStyle = "#FFFFFF";
                    this._ctx.lineWidth = 6;
                    this._ctx.arc(touch.clientX, touch.clientY, 20, 0, Math.PI*2, true); 
                    this._ctx.stroke();
                }

                if (touch.clientX < (this.state._width / this._dpr) / 2) {
                    //Only check if touch is on the left
                    let pad_dist = this.distance_two(this._pad._x + (this._pad._width / 2), this._pad._y + (this._pad._height / 2), touch.clientX, touch.clientY);
                    if (pad_dist[0] < 100) {
                        if (pad_dist[1] > 0 && pad_dist[2] >= -30 && pad_dist[2] <= 30) {
                            this._pad._direction = 1;
                        } else if (pad_dist[1] < 0 && pad_dist[2] >= -30 && pad_dist[2] <= 30) {
                            this._pad._direction = 3;
                        } else if (pad_dist[2] < 0 && pad_dist[1] >= -30 && pad_dist[1] <= 30) {
                            this._pad._direction = 2;
                        } else if (pad_dist[2] > 0 && pad_dist[1] >= -30 && pad_dist[1] <= 30) {
                            this._pad._direction = 4;
                        } else {
                            this._pad._direction = 0;
                        }
                    }
                } else {
                    //Only check if touch is on the right
                    for (var k = 0; k < this._button_container.length; k++) {
                        let dist = this.distance(this._button_container[k]._x + this._button_container[k]._width / 2, this._button_container[k]._y + this._button_container[k]._height / 2, touch.clientX, touch.clientY);
                        if (dist < this._hit_dist) {
                            this._button_container[k]._depressed = true;
                        } else {
                            this._button_container[k]._depressed = false;
                        }
                    }
                }
            }
        }

        this._ctx.restore();
        this._animationRequest = window.requestAnimationFrame(this.animate);
    }

    distance(x, y, ox, oy) {
        return Math.floor(Math.sqrt(Math.pow(x - ox, 2) + Math.pow(y - oy, 2)));
    }

    distance_two(x, y, ox, oy) {
        let dist = Math.floor(Math.sqrt(Math.pow(x - ox, 2) + Math.pow(y - oy, 2)));
        let dist_y = (y - oy);
        let dist_x = (x - ox);
        return [dist, dist_y, dist_x];
    }

    render() {
        return (
            <div className="console">
                <canvas ref={(canvas) => { this._canvas = canvas; }} />
                <Switch type={'switchmute mute'} up_event={'unmute'} down_event={'mute'} />
                <Switch type={'switchhelp help'} up_event={'unhelp'} down_event={'help'} />
            </div>
        )
    }
}
