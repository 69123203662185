export default class Button {
    constructor(ctx, x, y, width, height, button_up, button_down, event) {
        this._ctx = ctx;
        this._x = x;
        this._y = y;
        this._height = height;
        this._width = width;
        this._button_up = new Image();
        this._button_up.src = button_up;
        this._button_down = new Image();
        this._button_down.src = button_down;
        this._depressed = false;
        this._isDown = false;
        this._event = event;

        this._image_container = [this._button_up, this._button_down];
        for (var i = 0; i < this._image_container.length; ++i) {
            this._image_container[i].onload = function() {
                if (i === this._image_container.length) {
                    this.draw();
                }
            }.bind(this);
        }
    }

    draw() {
        this._ctx.save();
        this._ctx.translate(this._x + this._width / 2, this._y + this._height / 2);
        this._ctx.globalAlpha = this._opacity;
        this._ctx.imageSmoothingEnabled = true;
        this._ctx.imageSmoothingQuality = "high"
        this._ctx.rotate(this._rotation * Math.PI / 180);
        this._ctx.translate(-this._x - this._width / 2, -this._y - this._height / 2);

        if (!this._depressed) {
            if (this._isDown) {
                this._isDown = false;
                this.disptachUp();
            }
            this._depressedCount += 1;
            this._ctx.drawImage(this._button_up, this._x, this._y, this._width, this._height);
        } else {
            this.dispatch();
            this._isDown = true;
            this._ctx.drawImage(this._button_down, this._x, this._y, this._width, this._height);
        }

        this._ctx.restore();
    }

    dispatch() {
        let event = new Event(this._event, { bubbles: true });
        document.dispatchEvent(event);
    }

    disptachUp() {
        let event = new Event(this._event + "_up", { bubbles: true });
        document.dispatchEvent(event);
    }
};