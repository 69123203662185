import Phaser from 'phaser';

import Enemy from './Enemy';

export default class Icecream extends Enemy {
    constructor(config) {
        super(config);
        this.flipX = true;
        this.anims.play('icecream');
        this.killAt = 0;
        this.scoops = 4;
        // this.type = 'icecream';
        this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer);

        this.body.setSize(16, 50, false);
        this.body.setBounce(0, 0.3);
        this.direction = 0;
        this.jumpTimer = 2500;
    }

    update(time, delta) {
        if (!this.activated()) {
            return;
        }
        if(this.scene.killAllEnemies){
            // console.log('kill');
            this.kill();
        }
        //

        if (this.killAt !== 0) {
            // The killtimer is set, keep the flat Icecream then kill it for good.
            this.body.setVelocityX(0);
            this.killAt -= delta;
            if (this.killAt < 0) {
                this.kill();
            }
            return;
        }
        this.flipX = (this.x < this.scene.donut.x) ? true : false;
        this.scene.physics.world.overlap(this, this.scene.donut, this.donutHit);

        this.jumpTimer -= delta;
        if (this.jumpTimer <= 0 
            // && !this.scene.donut.body.blocked.down
            ) {
            // console.log('jump!', delta)
            this.body.setVelocityY(-380);
            this.jumpTimer = Phaser.Math.Between(1100, 2200);

        }


    }

    donutHit(enemy, donut) {

        if (enemy.verticalHit(enemy, donut)) {
            // Donut jumps on the enemy
            donut.enemyBounce(enemy);

            enemy.scene.sfx.stomp.play();
            enemy.scoops--;
            // console.log(enemy.scoops);

            if (enemy.scoops === 0) {

                enemy.body.offset.y = 0;

                enemy.getFlipped(enemy);

            }
            //
            else {

                enemy.resizeScoops(enemy);

            }
        }
        //
        else {
            // Donut collides with the enemy
            enemy.hurtDonut(enemy, donut);
        }
    }
    resizeScoops(enemy) {

        this.scene.impactParticleEmitter.explode(8, enemy.body.x, enemy.body.y + 64);
        enemy.body.setSize(16, (enemy.body.height / 4) - 9, false)
        enemy.body.offset.y += 9;

        enemy.play('icecream_' + enemy.scoops);

    }
    getFlat(enemy, donut) {
        this.scene.hudscene.updateEnemyKillHUD(this.type);
        this.scene.impactParticleEmitter.explode(8, enemy.body.x, enemy.body.y + 64);

        enemy.play('icecreamFlat');
        enemy.body.setVelocityX(0);
        enemy.body.acceleration.x = 0;
        // Keep chocolate flat for 500ms, then remove it.
        enemy.killAt = 500;
    }



}