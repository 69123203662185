import Phaser from 'phaser';

export default class Line extends Phaser.GameObjects.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key, config.rotation, config.id = "");
        this.rotation = (config.rotation * Math.PI / 180);
        this.alpha = 0;
        this.width = 1000;
        this.id = config.id;
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
    }
};
