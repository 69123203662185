export default class Vector2 {
    
    constructor(x, y) {
        this.x = x || 0;
        this.y = y || 0;
    }

    reset(x, y) {
        this.x = x;
        this.y = y;

        return this;
    }

    copyFrom (v) {
        this.x = v.x;
        this.y = v.y;
    }
};