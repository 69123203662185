import Phaser from 'phaser';
import { CST } from '../CST';
import DataStore from '../../../Components/Store/DataStore';

export class InstructionScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.INSTRUCTION });
    }

    preload() {
        this.anims.create({
            key: "bg_animation",
            frameRate: 3,
            repeat: -1,
            frames: this.anims.generateFrameNumbers('instructions', {
                frames: [0, 1]
            })
        });
        this.anims.create({
            key: "how_to_play_animation",
            frameRate: 3,
            repeat: -1,
            frames: this.anims.generateFrameNumbers('how_to_play_2', {
                frames: [0, 1]
            })
        });
    }
    create(passedData) {

        this.isFromBeginning = passedData.isFromBeginning;
        // console.log('instruction, from beginning:', passedData)
        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;


        this.mainImage = this.add.sprite(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'instructions').setScale(2);

        this.mainImage.anims.play("bg_animation");

        this.how_to_play = [
            this.add.image(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'how_to_play').setScale(2),
            this.add.image(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'how_to_play_2').setScale(2).setAlpha(0)
        ];


        this.control = this.scene.get(CST.SCENES.CONTROL);


    }

    update() {

        if (this.control.keys.green.isDown || this.control.keys.pink.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;

                if (this.how_to_play[1].alpha == 0) {
                    //2nd page
                    this.how_to_play[0].setAlpha(0);
                    this.how_to_play[1].setAlpha(1);

                }
                //
                else {
                    // this.understood();
                    // console.log('from beginning:', this.isFromBeginning)
                    if (this.isFromBeginning) {
                        this.understood();

                    } else {
                        //back to 1st page
                        this.how_to_play[0].setAlpha(1);
                        this.how_to_play[1].setAlpha(0);

                    }
                }
            }
        }


    }

    understood() {
        DataStore.startGamePixel();
        this.scene.start(CST.SCENES.GAME);
        this.scene.launch(CST.SCENES.HUD);
    }
}