import Phaser from 'phaser';
import { CST } from '../CST';
import DataStore from '../../../Components/Store/DataStore';

export class ScoreScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.SCORE });
    }

    init(data) {
        this.base = 0;
        if(data.completed) {
            // console.log('completion bonus');
            this.base = 50000;
        }
        this.coins = data.coins * 100;
        this.boxes = data.boxes * 500;
        this.time_bonus = Math.floor(this.base + (data.time * (this.base / 100 * .1)));
        this.position = data.position;

        this.time.delayedCall(10000, function() {
            this.scene.start(CST.SCENES.KRISPYKART_TITLE, 'Init');
            DataStore.setVisibility(true);
        }, [], this);
        
        switch (this.position) {
            case 1:
                this.time_bonus = Math.floor(this.time_bonus * 1.5);
                break;
            case 2:
                this.time_bonus = Math.floor(this.time_bonus * 1.25);
                break;
            case 3:
                this.time_bonus = Math.floor(this.time_bonus * 1.05);
                break;
            default:
                break;
        }

        this.total_score = this.coins + this.boxes + this.time_bonus;
        DataStore.setScore(this.total_score);

        //Pixel

        DataStore.finishGamePixel();
        // console.log(this.coins, this.boxes, this.time_bonus, this.position);
    }

    create() {

        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;

        this.SCORE_ANIMATE_TIME = 1600;
        this.textHFrom = [238, 344, 408, 472, 140];
        this.textHTo = [218, 324, 388, 452];
        
        this.anims.create({
            key: "score_animation",
            frameRate: 3,
            repeat: -1,
            frames: this.anims.generateFrameNumbers('screen_bg', {
                frames: [0, 1]
            })
        });

        this.mainImage = this.add.sprite(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'screen_bg').setScale(2);
        this.mainImage.anims.play("score_animation");
        this.isTransferingScore = false;
        this.transferingScoreIndex = 0;

        this.score = {

            totalTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[0], 'HalfBoldPixel-7', '00000000', 72).setOrigin(0.5, 0.5),
            timeTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[1], 'HalfBoldPixel-7', '+ Time Bonus', 28).setOrigin(0.5, 0.5),
            vitalTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[2], 'HalfBoldPixel-7', '+ Coin Bonus', 28).setOrigin(0.5, 0.5),
            otherTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[3], 'HalfBoldPixel-7', '+ Box Bonus', 28).setOrigin(0.5, 0.5),
            titleTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[4], 'HalfBoldPixel-7', 'YOUR SCORE', 42).setOrigin(0.5, 0.5),

            time: this.time_bonus,
            coins: this.coins,
            other: this.boxes,

            toBeAdded: [
                this.time_bonus, 
                this.time_bonus + this.coins, 
                this.time_bonus  + this.coins + this.boxes
            ],
            step: [
                Math.round(this.time_bonus / (60 * this.SCORE_ANIMATE_TIME*0.001)),
                Math.round(this.coins / (60 * this.SCORE_ANIMATE_TIME*0.001)),
                Math.round(this.boxes / (60 * this.SCORE_ANIMATE_TIME*0.001))
            ],
            
            total: 0
        };

        this.score.totalTextObject.alpha =
        this.score.timeTextObject.alpha =
        this.score.vitalTextObject.alpha =
        this.score.otherTextObject.alpha = 0;

        this.score.totalTextObject.setTint(0xfae98c);
        this.score.timeTextObject.setTint(0xf2f2f2);
        this.score.vitalTextObject.setTint(0xf2f2f2);
        this.score.otherTextObject.setTint(0xf2f2f2);
        this.score.titleTextObject.setTint(0xf2f2f2);

        this.scoreTweenData = [
            {
                targets: this.score.totalTextObject,
                y: this.textHTo[0],
                alpha: 1,
                duration: 200,
                ease: 'Power2',
                onStart: function() {
                    // this.updateScore();
                    // this.displayScoreText();
                },
                onStartScope: this,

                onComplete: function() {
                    this.tweens.add(this.scoreTweenData[1]);

                    this.time.delayedCall(1200, function() {

                        this.isTransferingScore = true;

                    }, [], this);

                },
                onCompleteScope: this
            },
            {
                targets: this.score.timeTextObject,
                y: this.textHTo[1],
                alpha: 1,
                duration: 200,
                delay: 1000,

                ease: 'Power2',
                onComplete: function() {
                    this.tweens.add(this.scoreTweenData[2]);

                    this.time.delayedCall(2200, function() {
                        this.transferingScoreIndex++;
                        this.isTransferingScore = true;

                    }, [], this);

                },
                onCompleteScope: this
            },
            {
                targets: this.score.vitalTextObject,
                y: this.textHTo[2],
                alpha: 1,
                duration: 200,
                delay: 2000,

                ease: 'Power2',
                onComplete: function() {
                    this.tweens.add(this.scoreTweenData[3]);
                    this.time.delayedCall(2200, function() {
                        this.transferingScoreIndex++;
                        this.isTransferingScore = true;

                    }, [], this);

                },
                onCompleteScope: this
            },
            {
                targets: this.score.otherTextObject,
                y: this.textHTo[3],
                alpha: 1,
                duration: 200,
                delay: 2000,

                ease: 'Power2',
                onComplete: function() {

                },
                onCompleteScope: this
            }
        ];

        this.tweens.add(this.scoreTweenData[0]);
        this.pressAnyKey();

    }

    update() {

        if (this.isTransferingScore) {
            this.animateScore(this.score.toBeAdded[this.transferingScoreIndex], this.score.step[this.transferingScoreIndex]);
        }

    }


    animateScore(goal, step) {

        if (this.score.total < goal) {

            this.score.total += step;
            var scoreStr = ('00000000' + this.score.total).slice(-8);
            this.score.totalTextObject.setText(scoreStr);
        
        } else {
            this.score.total = goal;
            var scoreStr = ('00000000' + this.score.total).slice(-8);
            this.score.totalTextObject.setText(scoreStr);
            this.isTransferingScore = false;
        }
    }

    transferScoreToTotal(r) {
        if (r >= 100)
            r -= 100;
        this.score.total += 100;
        return r;
    }

    understood() {

    }

    pressAnyKey() {
        this.input.keyboard.on('keydown', function(event) {
            this.understood();
        }, this);
    }
}