import Phaser from 'phaser';

export default class MysteryBox extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key, config.rotation);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.displayWidth = 80;
        this.displayHeight = 80;
        this.rotation = Phaser.Math.DegToRad(config.rotation);
        this.x = config.x;
        this.y = config.y;
    }
};
