import React, { Component } from 'react'

export class Decorations extends Component {
  render() {
    return (
      <div className="decorations">
        <div className="decor top_left"></div>
        <div className="decor top_right"></div>
        <div className="decor bottom_left"></div>
        <div className="decor bottom_right"></div>
      </div>
    )
  }
}

export default Decorations
