import Phaser from 'phaser';
import { CST } from '../CST';

export class IntroScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.INTRO });
    }

    create() {

        this.control = this.scene.get(CST.SCENES.CONTROL);

        this.control.bgm.stop();
        this.control.sad.play();

        //Animation
        this.anims.create({
            key: 'intro_boi_talk',
            frames: this.anims.generateFrameNumbers('intro_boi', {
                frames: [0, 1]
            }),
            frameRate: 10,
            repeat: -1
        });

        this.anims.create({
            key: 'intro_boi_idle',
            frames: this.anims.generateFrameNumbers('intro_boi', {
                frames: [2]
            })
        });

        this.anims.create({
            key: 'intro_boi_jump',
            frames: this.anims.generateFrameNumbers('intro_boi', {
                frames: [3]
            })
        });

        this.anims.create({
            key: 'intro_people_cry',
            frames: this.anims.generateFrameNumbers('intro_people', {
                frames: [0, 1]
            }),
            frameRate: 4,
            repeat: -1
        });

        this.anims.create({
            key: 'intro_people_gasp',
            frames: this.anims.generateFrameNumbers('intro_people', {
                frames: [2]
            })
        });
        this.anims.create({
            key: 'intro_people_farewell',
            frames: this.anims.generateFrameNumbers('intro_people', {
                frames: [3]
            })
        });
        //sound
        this.sfx = {

            hop: this.sound.add('hop', { volume: 0.6 })

        };

        //
        this.introTween = null;
        this.introTimer = null;

        this.bgmTween = null;

        this.intro_bg = this.add.image(330, 330, 'intro_bg').setScale(4);

        this.intro_speech_people = this.add.image(330 + 80, 384, 'intro_speech').setScale(4).setOrigin(0.7, 1).setAlpha(0);
        this.intro_speech_boi = this.add.image(330 - 80, 384, 'intro_speech').setScale(4).setOrigin(0.3, 1).setFlipX(true).setAlpha(0);

        this.intro_people = this.add.sprite(480, 540 - 6 - 44, 'intro_extras').setScale(4).setOrigin(0.5, 1);
        this.intro_boi = this.add.sprite(160, 540 - 10 - 44, 'intro_boi').setScale(4).setOrigin(0.5, 1);

        this.intro_speechTO = this.add.bitmapText(178, 172, 'HalfBoldPixel-7', '', 34, 1).setOrigin(0, 0).setTint(0x6c3e3f);

        this.intro_letterbox = this.add.image(330, 330, 'intro_letterbox').setScale(4);
        this.intro_press = this.add.image(330, 640, 'intro_press').setOrigin(0.5, 1);

        this.speeches = [
            'The evil snacks\nhave turned off\nour hot light.\nWithout it, we\nare doomed!',
            // 'Our hot light\nhas been \nturned off!\nWe are doomed!',
            'Don\'t worry my\nfriends. I will\nfind the hot\nlight and turn\nit back on!',
            ''
        ]
        this.step = 0;
        this.setDialogue();



    }

    update() {
        if (this.control.keys.fire.isDown || this.control.keys.jump.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;

                if (this.step <= 6) {
                    this.step += 1;
                    if (this.introTween != null) {
                        this.introTween.seek(1);

                    }
                    this.introTimer.remove(false);

                    this.setDialogue();

                }
                // this.understood();

            }
        }
    }

    setDialogue() {
        // console.log('dialogue', this.step);
        switch (this.step) {
            case 0:
                this.intro_people.anims.play('intro_people_cry');
                this.intro_boi.anims.play('intro_boi_idle');

                this.introTimer = this.time.delayedCall(1500, function() {
                    this.step = 1;
                    this.setDialogue();
                }, [], this);


                break;

            case 1:
                this.intro_speech_people.setAlpha(1).setScale(0.1);
                this.introTween = this.tweens.add({
                    targets: this.intro_speech_people,
                    scaleX: 4,
                    scaleY: 4,
                    duration: 100,
                    onComplete: () => {
                        this.intro_speechTO.setText(this.speeches[0]);
                    }
                });

                this.intro_boi.anims.play('intro_boi_idle');

                this.introTimer = this.time.delayedCall(4000, function() {
                    this.step = 2;
                    this.setDialogue();
                }, [], this);

                break;

            case 2:
                this.intro_speechTO.setText('');

                this.tweens.add({
                    targets: this.intro_speech_people,
                    scaleX: 0.1,
                    scaleY: 0.1,
                    duration: 100,
                    onComplete: () => {
                        this.intro_speech_people.setAlpha(0);
                    }
                });

                this.intro_speech_boi.setAlpha(1).setScale(0.1);
                this.tweens.add({
                    targets: this.intro_speech_boi,
                    scaleX: 4,
                    scaleY: 4,
                    duration: 100,
                    delay: 150,
                    onComplete: () => {
                        this.intro_speechTO.setText(this.speeches[1]);
                    }
                });
                this.intro_boi.anims.play('intro_boi_talk');

                //
                //

                this.introTimer = this.time.delayedCall(2000, function() {
                    this.step = 3;
                    this.setDialogue();
                }, [], this);

                break;

            case 3:
                // this.intro_speechTO.setText(this.speeches[2]);
                this.intro_people.anims.play('intro_people_gasp');
                this.introTimer = this.time.delayedCall(2000, function() {
                    this.step = 4;
                    this.setDialogue();
                }, [], this);

                break;

            case 4:
                this.intro_speechTO.setText('');

                this.tweens.add({
                    targets: this.intro_speech_boi,
                    scaleX: 0.1,
                    scaleY: 0.1,
                    duration: 100,
                    onComplete: () => {
                        this.intro_speech_boi.setAlpha(0);
                    }
                });

                this.intro_boi.anims.play('intro_boi_idle');

                this.introTimer = this.time.delayedCall(1000, function() {
                    this.step = 5;
                    this.setDialogue();
                }, [], this);

                break;


            case 5:
                this.sfx.hop.play();

                this.intro_boi.anims.play('intro_boi_jump');
                this.tweens.add({
                    targets: this.intro_boi,
                    x: 720,
                    y: 140,
                    duration: 1000,

                    onComplete: () => {}
                });
                this.introTimer = this.time.delayedCall(500, function() {
                    this.step = 6;
                    this.setDialogue();
                }, [], this);

                break;

            case 6:
                this.intro_press.setAlpha(0);
                this.intro_people.anims.play('intro_people_farewell');
                this.tweens.add({
                    targets: this.intro_letterbox,
                    scaleY: 6,
                    delay: 700,
                    duration: 1000,

                    onComplete: () => {}
                });

                this.introTimer = this.time.delayedCall(2500, function() {
                    this.step = 7;
                    this.setDialogue();
                }, [], this);


                break;

            case 7:
                this.understood();
                break;


        }


    }


    understood() {
        let startLvl = 1;

        let pd = {
            level: startLvl,
            startState: 'small',
            life: 3,
            coin: 0,
            enemyKilled: 0,
            hadHeart: [false, false, false],
            score: {
                base: 0,
                timeBonus: 0,
                completionBonus: 0,
                lifeBonus: 0,
                enemyBonus: 0
            }
        };
        this.control.sad.stop();
        //
        this.scene.start(CST.SCENES.GAME, pd);
        this.scene.launch(CST.SCENES.HUD, pd);
    }

    pressAnyKey() {
        this.input.keyboard.on('keydown', function(event) {
            this.understood();
        }, this);

    }
}