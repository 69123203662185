export default class Button {
    constructor(ctx, x, y, width, height, image) {
        this._ctx = ctx;
        this._x = x;
        this._y = y;
        this._height = height;
        this._width = width;
        this._image = new Image(); 
        this._image.src = image;
        this._image_container = [this._image];
        for (var i = 0; i < this._image_container.length; ++i) {
            this._image_container[i].onload = function () {
                if (i === this._image_container.length) {
                    this.draw();
                }
            }.bind(this);
        }
    }

    draw() {
        this._ctx.save();
        this._ctx.drawImage(this._image, this._x, this._y, this._width, this._height);
        this._ctx.imageSmoothingEnabled = true;
        this._ctx.imageSmoothingQuality = "high";
        this._ctx.restore();
    }
};
