import Phaser from 'phaser';
import { CST } from '../CST';
import DataStore from '../../../Components/Store/DataStore';

export class InstructionScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.INSTRUCTION });
    }

    create(passedData) {

        this.isFromBeginning = passedData.isFromBeginning;
        // console.log('instruction, from beginning:', passedData)
        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;

        this.mainImage = this.add.sprite(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'screen_bg').setScale(2);

        this.mainImage.anims.play("bg_animation");

        this.instruction = {

            titleTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, 140, 'HalfBoldPixel-7', 'HOW TO PLAY', 42).setOrigin(0.5, 0.5),
            control: [
                this.add.image(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'screen_instruction1'),
                this.add.image(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'screen_instruction2').setAlpha(0)
            ]

        };

        this.control = this.scene.get(CST.SCENES.CONTROL);

        // this.pressAnyKey();    
        // this.understood();  

    }

    update() {

        if (this.control.keys.fire.isDown || this.control.keys.jump.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;

                if (this.instruction.control[1].alpha == 0) {
                    //2nd page
                    this.instruction.control[0].setAlpha(0);
                    this.instruction.control[1].setAlpha(1);
                    this.instruction.titleTextObject.setText('THE POWER-UPS');

                }
                //
                else {
                    // this.understood();
                    //console.log('from beginning:', this.isFromBeginning)
                    if (this.isFromBeginning) {
                        this.understood();

                    } else {
                        //back to 1st page
                        this.instruction.control[0].setAlpha(1);
                        this.instruction.control[1].setAlpha(0);
                        this.instruction.titleTextObject.setText('HOW TO PLAY');

                    }
                }
            }
        }


    }

    understood() {
        this.scene.start(CST.SCENES.INTRO);
        DataStore.startGamePixel();
    }
    pressAnyKey() {
        this.input.keyboard.on('keydown', function(event) {
            this.understood();
        }, this);

    }
}