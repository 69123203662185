import Phaser from 'phaser';

import Enemy from './Enemy';

export default class Cookie extends Enemy {
    constructor(config) {
        super(config);
        this.body.setVelocity(0, 0).setBounce(0, 0).setCollideWorldBounds(false);
        this.anims.play('cookie');
        this.killAt = 0;
        this.isChasing = false;
        // Ghosty be phasin' thru
        // this.scene.physics.add.collider(this, this.scene.groundLayer);

        this.body.setSize(21, 20, false);

        // Ghosty be floatin' 
        this.body.gravity.y = -1400;
    }

    update(time, delta) {
        // If it's not activated, then just skip the update method (see Enemy.js)
        if (!this.activated()) {
            return;
        }
        if(this.scene.killAllEnemies){
            // console.log('kill');
            this.kill();
        }
        //
        
        this.flipX = (this.body.x > this.scene.donut.body.x) ? true : false;

        if (this.scene.donut.flipX == this.flipX) {
            // Ghosty be chasin' yo
            this.isChasing = true;
            this.anims.play('cookie');

        } else {
            this.isChasing = false;
            this.anims.play('cookieStop');
        }

        if (this.isChasing) {
            // var vx = this.body.x < this.scene.donut.body.x ? 1 : -1;
            var vx;
            var vertAllowance = 100;
            if(this.body.x > this.scene.donut.body.x + vertAllowance){
                vx = -1;
            }
            //
            else if(this.body.x + vertAllowance < this.scene.donut.body.x){
                vx = 1;
            }
            //
            else {
                vx = 0;
            }

            var vy = this.body.y < this.scene.donut.body.y ? 1 : -1;

            this.body.setVelocity(vx * 100, vy * 60);
        } else {
            this.body.setVelocity(0, 0);
        }

        if(this.body.x > 75 * 64){
            this.body.setVelocity(0, 0);
        }
        
        if (this.killAt !== 0) {
            // The killtimer is set, keep the flat Cookie then kill it for good.
            this.body.setVelocityX(0);
            this.killAt -= delta;
            if (this.killAt < 0) {
                this.kill();
            }
            return;
        }

        // Overlap with Donut!
        this.scene.physics.world.overlap(this, this.scene.donut, this.donutHit);
  
    }

    donutHit(enemy, donut) {

        // Donut collides with the enemy
        enemy.hurtDonut(enemy, donut);

    }


}