import Phaser from 'phaser';
import { CST } from '../CST';
import DataStore from '../../../Components/Store/DataStore';

export class ScoreScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.SCORE });
    }

    init(passedData) {


        this.total_score = passedData.score.total;

        /* Set Score and Bring up Content */
        DataStore.setScore(this.total_score);

        DataStore.finishGamePixel();
        
        this.endTimer = this.time.delayedCall(15000, function() {
            DataStore.setVisibility(true);
            this.understood();

        }, [], this);
        
    }


    create(passedData) {

        this.control = this.scene.get(CST.SCENES.CONTROL);


        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;

        this.SCORE_ANIMATE_TIME = 1600;
        this.textHFrom = [238, 344, 408, 472, 536, 140];
        this.textHTo = [218, 324, 388, 452, 516];


        this.mainImage = this.add.sprite(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'screen_bg').setScale(2);
        this.mainImage.anims.play("bg_animation");


        this.win = (passedData.win >= 2) ? true : false;

        // this.win ? console.log("You WON") : console.log("You LOST");

        //
        this.isTransferingScore = false;
        this.transferingScoreIndex = 0;


        this.score = {
            totalTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[0], 'HalfBoldPixel-7', '00000000', 72).setOrigin(0.5, 0.5),
            timeTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[1], 'HalfBoldPixel-7', '+ Time Bonus', 28).setOrigin(0.5, 0.5),
            vitalTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[2], 'HalfBoldPixel-7', '+ Vital Bonus', 28).setOrigin(0.5, 0.5),
            comboTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[3], 'HalfBoldPixel-7', '+ Combo Bonus', 28).setOrigin(0.5, 0.5),
            otherTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[4], 'HalfBoldPixel-7', '+ Special Moves', 28).setOrigin(0.5, 0.5),
            titleTextObject: this.add.bitmapText(this.SCREEN_CENTRE_W, this.textHFrom[5], 'HalfBoldPixel-7', 'YOUR SCORE', 42).setOrigin(0.5, 0.5),

            time: passedData.score.timeBonus,
            vital: passedData.score.vitalBonus,
            combo: passedData.score.comboBonus,
            other: passedData.score.otherBonus,

            toBeAdded: [
                passedData.score.timeBonus,
                passedData.score.timeBonus + passedData.score.vitalBonus,
                passedData.score.timeBonus + passedData.score.vitalBonus + passedData.score.comboBonus,
                passedData.score.timeBonus + passedData.score.vitalBonus + passedData.score.comboBonus + passedData.score.otherBonus
            ],
            step: [
                Math.round(passedData.score.timeBonus / (60 * this.SCORE_ANIMATE_TIME * 0.001)),
                Math.round(passedData.score.vitalBonus / (60 * this.SCORE_ANIMATE_TIME * 0.001)),
                Math.round(passedData.score.comboBonus / (60 * this.SCORE_ANIMATE_TIME * 0.001)),
                Math.round(passedData.score.otherBonus / (60 * this.SCORE_ANIMATE_TIME * 0.001))

            ],

            total: 0
        };

        // console.log(this.score.toBeAdded);
        this.score.totalTextObject.alpha =
            this.score.timeTextObject.alpha =
            this.score.vitalTextObject.alpha =
            this.score.comboTextObject.alpha =
            this.score.otherTextObject.alpha = 0;

        this.score.totalTextObject.setTint(0xfae98c);
        this.score.timeTextObject.setTint(0xf2f2f2);
        this.score.vitalTextObject.setTint(0xf2f2f2);
        this.score.comboTextObject.setTint(0xf2f2f2);
        this.score.otherTextObject.setTint(0xf2f2f2);
        this.score.titleTextObject.setTint(0xf2f2f2);


        this.scoreTweenData = [

            {
                targets: this.score.totalTextObject,
                y: this.textHTo[0],
                alpha: 1,
                duration: 200,
                ease: 'Power2',
                onStart: function() {
                    // this.updateScore();
                    // this.displayScoreText();
                },
                onStartScope: this,

                onComplete: function() {
                    this.scoreTween = this.tweens.add(this.scoreTweenData[1]);

                    this.scoreTimer = this.time.delayedCall(1200, function() {

                        this.isTransferingScore = true;

                    }, [], this);

                },
                onCompleteScope: this
            },
            {
                targets: this.score.timeTextObject,
                y: this.textHTo[1],
                alpha: 1,
                duration: 200,
                delay: 1000,

                ease: 'Power2',
                onComplete: function() {
                    this.scoreTween = this.tweens.add(this.scoreTweenData[2]);

                    this.scoreTimer = this.time.delayedCall(2200, function() {
                        this.transferingScoreIndex++;
                        this.isTransferingScore = true;

                    }, [], this);



                },
                onCompleteScope: this
            },
            {
                targets: this.score.vitalTextObject,
                y: this.textHTo[2],
                alpha: 1,
                duration: 200,
                delay: 2000,

                ease: 'Power2',
                onComplete: function() {
                    this.scoreTween = this.tweens.add(this.scoreTweenData[3]);
                    this.scoreTimer = this.time.delayedCall(2200, function() {
                        this.transferingScoreIndex++;
                        this.isTransferingScore = true;

                    }, [], this);

                },
                onCompleteScope: this
            },
            {
                targets: this.score.comboTextObject,
                y: this.textHTo[3],
                alpha: 1,
                duration: 200,
                delay: 2000,

                ease: 'Power2',
                onComplete: function() {
                    this.scoreTween = this.tweens.add(this.scoreTweenData[4]);
                    this.scoreTimer = this.time.delayedCall(2200, function() {
                        this.transferingScoreIndex++;
                        this.isTransferingScore = true;

                    }, [], this);

                },
                onCompleteScope: this
            },
            {
                targets: this.score.otherTextObject,
                y: this.textHTo[4],
                alpha: 1,
                duration: 200,
                delay: 2000,

                ease: 'Power2',
                onComplete: function() {


                },
                onCompleteScope: this
            }


        ];



        //
        this.scoreTween = this.tweens.add(this.scoreTweenData[0]);
        this.scoreTimer = null;
    }

    update() {

        if (this.isTransferingScore) {

            this.animateScore(this.score.toBeAdded[this.transferingScoreIndex], this.score.step[this.transferingScoreIndex]);

        }

        if (this.control.keys.punch.isDown || this.control.keys.kick.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;

                if (this.scoreTween != null) {
                    this.scoreTween.pause();
                }
                if (this.scoreTimer != null) {
                    this.scoreTimer.remove(false);
                }

                this.isTransferingScore = false;

                // this.endTimer.remove(true);

                this.score.totalTextObject.setAlpha(1).y = this.textHTo[0];
                this.score.timeTextObject.setAlpha(1).y = this.textHTo[1];
                this.score.vitalTextObject.setAlpha(1).y = this.textHTo[2];
                this.score.comboTextObject.setAlpha(1).y = this.textHTo[3];
                this.score.otherTextObject.setAlpha(1).y = this.textHTo[4];

                this.score.totalTextObject.setText(('00000000' + this.total_score).slice(-8));

                this.endTimer.remove(false);

                this.endTimer = this.time.delayedCall(1500, function() {
                    DataStore.setVisibility(true);
                    this.understood();

                }, [], this);

                // this.understood();

            }
        }

    }


    animateScore(goal, step) {

        if (this.score.total < goal) {

            this.score.total += step;
            var scoreStr = ('00000000' + this.score.total).slice(-8);
            this.score.totalTextObject.setText(scoreStr);

        } else {
            this.score.total = goal;
            var scoreStr = ('00000000' + this.score.total).slice(-8);
            this.score.totalTextObject.setText(scoreStr);
            this.isTransferingScore = false;
        }
    }


    transferScoreToTotal(r) {
        if (r >= 100)
            r -= 100;
        this.score.total += 100;
        return r;
    }

    understood() {
        this.scene.start(CST.SCENES.GAME_TITLE);


    }
    // pressAnyKey() {
    //     this.input.keyboard.on('keydown', function(event) {
    //         this.understood();
    //     }, this);

    // }
}