export const CST = {
    SCENES: {
        INIT:               "INIT",
        LOAD:               "LOAD",
        CONTROL:            "CONTROL",
        THROWBACK_TITLE:    "THROWBACK_TITLE",
        BROWNIE_TITLE:      "BROWNIE_TITLE",
        INSTRUCTION:        "INSTRUCTION",
        GAME:               "GAME",
        HUD:                "HUD",
        SCORE:              "SCORE",
        SOUND:              "SOUND"
    }
}