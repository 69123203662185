import Phaser from 'phaser';

export default class Box extends Phaser.GameObjects.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.scene = config.scene;


        this.body.setImmovable();


        this.scene.anims.create({
            key: 'small_box',
            frames: this.scene.anims.generateFrameNumbers('box', {
                start: 1,
                end: 3

            })
        });
        this.scene.anims.create({
            key: 'large_box',
            frames: this.scene.anims.generateFrameNumbers('box', {
                start: 0,
                end: 0

            })
        });

        this.scene.anims.create({
            key: 'large_box_broken',
            frames: this.scene.anims.generateFrameNumbers('box', {
                start: 4,
                end: 4

            })
        });

        // console.log(this.scene.anims)

        this.setOrigin(0.5);
        this.setScale(4);
        
        this.isOnFourPack = false;
        this.twelvePack();

        //TEST
        // this.isOnFourPack = true;
        // this.fourPack();

    }

    fourPack() {
        this.isOnFourPack = true;

        this.play('small_box');


        var ws = 21,
            hs = 13;
        this.body.setSize(ws, hs, false);
        this.body.setOffset((33 - ws) / 2, (13 - hs) / 2);



    }

    twelvePack() {
        this.isOnFourPack = false;

        this.play('large_box');
    }

    dead() {
        this.play('large_box_broken');
    }
};
