import Phaser from 'phaser';

export default class Kart extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key, config.rotation, config.id, config.offset_angle, config.enemy, config.max_velocity, config.waypoint);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.angle = config.offset_angle;
        this.id = config.id;
        this.displayWidth = 184;
        this.displayHeight = 184;
        this.setSize(80, 80, true);
        this.setOffset(40, 40);
        this.setDamping(false);
        this.setDrag(1);
        // this.body.setImmovable(false);
        this.setMaxVelocity(config.max_velocity, config.max_velocity);
        this.setBounce(1, 1);
        this.setCollideWorldBounds(true);
        this.enemy = config.enemy;
        this.enabled = false;
        this.speed = 0;
        this.gravel_factor = 1;
        this.isGrass = false;
        this.isLake = false;
        this.oil = false;
        this.invinceable = false;
        this.current_way_point = 0;
        this.way_point_collection = config.waypoint;
        this.MAX_SPEED = config.maxspeed;
        this.checkpoint = 0;
        this.currentLap = 0;
        this.hitStartPoint = false;
        this.hitMidPoint = false;
        this.coins = 0;
        this.boxes = 0;
        this.shellHit = false;

        /* Player */

        config.scene.anims.create({
            key: 'star_power',
            frames: [
                { key: 'kart_pink' },
                { key: 'kart_purple' },
                { key: 'kart_yellow' },
                { key: 'kart_blue', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });

        config.scene.anims.create({
            key: 'mushroom_power',
            frames: [
                { key: 'kart_mushroom_2' },
                { key: 'kart_mushroom', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });   

        config.scene.anims.create({
            key: 'crusing',
            frames: [
                { key: 'kart_smoke_1' },
                { key: 'kart_smoke_2', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });   

        config.scene.anims.create({
            key: 'enemy_crusing',
            frames: [
                { key: 'kart_brown_smoke_1' },
                { key: 'kart_brown_smoke_2', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        }); 

        config.scene.anims.create({
            key: 'enemy_star_power',
            frames: [
                { key: 'kart_brown' },
                { key: 'kart_glazed', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });

        config.scene.anims.create({
            key: 'enemy_mushroom_power',
            frames: [
                { key: 'kart_brown_mushroom_2' },
                { key: 'kart_brown_mushroom', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });   

        config.scene.anims.create({
            key: 'kart_time_out',
            frames: [
                { key: 'kart_pink_no_wheels1'},
                { key: 'kart_pink_no_wheels2'},
                { key: 'kart_pink_no_wheels3'},
                { key: 'kart_pink_no_wheels4'},
                { key: 'kart_pink_no_wheels5'},
                { key: 'kart_pink_no_wheels6'},
                { key: 'kart_pink_no_wheels7'},
                { key: 'kart_pink_no_wheels8'},
                { key: 'kart_pink_no_wheels9'},
                { key: 'kart_pink_no_wheels10'},
                { key: 'kart_pink_no_wheels11'},
                { key: 'kart_pink_no_wheels12'},
                { key: 'kart_pink_no_wheels13'},
                { key: 'kart_pink_no_wheels14'},
                { key: 'kart_pink_no_wheels15'},
                { key: 'kart_pink_no_wheels16'},
                { key: 'kart_pink_no_wheels17'}
            ],
            frameRate: 15
        });   

        this.cruising();
    }

    hit() {
        this.play('enemy_star_power');
    }

    starPower() {
        this.invinceable = true;
        if(this.enemy) {
            this.play('enemy_star_power');
        } else {
            this.play('star_power');
        }
    }

    mushroomPower() {
        this.invinceable = false;
        if(this.enemy) {
            this.play('enemy_mushroom_power');
        } else {
            this.play('mushroom_power');
        }
    }

    cruising() {
        this.invinceable = false;
        if(this.enemy) {
            this.play('enemy_crusing');
        } else {
            this.play('crusing');
        }
    }

    time_out() {
        this.play('kart_time_out');
    }
};
