import Phaser from 'phaser';
import { CST } from '../CST';
import DataStore from '../../../Components/Store/DataStore';

export class IntructionScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.INSTRUCTIONS });
    }

    create() {
        this.user_understand_part_1 = false;
        this.user_understand_part_2 = false;
        this.timer_event = null;
        this.timer_event_2 = null;
        this.active = false;
        this.SCREEN_TIMER = 1000;
        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;
        this.wait = false;

        this.anims.create({
            key: "bg_animation",
            frameRate: 3,
            repeat: -1,
            frames: this.anims.generateFrameNumbers('instructions', {
                frames: [0, 1]
            })
        });

        this.anims.create({
            key: "how_to_play_animation",
            frameRate: 3,
            repeat: -1,
            frames: this.anims.generateFrameNumbers('how_to_play_2', {
                frames: [0, 1]
            })
        });

        
        this.mainImage = this.add.sprite(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'instructions').setScale(2);
        this.mainImage.anims.play("bg_animation");

        this.how_to_play = this.make.image({ x: this.sys.game.config.width / 2, y: this.sys.game.config.height/2, key: 'how_to_play' }).setScale(2);

        this.how_to_play_2 = this.add.sprite(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'how_to_play_2').setScale(2);
        this.how_to_play_2.anims.play("how_to_play_animation");
        this.how_to_play_2.visible = false;

        this.timer_event = this.time.addEvent({ delay: 1000, callback: () => {
            this.wait = true;
        }, callbackScope: this, loop: false });

        document.addEventListener("button_b", function (event) {
            if (!this.user_understand_part_1 && this.active && this.wait) {
                this.user_understand_part_1 = true;
                this.how_to_play.visible = false;
                this.how_to_play_2.visible = true;
                this.wait = false;
                this.timer_event_2 = this.time.addEvent({ delay: 1000, callback: () => {
                    this.wait = true;
                }, callbackScope: this, loop: false });
            }

            if(!this.user_understand_part_2 && this.active && this.wait) {
                this.user_understand_part_2 = true;
                this.understood();
            }
        }.bind(this)); 
    }

    update() {
        this.active = this.scene.isActive(CST.SCENES.INSTRUCTIONS); 
    }

    understood() {
        DataStore.startGamePixel();
        this.timer_event.remove(true);
        this.timer_event_2.remove(true)
        this.scene.start(CST.SCENES.GAME, 'Game');
    }
}