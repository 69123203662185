import Phaser from 'phaser';

import Enemy from './Enemy';

export default class Chocolate extends Enemy {
    constructor(config) {
        super(config);
        this.body.setVelocity(0, 0).setBounce(0, 0).setCollideWorldBounds(false);
        this.anims.play('chocolate');
        this.killAt = 0;
        this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer);

        this.body.setSize(16, 24, false);

        // Base horizontal velocity / direction.
        this.direction = -50;
    }

    update(time, delta) {
        // If it's not activated, then just skip the update method (see Enemy.js)
        if (!this.activated()) {
            return;
        }
        if(this.scene.killAllEnemies){
            // console.log('kill');
            this.kill();
        }
        //
        
        if (this.killAt !== 0) {
            // The killtimer is set, keep the flat Chocolate then kill it for good.
            this.body.setVelocityX(0);
            this.killAt -= delta;
            if (this.killAt < 0) {
                this.kill();
            }
            return;
        }

        // Collide with Donut!
        this.scene.physics.world.overlap(this, this.scene.donut, this.donutHit);

        // The Chocolate stopped, better try to walk in the other direction.
        if (this.body.velocity.x === 0) {
            this.direction = -this.direction;
            this.body.velocity.x = this.direction;
        }
    }

    donutHit(enemy, donut) {
        if (enemy.verticalHit(enemy, donut)) {
            // Donut jumps on the enemy
            donut.enemyBounce(enemy);
            // enemy.scene.sound.playAudioSprite('sfx', 'smb_stomp');
            enemy.scene.sfx.stomp.play();

            enemy.getFlat(enemy, donut);
        } else {
            // Donut collides with the enemy
            enemy.hurtDonut(enemy, donut);
        }
    }

    getFlat(enemy, donut) {
        this.scene.hudscene.updateEnemyKillHUD(this.type);
        this.scene.impactParticleEmitter.explode(8, enemy.body.x, enemy.body.y + 64);

        enemy.play('chocolateFlat');
        enemy.body.setVelocityX(0);
        enemy.body.acceleration.x = 0;
        // Keep chocolate flat for 2000ms, then remove it.
        enemy.killAt = 2000;
    }
}