import Phaser from 'phaser';

/*
Generic enemy class that extends Phaser sprites.
Classes for enemy types extend this class.
*/

export default class Enemy extends Phaser.GameObjects.Sprite {

    constructor(config) {
        super(config.scene, config.x, config.y - 64, config.key);
        this.type = config.key;
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.alive = true;
        this.MAX_VELOCITY_Y = 1600;

        // start still and wait until needed
        this.body.setVelocity(0, 0).setBounce(0, 0).setCollideWorldBounds(false);
        this.body.allowGravity = false;
        this.beenSeen = false;

        this.body.maxVelocity.y = this.MAX_VELOCITY_Y;

        // know about Donut
        this.donut = this.scene.donut;


        this.setScale(4);

    }

    activated() {
        // Method to check if an enemy is activated, the enemy will stay put
        // until activated so that starting positions is correct

        if (!this.alive) {
            if (this.y > 1024) {
                this.kill();
                //kills object toasted by fire and dropped
            }
            return false;
        }
        if (!this.beenSeen) {
            // check if it's being seen now and if so, activate it
            if (this.x < this.scene.cameras.main.scrollX + this.scene.sys.game.canvas.width + 32 + 128) {
                this.beenSeen = true;

                this.startMoving();

                return true;
            }
            return false;
        }
        return true;
    }
    startMoving() {
        this.body.velocity.x = this.direction;
        this.body.allowGravity = true;
    }
    verticalHit(enemy, donut) {
        // quick check if a collision between the enemy and Donut is from above.
        if (!donut.alive) {
            return false;
        }
        var r = false;
        if (donut.body.velocity.y >= 0) {

            //KNOW that  physics body is not set to centre. it is set to left top.
            if (
                (donut.body.y + donut.body.height) < (enemy.body.y) + 20
            ) {
                r = true;
            }

        }
        return r;
    }

    hurtDonut(enemy, donut) {
        // send the enemy to donut hurt method (if donut got a star this will not end well for the enemy)
        this.scene.donut.hurtBy(enemy);
    }

    getFlipped(enemy) {
        // Killed by a star or hit from below with a block, later on also fire
        if (!this.alive) {
            return;
        }

        if (this.type == 'icecream') {
            this.scoops--;
            if (this.scoops > 0) {
                this.resizeScoops(enemy);
                return;
            }
        }

        this.scene.hudscene.updateEnemyKillHUD(this.type);
        this.body.velocity.x = 120;
        this.body.velocity.y = -460;
        this.alive = false;
        this.flipY = true;
        // this.scene.sound.playAudioSprite('sfx', 'smb_stomp');
        this.scene.sfx.stomp.play();
        if (this.collider) this.collider.destroy();

    }

    kill() {

        if(this.type === 'crossboss'){
            this.scene.ladderReveal();
        }

        // Forget about this enemy
        this.scene.enemyGroup.remove(this);
        this.destroy();
    }
}