import Phaser from 'phaser';
import { CST } from '../CST';

export class VSScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.VS });
    }

    create() {

        this.control = this.scene.get(CST.SCENES.CONTROL);
        //Animation

        //


        this.vs_bg = this.add.image(330, 330, 'vs_bg');
        this.vs_port1 = this.add.image(330 - 500, 312, 'vs_port1');
        this.vs_port2 = this.add.image(330 + 500, 312, 'vs_port2');
        this.vs_vs = this.add.image(330, -600, 'vs_vs').setScale(4);

        this.setDialogue();



    }

    update() {
        if (this.control.keys.punch.isDown || this.control.keys.kick.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;
                this.understood();

            }
        }
    }

    setDialogue(step = 0) {
        // console.log('dialogue', step);
        switch (step) {
            case 0:

                this.time.delayedCall(500, function() {
                    this.setDialogue(1);
                }, [], this);

                break;

            case 1:
                this.tweens.add({
                    targets: this.vs_port1,
                    x: 330 - 134,
                    duration: 1000,
                    onComplete: () => {}
                });
                this.tweens.add({
                    targets: this.vs_port2,
                    x: 330 + 134,
                    duration: 1000,
                    onComplete: () => {}
                });
                this.tweens.add({
                    targets: this.vs_vs,
                    y: 312,
                    scaleX:1,
                    scaleY:1,
                    delay:700,
                    duration: 300,
                    onComplete: () => {}
                });


                this.time.delayedCall(1000, function() {
                    this.cameras.main.fadeIn(200, 255, 255, 255);

                    this.setDialogue(2);
                }, [], this);

                break;

            case 2:
                
                this.time.delayedCall(4000, function() {
                    this.understood();
                }, [], this);

                break;


        }


    }


    understood() {

        this.scene.start(CST.SCENES.GAME);
        this.scene.launch(CST.SCENES.HUD, {
            round: 0,
            win: 0,
            lose: 0,
            score: {
                timeBonus: 0,
                vitalBonus: 0,
                otherBonus: 0,
                comboBonus: 0,
                toBeAdded: 0
            }
        });

    }

    pressAnyKey() {
        this.input.keyboard.on('keydown', function(event) {
            this.understood();
        }, this);

    }
}