import Phaser from 'phaser';

export default class Ball extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.setSize(64, 64, true);

        this.onPaddle = true;

        this.body.maxVelocity.setTo(1300,1300);

        config.scene.anims.create({
            key: 'super',
            frames: [
                { key: 'super_nut', duration: 50 },
                { key: 'super_nut_1', duration: 50 },
                { key: 'super_nut_2', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });

        config.scene.anims.create({
            key: 'dead',
            frames: [
                { key: 'dead', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });

        config.scene.anims.create({
            key: 'super_dead',
            frames: [
                { key: 'super_dead', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });

        config.scene.anims.create({
            key: 'flying',
            frames: [
                { key: 'static_double_arm_up', duration: 2000 },
                { key: 'static_arm_up', duration: 2000 }
            ],
            frameRate: 10,
            repeat: -1
        });

        config.scene.anims.create({
            key: 'idle',
            frames: [
                { key: 'static_eyes', duration: 50 },
                { key: 'static', duration: 1500 },
                { key: 'static_eyes', duration: 50 },
                { key: 'static', duration: 1500 },
                { key: 'static_arm_up', duration: 1500 },
                { key: 'static_eyes', duration: 50 },
                { key: 'static', duration: 2500 },
                { key: 'static_eyes', duration: 50 },
                { key: 'static_double_arm_up', duration: 1000 }
            ],
            frameRate: 10,
            repeat: -1
        });

    }
    updateAngle() {
        if (this.body.velocity.x != 0 || this.body.velocity.y != 0) {
            let angle = Phaser.Math.RadToDeg(Phaser.Math.Angle.Between(0, 0, this.body.velocity.x, this.body.velocity.y));
            this.angle = angle + 90;
        } else {
            this.angle = 0;
        }
    }

    flying(){
        this.play('flying')
    }

    super() {
        this.play('super');
    }

    idle() {
        this.play('idle');
    }

    dead() {
        this.play('dead');
    }

    superDead() {
        this.play('super_dead')
    }

};