import React, { Component } from 'react'

export default class Social extends Component {
  render() {
    return (
      <div className="social">
        <p>Visit the arcade for all the games on</p>
        <a href="http://www.instagram.com" target="_blank" rel="noopener noreferrer" className="insta">Krispy Kreme Instagram</a>
        <a href="http://www.facebook.com" target="_blank" rel="noopener noreferrer" className="facebook">Krispy Kreme Facebook</a>
      </div>
    )
  }
}
