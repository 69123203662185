import Phaser from 'phaser';
import { InitScene } from './Scenes/InitScene';
import { PreloadScene } from './Scenes/PreloadScene';
import { ControlScene } from './Scenes/ControlScene';
import { ThrowbackTitleScene } from './Scenes/ThrowbackTitleScene';
import { FBFTitleScene } from './Scenes/FBFTitleScene';
import { FBFScene } from './Scenes/FBFScene';
import { InstructionScene } from './Scenes/InstructionScene';
import { IntroScene } from './Scenes/IntroScene';
import { HUD } from './Scenes/HUD';
import { IntermissionScene } from './Scenes/IntermissionScene';
import { ScoreScene } from './Scenes/ScoreScene';
import { EndScene } from './Scenes/EndScene';

export default class Fairybread {

    constructor() {

        const STAGE_PADDING = 60;
        const STAGE_WIDTH = (window.innerWidth - STAGE_PADDING);
        const STAGE_HEIGHT = STAGE_WIDTH;
        const STAGE_COLOUR = "#5D5D5D";
        const CANVAS = "CanvasWrapper";
        const GAME_CONFIG = {
            type: Phaser.AUTO,

            scale: {
                parent: CANVAS,
                mode: Phaser.Scale.FIT,
                width: 660,
                height: 660
            },

            backgroundColor: STAGE_COLOUR,
            render: {
                pixelArt: true
            },
            fps: {
                // target: 15,
            },
            autoFocus: true,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: {
                        y: 1400
                    }
                    // ,
                    // debug: true,
                }
            },

            scene: [
                InitScene,
                PreloadScene,
                ControlScene,
                ThrowbackTitleScene,
                FBFTitleScene,
                InstructionScene,
                IntroScene,
                FBFScene,
                HUD,
                IntermissionScene,
                ScoreScene,
                EndScene
            ]
        }

        var game = new Phaser.Game(GAME_CONFIG);
    }
}