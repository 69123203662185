import Phaser from 'phaser';

export default class Grandstand extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.displayWidth = 367;
        this.displayHeight = 168;
        this.body.mass = 100;
        this.body.bounce = true;
        this.body.immovable = true;
    }
};
