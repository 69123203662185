export default function attackData(scene) {
    scene.game.attackData = {

        attack11: {
            key: "attack11",
            moveName: "Standing punch",
            desc: "Straight",
            type: "melee",
            hitFrames: [41],
            damage: [4], //0~20, total 40
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [10], //0~2, x: 10 decimal place, y:1 decimal place
            reaction: ["hurt1"]
        },
        attack12: {
            key: "attack12",
            moveName: "Standing kick",
            desc: "Kick",
            type: "melee",
            hitFrames: [44],
            damage: [4],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [10],
            reaction: ["hurt1"]
        },
        attack21: {
            key: "attack21",
            moveName: "Crouch punch",
            desc: "Karate Chop",
            type: "melee",
            hitFrames: [51],
            damage: [6],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [21],
            reaction: ["hurt2"]
        },
        attack22: {
            key: "attack22",
            moveName: "Crouch kick",
            desc: "Heel kick",
            type: "melee",
            hitFrames: [55, 57],
            damage: [4, 6],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [1, 20],
            reaction: ["hurt1", "hurt2"]
        },
        attack31: {
            key: "attack31",
            moveName: "Air punch",
            desc: "Air punch",
            type: "melee",
            hitFrames: [47],
            damage: [12],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [10],
            reaction: ["hurt1"]
        },
        attack32: {
            key: "attack32",
            moveName: "Air kick",
            desc: "Air kick",
            type: "melee",
            hitFrames: [49],
            damage: [12],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [20],
            reaction: ["hurt2"]
        },
        attack51: {
            key: "attack51",
            moveName: "Sprinkle Canon",
            desc: "Shoots sprinkle",
            type: "projectile",
            hitFrames: [74],
            damage: [3],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [32],
            travel: {
                keepMoving: false,
                punch: [0, 0],
                kick: [0, 0]
            },
            reaction: ["hurt2"]
        },
        attack52: {
            key: "attack52",
            moveName: "Rocket Punch",
            desc: "Flying uppercut",
            type: "melee",
            hitFrames: [75, 78],
            damage: [5, 4],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [12, 11],
            travel: {
                keepMoving: false,
                punch: [200 ,-700 ],
                kick: [700,-1500]
            },
            reaction: ["hurt2", "hurt2"]
        },
        attack61: {
            key: "attack61",
            moveName: "Box Swing",
            desc: "Swings the box",
            type: "melee",
            hitFrames: [83],
            damage: [12], //0~20, total 40
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [41], //0~3
            travel: {
                keepMoving: false,
                punch: [300, -600],
                kick: [600, -800]
            },
            reaction: ["hurt2"]
        },
        attack62: {
            key: "attack62",
            moveName: "Rolling Donut",
            desc: "Roll",
            type: "melee",
            hitFrames: [85, 87],
            damage: [5, 5],
            hitBox: {start: {x: 0, y: 0}, size: {x: 20, y: 20}},
            push: [70, 70],
            travel: {
                keepMoving: true,
                punch: [700, 0],
                kick: [700, 0]
            },
            reaction: ["hurt1", "hurt1"]
        }


    };

}