import Phaser from 'phaser';
import { CST } from '../CST';

export class IntermissionScene extends Phaser.Scene {

    constructor() {
        super({
            key: CST.SCENES.INTERMISSION
        });
    }

    preload() {

    }

    create(passedData) {
        // console.log("intermission");
        // console.log(passedData);
        if (passedData.life <= 0) {
            this.scene.start(CST.SCENES.SCORE, passedData);
        } //
        else if (passedData.level > 3) {
            this.scene.start(CST.SCENES.END, passedData);
        } //
        else {
            this.scene.start(CST.SCENES.GAME, passedData);
            this.scene.launch(CST.SCENES.HUD, passedData);

        }

    }

    update() {

    }



}