import Phaser from 'phaser';
import { CST } from '../CST';

export class FBFTitleScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.GAME_TITLE });
    }

    create() {
        this.control = this.scene.get(CST.SCENES.CONTROL);

        this.mainImage = this.add.image(330, 330, 'screen_title');
        this.title_hotlight = this.add.sprite(330, 400, 'title_hotlight', 0).setOrigin(0.5, 0)
        this.title_croissant = this.add.sprite(330, 150, 'title_croissant', 0).setOrigin(0.5, 0)

        this.time.delayedCall(800, function() {
            this.title_hotlight.play('title_hotlight');;
        }, [], this);

        this.time.delayedCall(2500, function() {
            this.title_croissant.play('title_croissant');;
        }, [], this);


        // this.pressAnyKey();    
        // this.understood();    

    }

    update() {
        if (this.control.keys.fire.isDown || this.control.keys.jump.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;
                this.understood();

            }
        }
    }

    understood() {
        // this.scene.start(CST.SCENES.VS, this.keys);
        this.scene.start(CST.SCENES.INSTRUCTION, { isFromBeginning: true });

    }
    pressAnyKey() {
        this.input.keyboard.on('keydown', function(event) {
            this.understood();
        }, this);

    }
}