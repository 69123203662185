import Phaser from 'phaser';
import { CST } from '../CST';
import Line from './Sprites/Line';
import Kart from './Sprites/Kart';
import Jam from './Sprites/Jam';
import Chevron from './Sprites/Chevron';
import Grid from './Sprites/Grid';
import Doughnut from './Sprites/Doughnut';
import MysteryBox from './Sprites/MysteryBox';
import Checkpoint from './Sprites/Checkpoint';
import Grandstand from './Sprites/Grandstand';

export class KartScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.GAME });
    }

    init(data) {
    
        /* World */

        this.BOUNDS_X = (60 * 128);
        this.BOUNDS_Y = (40 * 128);
        this.ACCELLERATE_SPEED = 10;
        this.REVERSE_ACCELLERATE_SPEED = 15;
        this.DECELLERATE_SPEED = 5;
        this.REVERSE_DECELLERATE_SPEED = 2;
        this.STEERING_VECTOR = 0.003;
        this.INIT_MAX_SPEED = 500;
        this.MAX_SPEED = 500;
        this.REVERSE_MAX_SPEED = -300;
        this.SPEED_BOOST = 200;
        this.POWER_TIME = 5000;
        this.GRAVEL_SLOW = .5;
        this.GRAVEL_SUPER_SLOW = .3;
        this.ENEMY_DIFFICULTY = 1.2 ;
        this.MAX_ENEMIES = 4;
        this.ENEMY_ACCELLERATE_SPEED = this.ACCELLERATE_SPEED * this.ENEMY_DIFFICULTY;
        this.ENEMY_INIT_MAX_SPEED = this.MAX_SPEED * this.ENEMY_DIFFICULTY;
        this.ENEMY_MAX_SPEED = this.ENEMY_INIT_MAX_SPEED;
        this.ENEMY_SUPRESS_SPEED = 300;

        /* Car */

        this.grid = { x: 450, y: 4250 };
        this.player = null;
        this.playerId = 16;
        this.gravel_factor = 1;
        this.player_position = [];
        this.current_position = 0;
        this.current_direction = false;

        /* Enemy */
        this.enemies = [];
        this.enemySpeed = 0;
        this.enemyAngle = 0;
        this.currentWaypoint = 0;
        this.wayPointCollection = [{ x: 4 * 128, y: 19.5 * 128 },
        { x: 16 * 128, y: 18 * 128 },
        { x: 17 * 128, y: 26 * 128 },
        { x: 29 * 128, y: 26 * 128 },
        { x: 29 * 128, y: 15 * 128 },
        { x: 24 * 128, y: 11 * 128 },
        { x: 14 * 128, y: 10 * 128 },
        { x: 14.5 * 128, y: 4 * 128 },
        { x: 41 * 128, y: 4 * 128 },
        { x: 42 * 128, y: 24 * 128 },
        { x: 50 * 128, y: 24 * 128 },
        { x: 51.3 * 128, y: 12.5 * 128 },
        { x: 56.3 * 128, y: 12.3 * 128 },
        { x: 58 * 128, y: 25 * 128 },
        { x: 58 * 128, y: 30 * 128 },
        { x: 53.25 * 128, y: 35.75 * 128 },
        { x: 46 * 128, y: 36.4 * 128 },
        { x: 25 * 128, y: 36.4 * 128 },
        { x: 4 * 128, y: 35 * 128 }
        ];
        this.offset_angle = -90;

        /* Power Ups */
        this.star = 'star';
        this.mushroom = 'mushroom';
        this.shell = 'shell';
        this.jampower = 'jam';
        this.mystery_boxs = [{ x: 2.5 * 128, y: 22 * 124, r: 0 }, { x: 3.5 * 128, y: 22 * 124, r: 0 }, { x: 4.5 * 128, y: 22 * 124, r: 0 }, { x: 5.5 * 128, y: 22 * 124, r: 0 },{ x: 27.5 * 128, y: 17 * 128, r: 0 }, { x: 28.5 * 128, y: 17 * 128, r: 0 }, { x: 29.5 * 128, y: 17 * 128, r: 0 }, { x: 30.5 * 128, y: 17 * 128, r: 0 },{ x: 27 * 128, y: 2.5 * 128, r: 90 }, { x: 27 * 128, y: 3.5 * 128, r: 90 }, { x: 27 * 128, y: 4.5 * 128, r: 90 }, { x: 27 * 128, y: 5.5 * 128, r: 90 },{ x: 55.5 * 128, y: 25 * 128, r: 180 }, { x: 56.5 * 128, y: 25 * 128, r: 180 }, { x: 57.5 * 128, y: 25 * 128, r: 180 }, { x: 58.5 * 128, y: 25 * 128, r: 180 }];

        this.mystery_box = [this.jampower, this.star, this.shell];
        this.currentPower = '';
        this.shellHit = false;
        this.coins = [{ x: 6.5 * 128, y: 18.5 * 124 }, { x: 7.5 * 128, y: 18.5 * 124 }, { x: 8.5 * 128, y: 18.5 * 124 }, { x: 9.5 * 128, y: 18.5 * 124 }, { x: 10.5 * 128, y: 18.5 * 124 }, { x: 11.5 * 128, y: 18.5 * 124 }, { x: 12.5 * 128, y: 18.5 * 124 }, { x: 13.5 * 128, y: 18.5 * 124 }, { x: 14.5 * 128, y: 18.5 * 124 },{ x: 15 * 128, y: 11 * 128 }, { x: 16 * 128, y: 11 * 128 }, { x: 17 * 128, y: 11 * 128 }, { x: 18 * 128, y: 11 * 128 }, { x: 19 * 128, y: 11 * 128 }, { x: 20 * 128, y: 11 * 128 }, { x: 21 * 128, y: 11 * 128 }, { x: 22 * 128, y: 11 * 128 },{ x: 44 * 128, y: 19 * 128 }, { x: 45 * 128, y: 19 * 128 }, { x: 46 * 128, y: 19 * 128 }, { x: 47 * 128, y: 19 * 128 }, { x: 48 * 128, y: 19 * 128 }, { x: 49 * 128, y: 19 * 128 },{ x: 29 * 128, y: 4 * 128 }, { x: 30 * 128, y: 4 * 128 }, { x: 31 * 128, y: 4 * 128 }, { x: 32 * 128, y: 4 * 128 }, { x: 33 * 128, y: 4 * 128 },{ x: 9 * 128, y: 34.5 * 128 }, { x: 10 * 128, y: 35.5 * 128 }, { x: 11 * 128, y: 36.5 * 128 }, { x: 12 * 128, y: 37.5 * 128 }, { x: 13 * 128, y: 36.5 * 128 }, { x: 14 * 128, y: 35.5 * 128 }, { x: 15 * 128, y: 34.5 * 128 }, { x: 16 * 128, y: 35.5 * 128 }, { x: 17 * 128, y: 36.5 * 128 }, { x: 18 * 128, y: 37.5 * 128 }];

        /* Road */
        this.jams = [{ x: 24 * 128, y: 36.4 * 128 }, { x: 13.5 * 128, y: 4 * 128 }, { x: 47 * 128, y: 35.4 * 128 }, { x: 35 * 128, y: 4 * 128 }, { x: 44 * 128, y: 25 * 128 }];
        this.jam_group = null;
        this.jam = [];
        this.pole = [];
        this.tyre = [];
        this.tyres = [
            { x: 2 * 128, y: 17.5 * 124 }, { x: 2.3 * 128, y: 17 * 124  }, { x: 2.6 * 128, y: 16.6 * 124 }, 
            { x: 6 * 128, y: 21.3 * 124 }, { x: 6.3 * 128, y: 20.9 * 124  }, { x: 6.6 * 128, y: 20.6 * 124 },  
            { x: 18.3 * 128, y: 16.6 * 124 }, { x: 18.6 * 128, y: 16.9 * 124  }, { x: 18.9 * 128, y: 17.2 * 124 },
            { x: 14 * 128, y: 20.6 * 124 }, { x: 14.4 * 128, y: 20.9 * 124  }, { x: 14.7 * 128, y: 21.2 * 124 },
            { x: 19 * 128, y: 25 * 124 }, { x: 19.3 * 128, y: 25.3 * 124  }, { x: 19.6 * 128, y: 25.6 * 124 },
            { x: 15 * 128, y: 29 * 124 }, { x: 15.3 * 128, y: 29.3 * 124  }, { x: 15.6 * 128, y: 29.6 * 124 },
            { x: 26.2 * 128, y: 25.8 * 124 }, { x: 26.6 * 128, y: 25.7 * 124  }, { x: 26.9 * 128, y: 25.4 * 124 },
            { x: 30 * 128, y: 29.9 * 124 }, { x: 30.3 * 128, y: 29.6 * 124  }, { x: 30.7 * 128, y: 29.3 * 124 },
            { x: 25.6 * 128, y: 9.6 * 124 }, { x: 26 * 128, y: 10 * 124 }, { x: 26.4 * 128, y: 10.4 * 124 }, { x: 26.8 * 128, y: 10.8 * 124 }, { x: 27.2 * 128, y: 11.2 * 124 }, { x: 27.6 * 128, y: 11.6 * 124 }, { x: 28 * 128, y: 12 * 124 },
            { x: 28.4 * 128, y: 12.4 * 124 }, { x: 28.8 * 128, y: 12.8 * 124 }, { x: 29.2 * 128, y: 13.2 * 124 }, { x: 29.6 * 128, y: 13.6 * 124 }, { x: 30 * 128, y: 14 * 124 }, { x: 30.4 * 128, y: 14.4 * 124 }, { x: 30.8 * 128, y: 14.8 * 124 },
            { x: 12 * 128, y: 12.6 * 124 }, { x: 12.3 * 128, y: 13 * 124 }, { x: 12.7 * 128, y: 13.3 * 124 },
            { x: 16.2 * 128, y: 8.4 * 124 },  { x: 16.6 * 128, y: 8.8 * 124 },
            { x: 16.3 * 128, y: 6.9 * 124 },  { x: 16.7 * 128, y: 6.5 * 124 },
            { x: 12.2 * 128, y: 2.9 * 124 }, { x: 12.6 * 128, y: 2.5 * 124 }, { x: 13 * 128, y: 2.1 * 124 },
            { x: 39.2 * 128, y: 6.3 * 124 }, { x: 39.6 * 128, y: 6.6 * 124 }, { x: 40 * 128, y: 6.9 * 124 }, 
            { x: 43.2 * 128, y: 2.2 * 124 }, { x: 43.6 * 128, y: 2.6 * 124 }, { x: 43.9 * 128, y: 3 * 124 },
            { x: 40.2 * 128, y: 26.9 * 124 }, { x: 40.6 * 128, y: 27.3 * 124 }, { x: 41 * 128, y: 27.6 * 124 },
            { x: 44 * 128, y: 23 * 124 }, { x: 44.2 * 128, y: 23.5 * 124 }, { x: 44.7 * 128, y: 23.8 * 124 },
            { x: 52 * 128, y: 27.8 * 124 }, { x: 52.4 * 128, y: 27.4 * 124 }, { x: 52.8 * 128, y: 27 * 124 },
            { x: 48.3 * 128, y: 23.6 * 124 }, { x: 48.7 * 128, y: 23.3 * 124 }, { x: 49 * 128, y: 23 * 124 },
            { x: 53.2 * 128, y: 15.2 * 124 }, { x: 53.6 * 128, y: 14.8 * 124 },
            { x: 54.3 * 128, y: 14.8 * 124 }, { x: 54.7 * 128, y: 15.2 * 124 },
            { x: 49.1 * 128, y: 10.9 * 124 }, { x: 49.5 * 128, y: 10.5 * 124 }, { x: 49.9 * 128, y: 10.1 * 124 },
            { x: 58.1 * 128, y: 10.4 * 124 }, { x: 58.5 * 128, y: 10.8 * 124 }, { x: 58.9 * 128, y: 11.2 * 124 },
            { x: 55 * 128, y: 34.3 * 124 }, { x: 54.6 * 128, y: 34.7 * 124 }, { x: 54.2 * 128, y: 35.1 * 124 },
            { x: 58.8 * 128, y: 38.5 * 124 }, { x: 58.4 * 128, y: 38.9 * 124 }, { x: 57.8 * 128, y: 39.2 * 124 },
            { x: 2 * 128, y: 38.5 * 124 }, { x: 2.4 * 128, y: 38.9 * 124 }, { x: 2.8 * 128, y: 39.2 * 124 },
            { x: 6.1 * 128, y: 34.1 * 124 }, { x: 6.4 * 128, y: 34.6 * 124 }, { x: 6.9 * 128, y: 34.8 * 124 }
        ]; 

        this.grandstands = [{ x: 20 * 128, y: 34 * 124 }, { x: 24 * 128, y: 34 * 124 }, { x: 28 * 128, y: 34 * 124 }, { x: 32 * 128, y: 34 * 124 },
                            { x: 20 * 128, y: 8.5 * 124 }, { x: 23 * 128, y: 8.5 * 124 } ];
        this.grandstand = [];

        this.mushrooms = [ { x: 10 * 128, y: 17.5 * 124, r: 90 }, { x: 23 * 128, y: 27.7 * 124, r: 90 }, { x: 27 * 128, y: 13 * 124, r: -45 }, { x: 19 * 128, y: 3 * 124, r: 90 }, { x: 38 * 128, y: 3 * 124, r: 90 }, { x: 42 * 128, y: 20 * 124, r: 180 }, { x: 50 * 128, y: 17 * 124, r: 0 }, { x: 57 * 128, y: 22 * 124, r: 180 }, { x: 46 * 128, y: 36 * 124, r: 270 }, { x: 28 * 128, y: 37.5 * 124, r: 270 }];
        this.mushroom = [];

        /* Position */
        this.checkpoints = [{ x: 450, y: 4050, r: 0 }, { x: 450, y: 3050, r: 0 }, { x: 1350, y: 2300, r: 90 },{ x: 2050, y: 2900, r: 0 }, { x: 2850, y: 3500, r: 90 }, { x: 3550, y: 3000, r: 0 },{ x: 3350, y: 2000, r: 0 }, { x: 2750, y: 1500, r: 90 }, { x: 1850, y: 1000, r: 0 },{ x: 2250, y: 500, r: 90 }, { x: 3350, y: 500, r: 90 }, { x: 4250, y: 500, r: 90 }, { x: 5050, y: 500, r: 90 },{ x: 5350, y: 1500, r: 0 }, { x: 5350, y: 2400, r: 0 }, { x: 5850, y: 3200, r: 90 }, { x: 6350, y: 2500, r: 0 },{ x: 6350, y: 1900, r: 0 }, { x: 6850, y: 1500, r: 90 }, { x: 7250, y: 2000, r: 0 }, { x: 7250, y: 2700, r: 0 }, { x: 7250, y: 3500, r: 0 }, { x: 7250, y: 4300, r: 0 },{ x: 6250, y: 4600, r: 90 }, { x: 5250, y: 4600, r: 90 }, { x: 4250, y: 4600, r: 90 }, { x: 3250, y: 4600, r: 90 }, { x: 2250, y: 4600, r: 90 }, { x: 1250, y: 4600, r: 90 }];
        this.checkpoint = [];

        /* Map */
        this.map = null;
        this.layer = null;
        this.grass = null;

        /* Laps */
        this.lap_start = false;
        this.lap_mid = false;

        this.lap_indicator = null;

        /* Position Calls in Animation Loop */
        this.position_animation_loop = 0;

        /* ENDED */

        this.ended = false;

        /* Power Stuff */

        document.addEventListener("left", function (event) {
            this.cursors.left.isDown = true;
            this.cursors.right.isDown = false;
        }.bind(this));

        document.addEventListener("right", function (event) {
            this.cursors.right.isDown = true;
            this.cursors.left.isDown = false;
        }.bind(this));

        document.addEventListener("down", function (event) {
            this.cursors.down.isDown = true;
            this.cursors.right.isDown = false;
            this.cursors.up.isDown = false;
            this.cursors.left.isDown = false;
        }.bind(this));

        document.addEventListener("dpad_up", function (event) {
            this.cursors.right.isDown = false;
            this.cursors.left.isDown = false;
            this.cursors.up.isDown = false;
            this.cursors.down.isDown = false;
        }.bind(this));

        document.addEventListener("button_b", function (event) {
            this.cursors.up.isDown = true;
        }.bind(this));

        document.addEventListener("button_a", function (event) {
            this.usePower();
        }.bind(this));

        document.addEventListener("button_b_up", function (event) {
            this.cursors.up.isDown = false;
        }.bind(this));

        document.addEventListener("help", function (event) {
            this.scene.pause();
            this.scene.pause(CST.SCENES.HUD);
            this.scene.pause(CST.SCENES.SOUND);
            this.scene.launch(CST.SCENES.INSTRUCTIONS, false);
            this.scene.bringToTop(CST.SCENES.INSTRUCTIONS);
        }.bind(this));

        document.addEventListener("unhelp", function (event) {
            this.scene.resume();
            this.scene.resume(CST.SCENES.HUD);
            this.scene.resume(CST.SCENES.SOUND);
            this.scene.stop(CST.SCENES.INSTRUCTIONS);

        }.bind(this));

        this.scene.launch(CST.SCENES.HUD, 'Heads Up Display');
        this.scene.launch(CST.SCENES.SOUND, 'Sounds');
        this.scene.launch(CST.SCENES.CONTROL, 'Control');
    }

    create() {

        /* Set up world */
        this.cameras.main.setBounds(0, 0, this.BOUNDS_X, this.BOUNDS_Y);
        this.cameras.main.roundPixels = true;
        this.physics.world.setBounds(0, 0, this.BOUNDS_X, this.BOUNDS_Y);

        /* Map */
        this.map = this.make.tilemap({ key: 'map', tileWidth: 128, tileHeight: 128 });
        var trackset = this.map.addTilesetImage('track-set', 'track');
        var grassset = this.map.addTilesetImage('grass-set', 'grass')
        this.layer = this.map.createDynamicLayer('GroundLayer', trackset, 0, 0);
        this.grass = this.map.createDynamicLayer('GrassLayer', grassset, 0, 0);
        this.lake = this.map.createDynamicLayer('LakeLayer', grassset, 0, 0);

        this.layer.setTileIndexCallback([0,1,2,4,6,7,11,42,53,17,15,5,41,23,65,14,25,26,28,36,32,71,75,38,52,51,16,31,1610612758,61,62,64,13,2684354564,27,22,29,10,89,50,30,1610612778,24,39,20,99,60,40,33,70,80,43,46,54,55,56,57,66,67,81,82,72,83,3221225584,1610612780,1610612790,1610612777,3221225514,1610612789,1610612798,3221225476,1610612787,1610612797], this.hitRoad, this);
        this.grass.setTileIndexCallback([101,113,0,102,103,191,192,193,194,195,196,197,198,151,152,153,154,155,156,157,158,159,160,128,129,112,105,161,162,163,164,165,166,167,168,169,170,138,139,126,171,172,173,174,175,176,177,178,179,180,147,148,1610612872,181,182,183,184,185,186,187,188,189,190,149,150,2684354696,104,114,115,14,3221225573,3221225585], this.hitGrass, this);
        this.lake.setTileIndexCallback([0,131,123,132,141,124,127,1073741967,122,117,118,119,106,107,108,109,133,110,125,2684354668,143,116,3221225615,120,137,142], this.hitLake, this);

        this.shell_group = this.physics.add.group({
            defaultKey: 'shell_fire',
            runChildUpdate: false
        });

        this.jam_group = this.physics.add.group({
            defaultKey: 'jam',
            runChildUpdate: false
        });

        /* Init player */
        this.pole[0] = new Grid({ scene: this, key: 'grid_p1', x: this.grid.x, y: this.grid.y - 50 });
        this.lap_indicator = this.add.bitmapText(510, 4060, 'HalfBoldPixel-7', 'START', 45).setOrigin(0.5, 0.5);

        this.player = new Kart({
            scene: this,
            key: 'kart_pink',
            x: this.grid.x,
            y: this.grid.y,
            rotation: 0,
            id: this.playerId,
            offset_angle: this.offset_angle,
            enemy: false,
            max_velocity: this.MAX_SPEED,
            waypoint: this.wayPointCollection,
            maxspeed: this.MAX_SPEED
        }).setBounce(1);
        this.player.setDepth(1);
        this.physics.add.overlap(this.player, this.shell_group, this.enemyHit, null, this);
        this.physics.add.overlap(this.player, this.jam_group, this.hitOil, null, this);
        this.jam_group.setDepth(0);

        for (var k = 0; k < this.MAX_ENEMIES; k++) {
            this.pole[k + 1] = new Grid({ scene: this, key: 'grid', x: this.grid.x + ((k % 2 === 0) ? 100 : 0), y: this.grid.y + (((k + 1) * 100) - 50) });

            let newWayPoint = [];

            for(var i = 0; i < this.wayPointCollection.length; i++) {
                let randomx = 50 - Math.random() * 100;
                let randomy = 50 - Math.random() * 100;
                let obj = { x: this.wayPointCollection[i].x + randomx, y: this.wayPointCollection[i].y + randomy};
                newWayPoint.push(obj);
            }

            let randomMax = Math.random() * 60;

            this.enemies[k] = new Kart({
                scene: this,
                key: 'kart_brown',
                x: this.grid.x + ((k % 2 === 0) ? 100 : 0),
                y: this.grid.y + ((k + 1) * 100),
                rotation: 0,
                id: k,
                offset_angle: this.offset_angle,
                enemy: true,
                max_velocity: this.MAX_SPEED - randomMax,
                waypoint: newWayPoint,
                maxspeed: this.ENEMY_MAX_SPEED
            });

            this.enemies[k].setDepth(1);
            let ememyId = this.enemies[k].id;

            this.physics.add.collider(this.player, this.enemies[k], this.enemyBump, null, this);
            // this.physics.add.collider(this.enemies[k], this.player, this.enemyBump, null, this);

            this.physics.add.collider(this.enemies[k], this.grass);
            this.physics.add.collider(this.enemies[k], this.lake);
            this.physics.add.collider(this.enemies[k], this.layer);

            this.physics.add.overlap(this.enemies[k], this.shell_group, this.enemyHit, null, this);
            this.physics.add.overlap(this.enemies[k], this.jam_group, this.hitOil, null, this);

            for (var kk = 0; kk < this.MAX_ENEMIES; kk++) {
                this.physics.add.collider(this.enemies[k], this.enemies[kk]);
            }
        }

        this.physics.add.collider(this.player, this.lake);
        this.physics.add.collider(this.player, this.grass);
        this.physics.add.collider(this.player, this.layer);

        /* Mystery Box */
        for (var i = 0; i < this.mystery_boxs.length; i++) {
            let box = new MysteryBox({ scene: this, key: 'box', x: this.mystery_boxs[i].x, y: this.mystery_boxs[i].y, rotation: this.mystery_boxs[i].r });
            //let box = this.physics.add.sprite(this.mystery_boxs[i].x, this.mystery_boxs[i].y, 'box');
            box.displayWidth = 80;
            box.displayHeight = 80;
            this.physics.add.overlap(this.player, box, this.mystery, null, this);

            for (var mk = 0; mk < this.MAX_ENEMIES; mk++) {
                this.physics.add.overlap(this.enemies[mk], box, this.mystery, null, this);
            }
        }

        this.box_particle = this.add.particles('spark');
        this.box_emitter = this.box_particle.createEmitter({
            x: 0,
            y: 0,
            speed: { min: -800, max: 800 },
            angle: { min: 0, max: 360 },
            scale: { start: 5.5, end: 0 },
            lifespan: 200,
            active: false,
            gravityY: 800
        });

        for (var g = 0; g < this.grandstands.length; g++) {
            this.grandstand[g] = new Grandstand({ scene: this, key: 'grandstand', x: this.grandstands[g].x, y: this.grandstands[g].y });
            this.physics.add.collider(this.player, this.grandstand[g], this.hitOil, null, this);
        }

        for (var t = 0; t < this.tyres.length; t++) {

            if (t % 2 === 0) {
                this.tyre[t] = new Doughnut({ scene: this, key: 'tyre', x: this.tyres[t].x, y: this.tyres[t].y });
            } else {
                this.tyre[t] = new Doughnut({ scene: this, key: 'tyre_2', x: this.tyres[t].x, y: this.tyres[t].y });
            }

            this.physics.add.collider(this.player, this.tyre[t], this.hitTyre, null, this);
        }

        this.tyre_particle = this.add.particles('spark');

        this.tyre_emitter1 = this.tyre_particle.createEmitter({
            x: 0,
            y: 0,
            speed: { min: -400, max: 100 },
            angle: { min: 0, max: 360 },
            scale: { start: 1.5, end: 0 },
            lifespan: 500,
            active: false,
            tint: [0x8b67a1, 0xe6b1c2, 0xe9abbf, 0xf8e898, 0x457b9d, 0xd5a169, 0x8b67a1],
            gravityY: 800
        });

        /* Jam */
        for (var j = 0; j < this.jams.length; j++) {
            this.jam[j] = new Jam({ scene: this, key: 'jam', x: this.jams[j].x, y: this.jams[j].y });
            this.jam[j].setDepth(0);
            this.physics.add.overlap(this.player, this.jam[j], this.hitOil, null, this);
            for (var jj = 0; jj < this.MAX_ENEMIES; jj++) {
                this.physics.add.overlap(this.enemies[jj], this.jam[j], this.hitOil, null, this);
            }
        }

        /* Mushroom */
        for (var m = 0; m < this.mushrooms.length; m++) {
            this.mushroom[m] = new Chevron({ scene: this, key: 'chevron', x: this.mushrooms[m].x, y: this.mushrooms[m].y, rotation: this.mushrooms[m].r });
            this.mushroom[m].setDepth(0);
            this.physics.add.overlap(this.player, this.mushroom[m], this.hitMushroom, null, this);
            for (var mm = 0; mm < this.MAX_ENEMIES; mm++) {
                this.physics.add.overlap(this.enemies[mm], this.mushroom[m], this.hitMushroom, null, this);
            }
        }

        /* Coins */
        for (var c = 0; c < this.coins.length; c++) {
            let coin = this.physics.add.sprite(this.coins[c].x, this.coins[c].y, 'coin');
            coin.displayWidth = 60;
            coin.displayHeight = 60;
            this.physics.add.overlap(this.player, coin, this.coin, null, this);
            for (var cc = 0; cc < this.MAX_ENEMIES; cc++) {
                this.physics.add.overlap(this.enemies[cc], coin, this.coin, null, this);
            }
        }

        /* Controls */
        this.cursors = this.input.keyboard.createCursorKeys();
        this.cameras.main.startFollow(this.player, false, 0.5, 0.1);
        this.cameras.main.followOffset.set(0, 0);
        this.cameras.main.setZoom(1);

        /* Time */
        this.start_lap_time = new Line({ scene: this, key: 'line', x: 450, y: 4050, rotation: 0 });
        this.mid_lap_line = new Line({ scene: this, key: 'line', x: 7000, y: 4000, rotation: 0 });


        for (var p = 0; p < this.checkpoints.length; p++) {
            this.checkpoint[p] = new Checkpoint({ scene: this, key: 'line', x: this.checkpoints[p].x, y: this.checkpoints[p].y, rotation: this.checkpoints[p].r, id: p });
            this.physics.add.overlap(this.player, this.checkpoint[p], this.hitCheckPoint, null, this);

            for (var pe = 0; pe < this.MAX_ENEMIES; pe++) {
                this.physics.add.overlap(this.enemies[pe], this.checkpoint[p], this.hitCheckPoint, null, this);
            }
        }

        for (var fk = 0; fk < this.MAX_ENEMIES; fk++) {
            this.physics.add.overlap(this.enemies[fk], this.start_lap_time, this.hitStart, null, this);
            this.physics.add.overlap(this.enemies[fk], this.mid_lap_line, this.hitMidpoint, null, this);
        }

        this.physics.add.overlap(this.player, this.start_lap_time, this.hitStart, null, this);
        this.physics.add.overlap(this.player, this.mid_lap_line, this.hitMidpoint, null, this);    
    
        this.smoke_particles = this.add.particles('smoke');
        this.smoke_emitter = this.smoke_particles.createEmitter({
            speed: 20,
            lifespan: 2000,
            gravityY: 50,
            blendmode: "DARKEN",
            alpha : {start: 1, end: 0},
            scale: { start: 2, end: 0, random: false }
        });

        this.smoke_emitter.startFollow(this.player);
        this.smoke_emitter.active = false;
        this.smoke_emitter.on = false;
        this.smoke_particles.setDepth(2);
    }

    update(time) {
        this.player.setVelocity(0);

        let current = this.cameras.main.zoom;
        
        if (this.player.speed >= 400 && current >= 0.6) {
            current -= 0.005;
        } else if (this.player.speed <= 399 && current <= 1) {
            current += 0.005;
        }

        this.cameras.main.setZoom(current);

        //If Player is enabled give control
        if (this.player.enabled) {

            if (this.cursors.up.isDown && this.player.speed <= (this.player.MAX_SPEED * this.player.gravel_factor)) {
                this.player.speed += this.ACCELLERATE_SPEED;
            } else if (this.cursors.down.isDown && this.player.speed >= (this.REVERSE_MAX_SPEED)) {
                this.player.speed -= this.REVERSE_ACCELLERATE_SPEED;
            } else {
                if (this.player.speed > 0) {
                    this.player.speed -= this.DECELLERATE_SPEED;
                } else if (this.player.speed < 0) {
                    this.player.speed += this.REVERSE_DECELLERATE_SPEED;
                }
            }

            if (this.cursors.left.isDown) {
                this.player.angle -= this.STEERING_VECTOR * this.player.speed;
            }

            if (this.cursors.right.isDown) {
                this.player.angle += this.STEERING_VECTOR * this.player.speed;
            }

        } else {
            //Player over the line - slow down
            if (this.player.speed > 0) {
                this.player.speed -= this.DECELLERATE_SPEED;
            }
        }

        this.jam_group.children.each(function (b) {
            if (b.active) {
                if (b.y < 0) {
                    b.setActive(false);
                }
            }
        });

        this.shell_group.children.each(function (b) {
            if (b.active) {
                if (b.y < 0) {
                    b.setActive(false);
                }
            }
        });

        /* Calculate Postion */
        this.player_position = [];
        this.position_animation_loop += 1;

        /* Enemy Racers */
        for (var k = 0; k < this.MAX_ENEMIES; k++) {

            if (this.enemies[k].enabled) {
                if(this.current_position >= 0 && this.current_position <= 1) {
                    if (this.enemies[k].speed < ((this.enemies[k].MAX_SPEED - this.ENEMY_SUPRESS_SPEED) * this.enemies[k].gravel_factor)) {
                        this.enemies[k].speed += this.ENEMY_ACCELLERATE_SPEED;
                    } else {
                        this.enemies[k].speed -= this.DECELLERATE_SPEED;
                    }
                } else {
                    if (this.enemies[k].speed < (this.enemies[k].MAX_SPEED * this.enemies[k].gravel_factor)) {
                        this.enemies[k].speed += this.ENEMY_ACCELLERATE_SPEED;
                    } else {
                        this.enemies[k].speed -= this.DECELLERATE_SPEED;
                    }
                }
                
            } else {
                this.enemies[k].speed = 0;
            }

            //Need a way point array for each enemy
            let currentPoint = this.enemies[k].current_way_point;
            let enemyAngleToTarget = Phaser.Math.Angle.Between(this.enemies[k].x, this.enemies[k].y, this.enemies[k].way_point_collection[currentPoint].x, this.enemies[k].way_point_collection[currentPoint].y);
            let enemyDistanceToTarget = Phaser.Math.Distance.Between(this.enemies[k].x, this.enemies[k].y, this.enemies[k].way_point_collection[currentPoint].x, this.enemies[k].way_point_collection[currentPoint].y);
            let currentAngle = this.enemies[k].rotation;
            let turnVector = Phaser.Math.Angle.RotateTo(currentAngle, enemyAngleToTarget, 0.06)

            if (enemyDistanceToTarget > 100) {

            } else {
                this.enemies[k].current_way_point += 1;

                if (this.enemies[k].current_way_point === this.enemies[k].way_point_collection.length) {
                    this.enemies[k].current_way_point = 0;
                }
            }

            if(this.position_animation_loop === 20) {

                let nextCheckPoint = 0;
                if (this.enemies[k].checkpoint !== this.checkpoints.length - 1) {
                    nextCheckPoint = this.enemies[k].checkpoint + 1;
                }
                let distnaceToNextCheckpoint = Phaser.Math.Distance.Between(this.enemies[k].x, this.enemies[k].y, this.checkpoints[nextCheckPoint].x, this.checkpoints[nextCheckPoint].y);
                let pos = { checkpoint: this.enemies[k].checkpoint, laps: this.enemies[k].currentLap, id: k, dist: distnaceToNextCheckpoint }
                this.player_position.push(pos);
            }

            this.enemies[k].rotation = turnVector;
            this.physics.velocityFromRotation(turnVector, this.enemies[k].speed, this.enemies[k].body.velocity);
        }


        /* Position && Direction -- Only check every 10 frames */

        if(this.position_animation_loop === 20) {

            /* Position */
            this.position_animation_loop = 0;
            let nextCheckPoint = 0;
            if (this.player.checkpoint !== this.checkpoints.length - 1) {
                nextCheckPoint = this.player.checkpoint + 1;
            }
    
            let distnaceToNextCheckpoint = Phaser.Math.Distance.Between(this.player.x, this.player.y, this.checkpoints[nextCheckPoint].x, this.checkpoints[nextCheckPoint].y);
            let pos = { checkpoint: this.player.checkpoint, laps: this.player.currentLap, id: this.playerId, dist: distnaceToNextCheckpoint };
            this.player_position.push(pos);
            this.updatePosition(this.player_position);

            /* Direction */
            let playerAngleToTarget = Phaser.Math.RadToDeg(Phaser.Math.Angle.Between(this.player.x, this.player.y, this.checkpoints[nextCheckPoint].x, this.checkpoints[nextCheckPoint].y));
            let angle = (playerAngleToTarget - this.player.angle);

            if (angle < -140 && angle > -250) {
                this.wrongWay(true);
            } else {
                this.wrongWay(false);
            }
        }

        //Control Player 1
        this.physics.velocityFromRotation(this.player.angle * (Math.PI / 180), this.player.speed, this.player.body.velocity);
    }

    wrongWay(direction) {
        if (this.current_direction !== direction) {
            this.events.emit('update_direction', direction);
            this.current_direction = direction;
        }
    }

    hitCheckPoint(sprite, checkpoint) {
        if (sprite.checkpoint !== checkpoint.id) {
            sprite.checkpoint = checkpoint.id
        }
    }

    updatePosition(positions) {

        positions.sort(function (racer1, racer2) {
            /* First check distance */
            if (racer1.dist > racer2.dist) return -1;
            if (racer1.dist < racer2.dist) return 1;
        });

        positions.sort(function (racer1, racer2) {
            /* Second Check Checkpoints */
            if (racer1.checkpoint > racer2.checkpoint) return 1;
            if (racer1.checkpoint < racer2.checkpoint) return -1;
        });

        positions.sort(function (racer1, racer2) {
            /* Last Check Laps */
            if (racer1.laps > racer2.laps) return 1;
            if (racer1.laps < racer2.laps) return -1;
        });
        
        let position = positions.findIndex(x => x.id === this.playerId);

        if (this.current_position !== position) {
            this.events.emit('update_position', position);
            this.current_position = position;
        }
    }

    hitRoad(sprite, tile) {
        if (sprite.isGrass) {
            sprite.isGrass = false;
            sprite.isLake = false;
            sprite.gravel_factor = 1;
        }
    }

    hitGrass(sprite, tile) {
        if (!sprite.isGrass && !sprite.invinceable) {
            sprite.isGrass = true;
            sprite.gravel_factor = this.GRAVEL_SLOW;
        }
    }


    hitLake(sprite, tile) {
        if (!sprite.isLake && !sprite.invinceable) {
            sprite.isLake = true;
            sprite.gravel_factor = this.GRAVEL_SUPER_SLOW;
        }
    }

    hitMushroom(sprite, mushroom) {
        sprite.mushroomPower();
        sprite.MAX_SPEED = (this.INIT_MAX_SPEED + this.SPEED_BOOST);
        sprite.gravel_factor = 1;
        
        if(sprite.id === this.playerId) {
            //this.cameras.main.shake(20);
        }
        
        this.time.delayedCall(this.POWER_TIME, function() {

            sprite.cruising();
            sprite.MAX_SPEED = this.INIT_MAX_SPEED;

        }, [], this);
    }

    hitOil(sprite, jam) {
        if (!sprite.oil && !sprite.invinceable) {
            sprite.oil = true;
            let currentSpeed = sprite.speed;
            sprite.speed = currentSpeed * .3;

            this.time.delayedCall(2500, function() {

                sprite.oil = false;
    
            }, [], this);
        }
    }

    hitTyre(sprite, tyre) {
        if(sprite.speed > 500) {
            this.cameras.main.shake(30,0.015);
            this.tyre_emitter1.active = true;
            this.tyre_emitter1.setPosition(tyre.x, tyre.y);
            this.tyre_emitter1.explode();
           
            this.time.delayedCall(500, function() {
                this.tyre_emitter1.active = false;
            }, [], this);
        }
    }

    hitStart(sprite) {
        sprite.hitStartPoint = true;
        if (sprite.hitMidPoint) {
            sprite.hitMidPoint = false;
            sprite.currentLap += 1;
            if (sprite.id === this.playerId) {
                this.lap_indicator.setText("FINISH");
                this.events.emit('lap_complete');
            }
        }
    }

    hitMidpoint(sprite) {
        sprite.hitMidPoint = true;
    }

    enable() {
        this.player.enabled = true;
        for (var k = 0; k < this.MAX_ENEMIES; k++) {
            this.enemies[k].enabled = true;
        }
    }

    disable(time, position, complete) {
        this.ended = true;
        this.player.enabled = false;
        for (var k = 0; k < this.MAX_ENEMIES; k++) {
            this.enemies[k].enabled = false;
        }

        if(!complete) {
            this.smoke_emitter.active = true;
            this.smoke_emitter.on = true;
            this.player.time_out();
        }

        this.time.delayedCall(7000, function() {

            if(this.ended){
                this.scene.stop(CST.SCENES.HUD);
                this.scene.stop(CST.SCENES.SOUND);
                this.scene.start(CST.SCENES.SCORE, { coins: this.player.coins, boxes: this.player.boxes, time: time, position: position, completed: complete });
            }

        }, [], this);
    }

    coin(sprite, coin) {
        coin.disableBody(true, true);
        if (sprite.id === this.playerId) {
            this.events.emit('coin_sound');
            this.addCoinPower();
        }
    }

    mystery(sprite, box) {
        box.disableBody(true, true);
        let box_x = box.x;
        let box_y = box.y;

        this.box_emitter.explode(50);
        this.box_emitter.active = true;
        this.box_emitter.setPosition(box.x, box.y);
       
        this.time.delayedCall(500, function() {
            this.box_emitter.active = false;
        }, [], this);

        this.time.delayedCall(this.POWER_TIME, function() {

            if(!this.ended){
                box.enableBody(true, box_x, box_y, true, true)
            }

        }, [], this);

        if(sprite.id === this.playerId) {
            this.events.emit('box_sound');
            this.player.boxes += 1;
        }

        let random = this.mystery_box[Math.floor(Math.random() * this.mystery_box.length)];
        if (sprite.id === this.playerId) {
            switch (random) {
                case 'star':
                    this.addStarPower();
                    break;
                case 'mushroom':
                    this.addMusroomPower();
                    break;
                case 'shell':
                    this.addShellPower();
                    break;
                case 'jam':
                    this.addJamPower();
                    break;
                default:
                    break;
            };
        } else {
            switch (random) {
                case 'star':
                    this.starPower(sprite);
                    break;
                case 'mushroom':
                    this.mushroomPower(sprite);
                    break;
                case 'shell':
                    this.fireShell(sprite);
                    break;
                case 'jam':
                    this.dropJam(sprite);
                    break;
                default:
                    break;
            };
        }
    }

    addJamPower() {
        this.events.emit('add_jam');
        this.currentPower = 'jam';
    }

    addMusroomPower() {
        this.events.emit('add_mushroom');
        this.currentPower = 'mushroom';
    }

    addStarPower() {
        this.events.emit('add_starpower');
        this.currentPower = 'star';
    }

    addShellPower() {
        this.events.emit('add_shell');
        this.currentPower = 'shell';
    }

    addCoinPower() {
        this.events.emit('add_coin');
        this.player.coins += 1;
    }

    usePower() {
        switch (this.currentPower) {
            case 'mushroom':
                this.mushroomPower(this.player);
                break;
            case 'star':
                this.starPower(this.player);
                break;
            case 'shell':
                this.fireShell(this.player);
                break;
            case 'jam':
                this.dropJam(this.player);
                break;
            default:
                // console.log('NO POWER');
                break;
        };
    }

    mushroomPower(kart) {
        kart.mushroomPower();
        kart.MAX_SPEED = (this.INIT_MAX_SPEED + this.SPEED_BOOST);
        kart.gravel_factor = 1;

        if(kart.id === this.playerId){
            this.game.events.emit('hotlight_sound');
        }

        this.time.delayedCall(this.POWER_TIME, function() {

            kart.cruising();
            kart.MAX_SPEED = this.INIT_MAX_SPEED;
            if (kart.id === this.playerId) {
                this.game.events.emit('hotlight_sound_over');
                this.events.emit('used_power');
                this.currentPower = '';
            }

        }, [], this);
    }

    starPower(kart) {
        kart.starPower();
        kart.MAX_SPEED = (this.INIT_MAX_SPEED + this.SPEED_BOOST);
        kart.gravel_factor = 1;

        if(kart.id === this.playerId){
            this.game.events.emit('hotlight_sound');
        }

        this.time.delayedCall(this.POWER_TIME, function() {
            kart.cruising();
            kart.MAX_SPEED = this.INIT_MAX_SPEED;
            if (kart.id === this.playerId) {
                this.game.events.emit('hotlight_sound_over');
                this.events.emit('used_power');
                this.currentPower = '';
            }
        }, [], this);
    }

    dropJam(kart) {
        if (kart.id === this.playerId) {
            this.events.emit('used_power');
            this.currentPower = '';
        }
        var length = 110;
        var realAngle = kart.angle + 180;
        var radians = realAngle * Math.PI / 180;
        var x = kart.x + Math.cos(radians) * length;
        var y = kart.y + Math.sin(radians) * length;

        var jam = this.jam_group.get(x, y);
        if (jam) {
            jam.setActive(true);
            jam.setVisible(true);
            var velocity = new Phaser.Math.Vector2();
            var velocityFromRotation = this.physics.velocityFromRotation(kart.angle * (Math.PI / 180), 0, velocity);
            jam.body.velocity = velocityFromRotation;
        }
    }

    fireShell(kart) {
        if (kart.id === this.playerId) {
            this.events.emit('used_power');
            this.currentPower = '';
        }
        var length = -90;
        var realAngle = kart.angle + 180;
        var radians = realAngle * Math.PI / 180;
        var x = kart.x + Math.cos(radians) * length;
        var y = kart.y + Math.sin(radians) * length;

        var shell = this.shell_group.get(x, y);
        if (shell) {
            shell.setActive(true);
            shell.setVisible(true);
            var velocity = new Phaser.Math.Vector2();
            var velocityFromRotation = this.physics.velocityFromRotation(kart.angle * (Math.PI / 180), kart.speed + 1000, velocity);
            shell.body.velocity = velocityFromRotation;
        }
    }

    enemyBump(sprite, bumped) {

        // console.log(sprite.invinceable);

        if (sprite.invinceable) {
            bumped.enabled = false;
            bumped.hit();
            this.time.delayedCall(this.POWER_TIME, function() {

                bumped.cruising();
                bumped.enabled = true;
    
            }, [], this);
        } else if(bumped.invinceable) {
            sprite.enabled = false;
            sprite.hit();
            this.time.delayedCall(this.POWER_TIME, function() {

                sprite.cruising();
                sprite.enabled = true;
    
            }, [], this);
        } else {

        }
    }

    enemyJamed(id) {
        this.hitOil(this.enemies[id])
    }

    enemyHit(sprite) {
        if (!sprite.shellHit) {
            sprite.shellHit = true;
            sprite.enabled = false;
            sprite.hit();

            this.time.delayedCall(this.POWER_TIME, function() {

                sprite.shellHit = false;
                sprite.enabled = true;
                sprite.cruising();
    
            }, [], this);
        }
    }
}