import React, { Component } from 'react';
import './App.scss';
import {inject, observer} from 'mobx-react';
import { isBrowser, isMobile } from 'react-device-detect';
import Console from './Components/Console/Console';
import Stickies from './Components/Stickies/Stickies';
import PopUp from './Components/Application/PopUp';
import Routes from './Components/Routes/Routes';
import GameArea from './Games/GameArea';
import queryString from 'query-string';
import Desktop from './Components/Desktop/Desktop';

@inject('DataStore')
@observer
class App extends Component {

  render() {

    const {DataStore} = this.props;
    const parsed = queryString.parse(window.location.href);
    DataStore.social = parsed.social;

    DataStore.setGamerLocale(parsed.locale);
    
    /* Check if user has been here before */
    DataStore.getSigned();
    DataStore.setVisibility(false);  

    this.height = window.innerHeight;
    let vh =  this.height  * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      this.height = window.innerHeight;
      let vh = this.height * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    setTimeout(() => {
      this.height = window.innerHeight;
      let vh = this.height * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 3000);

    return (
      <div className="App">
        {isBrowser && !isMobile ? <Desktop /> : null}
        {isMobile ? <Routes /> : null }
        <GameArea />
        <Console />
        <Stickies height={this.height} />
        <PopUp datastore={DataStore} />
      </div>
    );
  }
}

export default App;
