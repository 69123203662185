import ReactPixel from 'react-facebook-pixel';

export default class Pixel {
        constructor() {
            this.advancedMatching = { em: 'monkeysfacebook@themonkeys.com.au' };
        this.options = {
            autoConfig: true,
            debug: false,
        };
        ReactPixel.init('1042185355800381', this.advancedMatching, this.options);
    }

    init(game_id, game_name, locale) {

        const PageViewEvent = {
            type: 'THROWBACK_SWIPE_UP',
            data: {
                action: 'Swipe Up',
                gameId: game_id,
                gameName: game_name,
                locale: locale
            }
        }
        ReactPixel.trackCustom( PageViewEvent.type, PageViewEvent.data ); 	
    }

    startGame(game_id, game_name, locale) {

        const PageViewEvent = {
            type: 'THROWBACK_START_GAME',
            data: {
                action: 'Start Game'
            }
        }
        ReactPixel.trackCustom( PageViewEvent.type, PageViewEvent.data ); 	
    }

    finishGame(game_id, game_name, locale, score) {

        const PageViewEvent = {
            type: 'THROWBACK_FINISH_GAME',
            data: {
                action: 'Finish Game',
                score: score
            }
        }

        ReactPixel.trackCustom( PageViewEvent.type, PageViewEvent.data ); 	
    }

    signup(game_id, game_name, locale) {

        const PageViewEvent = {
            type: 'THROWBACK_SUBMIT_APPLICATION',
            data: {
                action: 'Signup',
            }
        }
        ReactPixel.trackCustom( PageViewEvent.type, PageViewEvent.data ); 	
    }

    leaderboard(game_id, game_name, locale) {

        const PageViewEvent = {
            type: 'THROWBACK_VIEW_LEADERBOARD',
            data: {
                action: 'Leadeboard View',
            }
        }
        ReactPixel.trackCustom( PageViewEvent.type, PageViewEvent.data ); 	
    }
};
