export default class Pad {
    constructor(ctx, x, y, width, height, up, up_pressed, right_pressed, down_pressed, left_pressed, event) {
        
        this._ctx = ctx;
        this._x = x;
        this._y = y;
        this._height = height;
        this._width = width;
        
        this._up = new Image();
        this._up.src = up;

        this._up_pressed = new Image();
        this._up_pressed.src = up_pressed;

        this._right_pressed = new Image();
        this._right_pressed.src = right_pressed;

        this._down_pressed = new Image();
        this._down_pressed.src = down_pressed;

        this._left_pressed = new Image();
        this._left_pressed.src = left_pressed;

        this._direction = 0;
        this._event = null;
        this._depressed = false;

        //Load button state images
        this._image_container = [this._up, this._up_pressed, this._right_pressed, this._down_pressed, this._left_pressed];
        for (var i = 0; i < this._image_container.length; ++i) {
            this._image_container[i].onload = function () {
                if (i === this._image_container.length) {
                    this.draw();
                }
            }.bind(this);
        }
    }

    draw() {
        this._ctx.save();
        this._ctx.translate(this._x + this._width / 2, this._y + this._height / 2);
        this._ctx.globalAlpha = this._opacity;
        this._ctx.imageSmoothingEnabled = true;
        this._ctx.imageSmoothingQuality = "high"
        this._ctx.rotate(this._rotation * Math.PI / 180);
        this._ctx.translate(-this._x - this._width / 2, -this._y - this._height / 2);

        switch (this._direction) {
            case 0:
                if(this._depressed) {
                    this._depressed = false;
                    this.dispatchUp();
                }
                this._ctx.drawImage(this._up, this._x, this._y, this._width, this._height);
                break;
            case 1:
                this._depressed = true;
                this._ctx.drawImage(this._up_pressed, this._x, this._y, this._width, this._height);
                this.dispatch("up");
                break;
            case 2:
                this._depressed = true;
                this._ctx.drawImage(this._right_pressed, this._x, this._y, this._width, this._height);
                this.dispatch("right");
                break;
            case 3:
                this._depressed = true;
                this._ctx.drawImage(this._down_pressed, this._x, this._y, this._width, this._height);
                this.dispatch("down");
                break;
            case 4:
                this._depressed = true;
                this._ctx.drawImage(this._left_pressed, this._x, this._y, this._width, this._height);
                this.dispatch("left");
                break;
            default:
                break;
        }

        this._ctx.restore();
    }

    dispatch(move) {
        let event = new Event(move, { bubbles: true });
        document.dispatchEvent(event);
    }

    dispatchUp() {
        let event = new Event("dpad_up", { bubbles: true });
        document.dispatchEvent(event);
    }
};
