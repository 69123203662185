import Phaser from 'phaser';
import {CST} from '../CST';

export class SoundManagerScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.SOUND });
    }
    
    init() {
        /*  mute and unmute */
        document.addEventListener("mute", function (event) {
            this.sound.mute = true;
        }.bind(this));
        document.addEventListener("unmute", function (event) {
            this.sound.mute = false;
        }.bind(this));
    }

    create() {

        this.game = this.scene.get(CST.SCENES.GAME);

        // Audio
        // console.log('sound scene')
        this.bgm = this.sound.add('background_sound', { loop: true, volume: 0.6 });
        this.bgm.play();


        // this.super = this.sound.add('super', {volume: 0.5});
        // this.die = this.sound.add('die', {volume: 0.5});
        // this.plop = this.sound.add('bibip', {volume: 0.5});
        // this.block = this.sound.add('ding', {volume: 0.9});
        // this.toosh = this.sound.add('blip', {volume: 0.9});

        this.woosh = this.sound.add('woosh');
        this.coin = this.sound.add('coin');

        this.super = this.sound.add('super');
        this.dding1 = this.sound.add('dding1');
        this.dding2 = this.sound.add('dding2');
        this.ddong = this.sound.add('ddong');
        this.aww = this.sound.add('aww');
        this.yay = this.sound.add('yay');
        this.yum = this.sound.add('yum');
        this.nextlevel = this.sound.add('nextlevel');
        // this.ohyeah = this.sound.add('ohyeah');


        this.attack = this.sound.add('attack');



    }
    
}