import Phaser from 'phaser';
import { CST } from '../CST';
import Donut from '../Sprites/Donut';

import Chocolate from '../Sprites/Chocolate';
import Crossboss from '../Sprites/Crossboss';
import Cupcake from '../Sprites/Cupcake';
import Cookie from '../Sprites/Cookie';
import Icecream from '../Sprites/Icecream';
import PowerUp from '../Sprites/PowerUp';
import FBFTileSprite from '../Sprites/FBFTileSprite';
import MovingPlatform from '../Sprites/MovingPlatform';
import Fire from '../Sprites/Fire';

export class FBFScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.GAME });
    }

    preload() {

        // this.load.scenePlugin('animatedTiles', AnimatedTiles, 'animatedTiles', 'animatedTiles');

    }
    create(passedData) {

        /* HUD Reference */
        this.hudscene = this.scene.get(CST.SCENES.HUD);
        this.control = this.scene.get(CST.SCENES.CONTROL);

        this.showDebug = false;

        /* SETTING */

        this.passedData = passedData;

        this.isPaused = false;
        this.killAllEnemies = false;
        // this.cameras.main.setZoom(0.5); 

        /* World */


        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;

        /* Sound */

        this.sfx = {

            stomp: this.sound.add('stomp'),
            shoot: this.sound.add('shoot'),
            jump: this.sound.add('jump', { volume: 0.6 }),
            hop: this.sound.add('hop', { volume: 0.6 }),
            grow: this.sound.add('grow', { volume: 0.6 }),
            hurt: this.sound.add('hurt'),
            die: this.sound.add('die'),
            hit: this.sound.add('hit'),
            diding: this.sound.add('diding'),
            coin: this.sound.add('coin'),
            itemOut: this.sound.add('itemOut'),

        };

        /* Camera */

        this.rooms = [];

        // Add the map + bind the tileset

        this.map = this.make.tilemap({
            key: 'map' + this.passedData.level
        });

        this.tileset = this.map.addTilesetImage('fbf_tile', 'tiles');

        // Add the background as an tilesprite.
        this.bg = this.add.tileSprite(0, 0, 384, 256, 'bg' + this.passedData.level).setOrigin(0, 0).setScale(4);

        // Dynamic layer 'breakable and animated tiles'
        this.groundLayer = this.map.createDynamicLayer('world', this.tileset, 0, 0);

        this.bg.width = this.groundLayer.width;
        this.cameras.main.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels);

        // Probably not the correct way of doing this:
        this.physics.world.bounds.width = this.groundLayer.width;
        this.physics.world.TILE_BIAS = 64;

        // Set collision by property
        this.groundLayer.setCollisionByProperty({
            collide: true

        });

        // This group contains all enemies for collision and calling update-methods
        this.enemyGroup = this.add.group();

        // A group powerUps to update
        this.powerUps = this.add.group();

        this.movingPlatformGroup = this.add.group();


        this.parseObjectLayers();

        // An emitter for bricks when blocks are destroyed.
        this.blockEmitter = this.add.particles('broken_particle');
        this.blockEmitter.createEmitter({
            gravityY: 1200,
            lifespan: 2000,
            speed: 400,
            angle: {
                min: -90 - 25,
                max: -45 - 25
            },
            frequency: -1
        });

        // Impact particle
        var explodeParticleManager = this.add.particles('impact_particle'); //create particle manager
        this.explodeParticleEmitter = explodeParticleManager.createEmitter({
            lifespan: 300,
            speed: { min: 400, max: 1600 },
            gravityY: 700,
            scale: { min: 0.5, max: 2 },
            frequency: -1 //explode mode
        }); // create particle emitter
        // Sprinkle particle
        var sprinkleParticleManager = this.add.particles('sprinkle_particle'); //create particle manager
        this.sprinkleParticleEmitter = sprinkleParticleManager.createEmitter({
            lifespan: 600,
            frame: { frames: [0, 1, 2, 3, 4, 5, 6, 7] },
            speed: { min: 200, max: 800 },
            gravityY: 1600,
            angle: { min: -150, max: -30 },
            scale: { min: 0.5, max: 1.0 },
            frequency: -1 //explode mode
        }); // create particle emitter

        var impactParticleManager = this.add.particles('sprinkle_particle'); //create particle manager
        this.impactParticleEmitter = impactParticleManager.createEmitter({
            lifespan: 600,
            frame: { frames: [7, 8, 9] },
            speed: { min: 200, max: 800 },
            gravityY: 1600,
            angle: { min: -150, max: -30 },
            scale: { min: 0.8, max: 2.0 },
            frequency: -1 //explode mode
        }); // create particle emitter

        // var killParticleManager = this.add.particles('impact_particle'); //create particle manager
        // this.killParticleEmitter = killParticleManager.createEmitter({
        //     lifespan: 600,
        //     frame: { frames: [7, 8, 9] },
        //     speed: { min: 200, max: 800 },
        //     gravityY: 1600,
        //     angle: { min: -150, max: -30 },
        //     scale: { min: 0.8, max: 2.0 },
        //     frequency: -1 //explode mode
        // }); // create particle emitter





        // Used when hitting a tile from below that should bounce up.
        this.bounceTile = new FBFTileSprite({
            scene: this
        });

        // Prepare the finishLine
        let worldEndAt = -1;
        for (let x = 0; x < this.groundLayer.width; x++) {
            let tile = this.groundLayer.getTileAt(x, 2);
            if (tile && tile.properties.worldsEnd) {
                worldEndAt = tile.pixelX;
                break;
            }
        }
        this.finishLine = {
            x: worldEndAt,
            // flag: this.add.sprite(worldEndAt + 8, 4 * 16),
            active: false
        };
        // this.finishLine.flag.play('flag');

        // // If the game ended while physics was disabled
        // this.physics.world.resume();


        // Create Donut
        this.donut = new Donut({
            scene: this,
            key: 'donut',
            startState: this.passedData.startState,
            x: 64 * 5,
            y: 64 * 13
        });

        // Set bounds for current room
        this.donut.setRoomBounds(this.rooms);

        // The camera should follow Donut
        this.cameras.main.startFollow(this.donut);

        // this.cameras.main.roundPixels = true;

        if (this.passedData.level == 3) {
            this.createMovingPlatforms();

        }

        this.fireballs = this.add.group({
            classType: Fire,
            // maxSize: 20,
            runChildUpdate: false // Due to https://github.com/photonstorm/phaser/issues/3724
        });

        this.cameras.main.fadeIn(1000);

        this.debug_text = this.add.text(36, 46, '', {
            fontSize: '20px',
            fill: '#ffffff'
        }).setAlpha(0);
        this.debug_text.setScrollFactor(0);
        this.debugGraphics = this.add.graphics();
        
        this.control.bgm.rate = 1;
        if(!this.control.bgm.isPlaying) this.control.bgm.play();


    }

    createMovingPlatforms() {

        // console.log('Creating moving platforms...')

        // Create MovingP latforms
        let mp1 = new MovingPlatform({
            scene: this,
            key: 'cloud_pink',
            x: 64 * 8,
            y: 64 * 10,

            //Tiles per seconds
            velX: 64 * 3,
            velY: 64 * 0,

            hold: 2500,

            duration1: 2000,
            duration2: 2000 //return duration.
        });


        let mp2 = new MovingPlatform({
            scene: this,
            key: 'cloud_pink',
            x: 64 * 41,
            y: 64 * 14,

            //Tiles per seconds
            velX: 64 * 0,
            velY: 64 * -3.5,

            hold: 2000,

            duration1: 2000,
            duration2: 1000 //return duration.
        });


    }

    update(time, delta) {

        // TEST button
        if (this.control.keys.test.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;
                console.log("test key pressed");
                this.ladderReveal();
                // this.scene.pause();

                //
                // this.showDebug = !this.showDebug;
                // this.drawDebug();

                //
            }


        }



        Array.from(this.fireballs.children.entries).forEach(
            (fireball) => {
                fireball.update(time, delta);
            });


        if (this.physics.world.isPaused) {
            return;
        }

        if (this.donut.x > this.finishLine.x && this.finishLine.active) {
            this.ladderClimb();
            this.physics.world.pause();
            return;
        }

        // Run the update method of characters
        this.donut.update(this.control.keys, time, delta);

        // Run the update method of all enemies
        this.enemyGroup.children.entries.forEach(
            (sprite) => {
                sprite.update(time, delta);
            }
        );

        this.movingPlatformGroup.children.entries.forEach(
            (sprite) => {
                sprite.update(time, delta);
            }
        );


        // Run the update method of non-enemy sprites
        this.powerUps.children.entries.forEach(
            (sprite) => {
                sprite.update(time, delta);
            }
        );


        this.updateText();
    }

    drawDebug() {
        this.debugGraphics.clear();

        if (this.showDebug) {
            this.debug_text.alpha = 1;
            // Pass in null for any of the style options to disable drawing that component
            this.groundLayer.renderDebug(this.debugGraphics, {
                tileColor: null, // Non-colliding tiles
                collidingTileColor: new Phaser.Display.Color(243, 134, 48, 200), // Colliding tiles
                faceColor: new Phaser.Display.Color(40, 39, 37, 255) // Colliding face edges
            });
        } else {
            this.debug_text.alpha = 0;

        }

    }
    updateText() {
        this.debug_text.setText(
            // player.body.blocked.down+" .  "+player.body.blocked.left +" .  "+player.body.blocked.right + " "+Phaser.VERSION
            "Donut.body.x: " + Math.round(this.donut.body.x) + " Donut.body.y: " + Math.round(this.donut.body.y) + "  "
        );
    }
    tileCollision(sprite, tile) {



        if (sprite.type === 'donut') {
            // if (!sprite.bending) {
            // // if (!(sprite.body.velocity.y < 0 || sprite.bending)) {
            //     console.log('not hitting from below')
            //     return;
            // }
        }


        if (sprite.type === 'icecream') {
            if (tile.y > Math.round(sprite.y / 64)) {
                // Icecreams ignore the ground
                return;
            }
        }

        // If it's Donut and the body isn't blocked up it can't hit question marks or break bricks
        // Otherwise Donut will break bricks he touch from the side while moving up.
        if (sprite.type === 'donut' && !sprite.body.blocked.up) {
            return;
        }

        // If the tile has a callback, lets fire it
        if (tile.properties.callback) {
            switch (tile.properties.callback) {
                case 'questionMark':


                    // Check powerUp for what to do, make a coin if not defined
                    let powerUp = tile.powerUp ? tile.powerUp : 'coin';


                    // Shift to a 'boring' block


                    if (tile.powerUp === 'coin' || tile.powerUp === 'heart') {
                        tile.index = 17;
                    } else tile.index = 16;
                    //INDEX. gold:15, donutbox:16, brown:17


                    // Bounce it a bit
                    sprite.scene.bounceTile.restart(tile);

                    // The questionmark is no more
                    tile.properties.callback = null;

                    // Invincible blocks are only collidable from above, but everywhere once revealed
                    tile.setCollision(true);


                    // Make powerUp (including a coin)
                    (() => new PowerUp({
                        scene: sprite.scene,
                        key: 'items',
                        x: tile.x * 64 + 32,
                        y: tile.y * 64 - 32,
                        isExposed: false,
                        type: powerUp
                    }))();
                    sprite.scene.impactParticleEmitter.explode(8, tile.x * 64 + 32, tile.y * 64 + 32);


                    break;
                case 'breakable':
                    if (sprite.type === 'donut' && sprite.animSuffix === 'small') {
                        // Can't break it anyway. Bounce it a bit.
                        sprite.scene.bounceTile.restart(tile);
                        // sprite.scene.sound.playAudioSprite('sfx', 'smb_bump');
                    } else {
                        // get points
                        sprite.scene.updateScore(50);
                        sprite.scene.map.removeTileAt(tile.x, tile.y, true, true, this.groundLayer);
                        // sprite.scene.sound.playAudioSprite('sfx', 'smb_breakblock');
                        sprite.scene.blockEmitter.emitParticle(6, tile.x * 64, tile.y * 64);
                    }
                    break;

                default:
                    // sprite.scene.sound.playAudioSprite('sfx', 'smb_bump');
                    break;
            }
        } else {
            // sprite.scene.sound.playAudioSprite('sfx', 'smb_bump');
        }
    }
    ladderReveal(){
        for(let i = 13; i > 6-1; i--){
            this.map.removeTileAt(93, i, true, true, this.groundLayer);
            this.map.removeTileAt(76, i, true, true, this.groundLayer);

            this.blockEmitter.emitParticle(4, 93 * 64, i * 64);
            this.blockEmitter.emitParticle(4, 76 * 64, i * 64);

        }

        for(let j = 76; j < 93+1; j++){
            this.map.removeTileAt(j, 14, true, true, this.groundLayer);

            this.blockEmitter.emitParticle(4, j * 64, 14 * 64);

        }


    }

    ladderClimb(step = 0) {
        this.donut.alpha = 1;
        this.killAllEnemies = true;
        switch (step) {
            case 0:
                this.control.bgm.stop();
                // this.physics.world.disable(this.donut);
                this.donut.collider.destroy();

                this.donut.play('climb' + this.donut.animSuffix);
                this.donut.x = this.finishLine.x + 32;
                let destY = -160;
                if (this.passedData.level === 3) {
                    destY = 320 - 32;
                }
                let d = (this.donut.y - destY) * 2.4;
                this.tweens.add({
                    targets: this.donut,
                    y: destY,
                    delay: 300,
                    duration: 2000,
                    onComplete: () => {
                        this.ladderClimb(1);
                    }
                });
                break;
            case 1:
                if (this.passedData.level === 3) {

                    this.donut.play('run' + this.donut.animSuffix)
                    this.donut.flipX = false;

                    this.tweens.add({
                        targets: this.donut,
                        x: this.finishLine.x + 192 + 32,
                        delay: 100,
                        duration: 700,
                        onComplete: () => {
                            this.ladderClimb(2);
                        }
                    });


                } else {
                    this.ladderClimb(3);

                }


                break;

            case 2:
                if (this.passedData.level === 3) {
                    this.donut.flipX = true;
                    this.donut.play('win' + this.donut.animSuffix);

                    // this.add.sprite(64 * 95, 64 * 5, 'hotlight').setScale(4).play('hotlight');
                    let hotlight = this.add.image(64 * 95 + 4, 64 * 5 - 18, 'hotlight2').setAlpha(0.2);
                    this.tweens.add({
                        targets: hotlight,
                        alpha: 1,
                        hold: 200,
                        duration: 300,
                        yoyo:true,
                        repeat:-1
                    });

                }

                this.ladderClimb(3);

                break;

            case 3:

                // console.log('LEVEL END');
                this.hudscene.levelEnd(true);

                break;


        }
    }
    parseObjectLayers() {

        // The map has one object layer with enemies as stamped tiles,
        // each tile has properties containing info on what enemy it represents.
        this.map.getObjectLayer('enemies').objects.forEach(
            (enemy) => {
                let enemyObject;
                switch (this.tileset.tileProperties[enemy.gid - 1].name) {
                    case 'crossboss':
                        enemyObject = new Crossboss({
                            scene: this,
                            key: 'crossboss',
                            x: enemy.x + 32,
                            y: enemy.y - 32
                        });
                        break;
                    case 'chocolate':
                        enemyObject = new Chocolate({
                            scene: this,
                            key: 'chocolate',
                            x: enemy.x + 32,
                            y: enemy.y - 32
                        });
                        break;
                    case 'cupcake':
                        enemyObject = new Cupcake({
                            scene: this,
                            key: 'cupcake',
                            x: enemy.x + 32,
                            y: enemy.y - 32
                        });
                        break;
                    case 'cookie':
                        enemyObject = new Cookie({
                            scene: this,
                            key: 'cookie',
                            x: enemy.x + 32,
                            y: enemy.y - 32
                        });
                        break;
                    case 'icecream':
                        enemyObject = new Icecream({
                            scene: this,
                            key: 'icecream',
                            x: enemy.x + 32,
                            y: enemy.y - 32
                        });
                        break;
                    default:
                        console.error('Unknown:', this.tileset.tileProperties[enemy.gid - 1]); // eslint-disable-line no-console
                        break;
                }
                this.enemyGroup.add(enemyObject);
            }
        );

        // The map has an object layer with 'modifiers' that do 'stuff', see below
        this.map.getObjectLayer('modifiers').objects.forEach((modifier) => {
            let tile, properties, type;
            // Get property stuff from the tile if present or just from the object layer directly
            //
            if (typeof modifier.gid !== 'undefined') {
                properties = this.tileset.tileProperties[modifier.gid - 1];
                type = properties.type;
                if (properties.hasOwnProperty('powerUp')) {
                    type = 'powerUp';
                }

            } else {
                type = modifier.properties.type;
            }
            // console.log(modifier, type);
            //

            switch (type) {
                case 'powerUp':
                    tile = this.groundLayer.getTileAt(modifier.x / 64, modifier.y / 64 - 1);

                    if (tile == null) {
                        // powerUp item is not hidden in the tile. It is exposed.
                        // any item applicable but coins preferred.
                        // Make powerUp (including a coin)

                        (() => new PowerUp({
                            scene: this,
                            key: 'items',
                            x: modifier.x + 32,
                            y: modifier.y - 32,
                            isExposed: true,
                            type: properties.powerUp
                        }))();



                    }
                    //
                    else {
                        // console.log(tile);
                        tile.powerUp = properties.powerUp;
                        tile.properties.callback = 'questionMark';

                        if (!tile.collides) {
                            // Hidden block without a question mark
                            tile.setCollision(false, false, false, true);
                        }

                    }
                    break;

                case 'room':
                    this.rooms.push({
                        x: modifier.x,
                        width: modifier.width,
                        sky: modifier.properties.sky
                    });
                    break;
            }
        });
    }

}