import Phaser from 'phaser';
import { InitScene } from './Scenes/InitScene';
import { PreloadScene } from './Scenes/PreloadScene';
import { ControlScene } from './Scenes/ControlScene';
import { ThrowbackTitleScene } from './Scenes/ThrowbackTitleScene';
import { FighterbunTitleScene } from './Scenes/FighterbunTitleScene';
import { FighterbunScene } from './Scenes/FighterbunScene';
import { InstructionScene } from './Scenes/InstructionScene';
import { VSScene } from './Scenes/VSScene';
import { HUD } from './Scenes/HUD';
import { IntermissionScene } from './Scenes/IntermissionScene';
import { ScoreScene } from './Scenes/ScoreScene';

export default class Fighter {

    constructor() {

        const STAGE_PADDING = 60;
        const STAGE_WIDTH = (window.innerWidth - STAGE_PADDING);
        const STAGE_HEIGHT = STAGE_WIDTH;
        const STAGE_COLOUR = "#5D5D5D";
        const CANVAS = "CanvasWrapper";
        const GAME_CONFIG = {
            type: Phaser.AUTO,

            scale: {
                parent: CANVAS,
                mode: Phaser.Scale.FIT,
                width: 660,
                height: 660
            },

            backgroundColor: STAGE_COLOUR,
            render: {
                pixelArt: true   

            },
            fps: {
                // target: 15,
            },
            autoFocus: true,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: {
                        y: 3200
                    },
                    // debug: true,
                    // Physics world is automatically aligned to top,
                    // so there's no need to add y offset.
                    // y:-60,

                    width: 960,
                    height: 620 // 720 - 100
                }
            },
            // debug: true,
            
            // Physics world is automatically aligned to top,
            // so there's no need to add y offset.
            // y:-60,

        scene: [
        InitScene,
        PreloadScene,
        ControlScene,
        ThrowbackTitleScene,
        FighterbunTitleScene,
        InstructionScene,
        VSScene,
        FighterbunScene, 
        HUD,
        IntermissionScene,
        ScoreScene
        ]
        }

        var game = new Phaser.Game(GAME_CONFIG);


    }
}