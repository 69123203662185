export default function makeAnimations(scene) {
    // TONS of animations. Everything animation-related is ugly and stupid below.
    // TODO:  maybe use JSON to load animations

    var char3_name = 'fingerbun_v2';
    var char4_name = 'enemybun_v2';

    var char_base = [

        //==========
        {
            key: "temp",
            frameRate: 30,
            frameData: 0
        },

        {
            key: "idle",
            repeat: -1,
            frameRate: 8,
            frameData: [0, 0, 1, 2, 3, 4, 4, 5, 6, 7]
        },

        {
            key: "jump",
            repeat: -1,
            frameRate: 1,
            frameData: [8]
        },

        {
            key: "crouch",
            repeat: -1,
            frameRate: 1,
            frameData: [9]
        },

        {
            key: "moveF",
            repeat: -1,
            frameRate: 12,
            frameData: [10, 11, 12, 13, 14]
        },

        {
            key: "moveB",
            repeat: -1,
            frameRate: 12,
            frameData: [15, 16, 17, 18, 19]
        },

        {
            key: "hurt1",
            frameRate: 15,
            frameData: [20, 21, 21]
        },

        {
            key: "hurt2",
            frameRate: 6,
            frameData: [22, 23, 23]
        },

        {
            key: "dizzy",
            frameRate: 4,
            frameData: [24, 25, 26, 25, 24, 25, 26, 25]
        },

        {
            key: "block",
            repeat: -1,
            frameRate: 12,
            frameData: [27]
        },
{
            key: "blockshake",
            frameRate: 18,
            frameData: [28, 28, 27, 28, 27]
        },


        {
            key: "falling",
            frameRate: 1,
            frameData: [30, 30, 30, 30, 30, 30, 30, 30, 30, 30]
        },

        {
            key: "fallen",
            frameRate: 8,
            frameData: [31, 32, 33, 33, 33, 33]
        },

        {
            key: "wakeup",
            frameRate: 10,
            frameData: [34, 35, 36, 37, 38, 39, 0]
        },


        {
            key: "attack11",
            frameRate: 16,
            frameData: [40, 41, 42, 40, 0]
        },

        {
            key: "attack12",
            frameRate: 16,
            frameData: [43, 44, 45, 43, 0]
        },

        {
            key: "attack21",
            frameRate: 16,
            frameData: [50, 51, 52, 53]
        },

        {
            key: "attack22",
            frameRate: 16,
            frameData: [54, 55, 56, 57]
        },

        {
            key: "attack31",
            frameRate: 3,
            frameData: [47, 46, 8]
        },

        {
            key: "attack32",
            frameRate: 3,
            frameData: [49, 48, 8]
        },

        {
            key: "attack51",
            frameRate: 20,
            frameData: [70, 71, 70, 71, 70, 71, 70, 71, 74, 72, 73, 72, 73, 72, 73, 72, 73, 72, 73, 72, 73]
        },

        {
            key: "attack52",
            frameRate: 20,
            frameData: [0, 75, 76, 77, 78, 79, 78, 79, 78, 79, 78, 8, 8, 8]
        },

        {
            key: "attack61",
            frameRate: 20,
            frameData: [80, 81, 81, 81, 82, 83, 84, 84, 1, 81, 81, 82, 83, 84, 84, 81, 81]
        },

        {
            key: "attack62",
            frameRate: 20,
            frameData: [85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 85, 86, 87, 88, 89, 89]
        },

        {
            key: "win",
            repeat: -1,
            frameRate: 10,
            frameData: [90, 91, 91, 90, 94, 92, 93, 93, 92, 94]
        }



    ];

    //=====
    var instructionDemo = [

        {
            key: "pose_1",
            repeat: -1,
            frameRate: 8,
            frames: scene.anims.generateFrameNumbers(char3_name, {
                frames: [70, 71, 70, 71, 73, 72, 73, 72, 73, 72, 74, 74]
            })
        },

        {
            key: "pose_2",
            repeat: -1,
            frameRate: 8,
            frames: scene.anims.generateFrameNumbers(char3_name, {
                frames: [75, 76, 77, 78, 79, 78, 79, 78, 8, 8, 8, 0, 0]
            })
        },

        {
            key: "pose_3",
            repeat: -1,
            frameRate: 8,
            frames: scene.anims.generateFrameNumbers(char3_name, {
                frames: [80, 81, 81, 82, 83, 84, 84, 0, 0]
            })
        },

        {
            key: "pose_4",
            repeat: -1,
            frameRate: 8,
            frames: scene.anims.generateFrameNumbers(char3_name, {
                frames: [85, 86, 87, 88]
            })
        },

    ];
    instructionDemo.forEach(function(elem) {

        scene.anims.create(elem);

    });

    //====
    var clone = function(obj) {
        var copy;

        if (null == obj || "object" != typeof obj) return obj;

        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = clone(obj[i]);
            }
            return copy;
        }

        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    };



    //====


    var char3 = clone(char_base);
    var char4 = clone(char_base);

    char3.forEach(function(elem) {
        elem.key = 'char3_' + elem.key;
        elem.frames = scene.anims.generateFrameNumbers(char3_name, {
            frames: elem.frameData
        });

        scene.anims.create(elem);

    });

    char4.forEach(function(elem) {
        elem.key = 'char4_' + elem.key;
        elem.frames = scene.anims.generateFrameNumbers(char4_name, {
            frames: elem.frameData
        });

        scene.anims.create(elem);

    });


    //==========
    // Other animations
    //==========


    scene.anims.create({
        key: "bg_animation",
        frameRate: 3,
        repeat: -1,
        frames: scene.anims.generateFrameNumbers('screen_bg', {

            frames: [0, 1]

        })

    });
    scene.anims.create({
        key: "pow",
        frameRate: 30,
        frames: scene.anims.generateFrameNumbers('impact', {

            frames: [0, 1, 2, 3]

        })

    });

    scene.anims.create({
        key: "sparking",
        repeat: -1,
        frameRate: 15,
        frames: scene.anims.generateFrameNumbers('spark', {

            frames: [0, 1, 2, 3]

        })

    });

    scene.anims.create({
        key: "beam_fire",
        // repeat: -1,
        frameRate: 20,
        frames: scene.anims.generateFrameNumbers('beam', {
            frames: [0, 0, 1, 2, 3, 1, 2, 3, 1, 0, 0]

        })

    });


    //==========



    //==========




}