import Phaser from 'phaser';
import { CST } from '../CST';

export class HelpScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.HELP });
    }

    init(data) {
        this.user_understand = false;
        this.active = false;
        this.SCREEN_TIMER = 1000;
        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;
    }

    create() {
        
        this.anims.create({
            key: "bg_animation",
            frameRate: 3,
            repeat: -1,
            frames: this.anims.generateFrameNumbers('instructions', {
                frames: [0, 1]
            })
        });

        this.mainImage = this.add.sprite(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H, 'instructions').setScale(2);
        this.mainImage.anims.play("bg_animation");
    }
}