import Phaser from 'phaser';
import {
  CST
} from '../CST';

export class ThrowbackTitleScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.THROWBACK_TITLE
    });
  }

  init(data) {
    this.SCREEN_TIMER = 3000;

    document.addEventListener("mute", function(event) {
      this.game.sound.mute = true;
    }.bind(this));

    document.addEventListener("unmute", function(event) {
      this.game.sound.mute = false;
    }.bind(this));
  }

  preload() {

  }

  create() {
    const background = this.add.graphics();
    background.fillStyle(0x000000, 1);
    background.fillRect(0, 0, 660, 660);
    //Add stars
    this.particles = this.add.particles('particle');

    this.emitter = this.particles.createEmitter({
      x: 330,
      y: 330,
      bounce: true,
      lifespan: 1000,
      alpha: 0.6,
      speed: {
        min: 300,
        max: 360
      },
      gravityY: 0,
      scale: {
        start: 1,
        end: .7
      },
      quantity: 1,
      blendMode: 'ADD',
      timeScale: 0.7
    });

    this.mainImage = this.add.image(330, 330, 'preloader');
    this.add.text(330 - 40, 660 - 110, 'PRESENTS...', {
      fontFamily: 'VT323',
      color: '#41b1e4'
    });

    this.bgm = [this.game.sound.add('background_sound', {
      loop: true,
      volume: 0.4
    })];
    this.bgm[0].play();

    setTimeout(() => {
      this.understood();
    }, this.SCREEN_TIMER);
  }

  understood() {
    this.scene.start(CST.SCENES.KRISPYKART_TITLE, 'Krispy Kart Title Scene');
  }
}
