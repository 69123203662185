import React, { Component } from 'react'
import Brownie from './Brownie';
import DataStore from '../../Components/Store/DataStore';

export default class BrownieComponent extends Component {
    render(props) {
        DataStore.game_id = 4;
        DataStore.game_name = "Doughnut Bash";
        DataStore.initPixel();
        let brownie = new Brownie();
        return (
            <div></div>
        )
    }
}
