import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Fighterbun from '../../Games/Fighterbun/FighterbunComponent';
import Kart from '../../Games/Kart/KartComponent';
import Fairybread from '../../Games/Fairybread/FairybreadComponent';
import Brownie from '../../Games/Brownie/BrownieComponent';

const Routes = () => {

  return (
    <Switch>
        <Route exact path="/kart" component={Kart} /> 
        <Route exact path="/fighter" component={Fighterbun} />
        <Route exact path="/fantasy" component={Fairybread} />
        <Route exact path="/bash" component={Brownie} />
    </Switch>
  );
};

export default Routes;
