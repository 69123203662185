import React, { Component } from 'react'

export class Header extends Component {
  render(props) {
    return (
      <header className={`krispy_kreme_header`}>  
        <a href={this.props.url} target="_blank">
          <img src={`/score/krispy_kreme_logo.png`} alt="Krispy Kreme" />
        </a>
      </header>
    )
  }
}

export default Header
