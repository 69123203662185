import Phaser from 'phaser';

export default class Coin extends Phaser.GameObjects.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.scene = config.scene;

        config.scene.anims.create({
            key: 'spin',
            frames: [
                { key: 'coin_front' },
                { key: 'coin_mid' },
                { key: 'coin_side', duration: 50 }
            ],
            frameRate: 10,
            repeat: -1
        });
        this.play('spin');
        // this.x = 
        this.body.gravity.y = Phaser.Math.Between(150, 250);
        this.scene.soundscene.coin.play();
        this.scene.physics.add.collider(this, this.scene.box, this.hitCoin, null, this);

    }


    hitCoin() {
        this.scene.box.fourPack();
        // this.scene.doughnutCollider.overlapOnly = true;
        
        // this.scene.boxBonus = true;
        this.scene.soundscene.coin.play();
        this.destroy();

    }

};