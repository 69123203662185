import Phaser from 'phaser';
import { CST } from '../CST';

export class HUD extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.HUD, active: false });

    }

    create(passedData) {

        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;


        this.MAX_DISPLAY_TIME = 300;
        this.MAX_REAL_TIME = 160;

        this.prevLevelScore = passedData.score;
        this.pauseTime = true;

        this.passedData = passedData;
 

        /* Game Reference */
        this.gamescene = this.scene.get(CST.SCENES.GAME);

        this.hudText = {
            lifeTO: this.add.bitmapText(16, 16, 'HalfBoldPixel-7', 'PLAYER X ' + this.passedData.life, 24).setOrigin(0, 0),
            scoreTO: this.add.bitmapText(16, 38, 'HalfBoldPixel-7', ('00000000' + this.passedData.score.base).slice(-8), 24).setOrigin(0, 0),
            coinImg: this.add.image(260, 16, 'coin').setOrigin(0, 0),
            coinTO: this.add.bitmapText(280, 16, 'HalfBoldPixel-7', ' X ' + this.passedData.coin, 24).setOrigin(0, 0),
            timeTextTO: this.add.bitmapText(this.SCREEN_W - 16, 16, 'HalfBoldPixel-7', 'LEVEL ' + this.passedData.level + ' TIME', 24).setOrigin(1, 0),
            timeTO: this.add.bitmapText(this.SCREEN_W - 16, 38, 'HalfBoldPixel-7', this.MAX_DISPLAY_TIME+'', 24).setOrigin(1, 0),
            time: this.MAX_REAL_TIME * 1000,
            displayedTime: this.MAX_DISPLAY_TIME,
            hurry: false
        };


        this.time.delayedCall(500, function() {

            this.pauseTime = false;

        }, [], this);


    }



    update(time, delta) {

        if (this.pauseTime == false) {
            this.updateTimer(delta);
        }

    }



    updateItemHUD(type) {
        let s = 0;
        switch (type) {
            case 'coin':
                this.updateCoin(1);
                s = 1000;
                break;
            case 'choctop':
                s = 3000;
                break;
            case 'rainbow':
                s = 5000;
                break;
            case 'str':
                s = 10000;
                break;
            case 'heart':
                this.updateLife(1);
                this.passedData.hadHeart[this.passedData.level - 1] = true;
                // console.log(this.passedData.hadHeart);
                s = 10000;
                break;
        }
        this.updateScore(s);

    }

    updateEnemyKillHUD(type) {
        this.passedData.enemyKilled += 1;
        let s = 0;
        switch (type) {
            case 'chocolate':
                s = 2000;
                break;
            case 'cookie':
                s = 5000;
                break;
            case 'icecream':
                s = 8000;
                break;
            case 'cupcake':
                s = 10000;
                break;
            case 'crossboss':
                // s = 15000;
                break;
        }
        this.updateScore(s);

    }
    updateLife(a) {
        this.passedData.life += a;
        this.hudText.lifeTO.setText('PLAYER X ' + this.passedData.life);

    }
    updateCoin(a) {
        this.passedData.coin += a;
        this.hudText.coinTO.setText(' X ' + this.passedData.coin);

    }
    updateScore(a) {
        this.passedData.score.base += a;

        let scoreStr = ('00000000' + this.passedData.score.base).slice(-8);
        this.hudText.scoreTO.setText(scoreStr);

    }
    updateTimer(delta) {
        if(this.hudText.time === 0){
            return;
        }

        if(this.hudText.displayedTime == 50){
            if(!this.hudText.hurry){
                // this.gamescene.sfx.hurry.play();
                this.gamescene.control.bgm.rate = 1.2;
                this.hudText.hurry = true;
                this.hudText.timeTextTO.setText('LEVEL ' + this.passedData.level + ' HURRY!');


            }
            
        }
        if (this.hudText.displayedTime >= 1) {

            this.hudText.time -= delta;
            var remainingSec = (this.hudText.time / 1000 / this.MAX_REAL_TIME) * this.MAX_DISPLAY_TIME;
            this.hudText.displayedTime = Math.round(remainingSec);

            // Time over

        } else {
            this.hudText.time = 0;
            this.hudText.displayedTime = 0;
            this.gamescene.donut.die();

        }
        // this.hudText.textObject_black.setText('' + this.hudText.displayedTime);
        this.hudText.timeTO.setText('' + this.hudText.displayedTime);
    }
    levelEnd(isCompleted) {

        this.pauseTime = true;

        this.passedData.score.timeBonus += isCompleted ? this.hudText.displayedTime * 1000 : 0;
        this.passedData.score.completionBonus += isCompleted ? 40000 : 0;
        this.passedData.score.lifeBonus = this.passedData.life * 10000;
        this.passedData.score.enemyBonus = this.passedData.enemyKilled * 10000;
        this.passedData.score.total = this.passedData.score.timeBonus + this.passedData.score.completionBonus + this.passedData.score.lifeBonus + this.passedData.score.enemyBonus;


        this.passedData.startState = this.gamescene.donut.animSuffix;

        this.passedData.level += isCompleted ? 1 : 0;
        let d = 2.5*1000;
        if(this.passedData.level > 3) d = 9*1000;
        this.time.delayedCall(d, function() {

            this.scene.stop(CST.SCENES.GAME);
            this.scene.start(CST.SCENES.INTERMISSION, this.passedData);

        }, [], this);

    }

};