import Phaser from 'phaser';
import { CST } from '../CST';

export class HUD extends Phaser.Scene {

    constructor(config) {
        super({ key: CST.SCENES.HUD, active: false });
    }

    create() {
        /* Game Reference */
        this.game = this.scene.get(CST.SCENES.GAME);

        this.lives = 3;
        this.WIDTH = 660;
        this.HEIGHT = 660;

        this.current_super = 0;
        this.MAX_SUPER = 1000;
        this.SUPER_INCREASE = 140
        this.SUPER_DECREASE = 0.1;

        this.extraHeart = 0;

        this.isPaused = false;

        this.anims.create({
            key: "super_full",
            frameRate: 20,
            repeat: -1,
            frames: this.anims.generateFrameNumbers('super_full', {
                start: 0,
                end: 6
                // frames: [0, 1, 2, 3, 4, 5, 6]
            })
        });

        this.level_screen = {
            bg: this.add.image(330, 330 + 25 - 660, 'level_up_background').setAlpha(1),
            level: this.add.image(330, 330 - 50 - 660, 'level_x').setAlpha(1),
            numbers: this.add.sprite(330, 330 + 80 + 660, 'numbers').setScale(4).setAlpha(1)
        }


        this.player_stat = {
            frame: this.add.image(330, 330, 'frame_brown'),

            base: this.add.image(145, 30, 'super_bar').setOrigin(0.5, 0.5),
            super: this.add.image(12, 30, 'super').setOrigin(0.0, 0.5).setScale(0, 1),
            super_full: this.add.sprite(12, 30, 'super_full').setOrigin(0.0, 0.5).setScale(4).play('super_full').setAlpha(0),
            top: this.add.image(12, 30, 'bar_top').setOrigin(0, 0.5),

            heart: [this.add.image(this.WIDTH - 22, 30, 'live_left'),
                this.add.image(this.WIDTH - 58, 30, 'live_left'),
                this.add.image(this.WIDTH - 94, 30, 'live_left')
            ],
            level_text: this.add.bitmapText(486, 15, 'HalfBoldPixel-7', `L${this.game.currentLevel}`, 30, 2),
            score: this.add.bitmapText(468, 15, 'HalfBoldPixel-7', '00000000', 30, 1).setOrigin(1, 0),
        }

        // this.player_stat.super.scaleX = (this.current_super / this.MAX_SUPER);


        this.score_number = 0;
        this.player_stat.score.tint = 0xF3A7C0;
        this.player_stat.level_text.tint = 0xF3A7C0;





    }

    update() {
        if (this.isPaused) {
            return;
        }
        this.current_super -= this.SUPER_DECREASE;
        if (this.current_super < 0) this.current_super = 0;
        this.player_stat.super.scaleX = (this.current_super / this.MAX_SUPER);
    }

    nextLevel() {

        this.player_stat.level_text.setText(`L${this.game.currentLevel}`);
        this.level_screen.bg.setAlpha(1);
        this.level_screen.level.setAlpha(1);
        this.level_screen.numbers.setAlpha(1).setFrame(this.game.currentLevel - 1);

        this.tweens.timeline({

            ease: 'Power2',
            duration: 300,

            tweens: [{
                    targets: this.level_screen.bg,
                    y: 330 + 25,
                    offset: 0,
                },
                {
                    targets: this.level_screen.level,
                    y: 330 - 50,
                    offset: 200
                },
                {
                    targets: this.level_screen.numbers,
                    y: 330 + 80,
                    offset: 250
                },

                //
                {
                    targets: this.level_screen.numbers,
                    y: 330 + 80 + 660,
                    offset: 1200
                },

                {
                    targets: this.level_screen.level,
                    y: 330 - 50 - 660,
                    offset: 1250
                },

                {
                    targets: this.level_screen.bg,
                    y: 330 + 25 - 660,
                    offset: 1500,
                }

            ]

        });

        this.isPaused = true;
    }

    lifeLost() {
        this.isPaused = true;
        if (this.lives === 0) {
            this.game.end(this.score_number);
        } else {

        }
        this.lives -= 1;
        this.updateLives();
    }

    isNotPaused() {
        this.isPaused = false;
    }

    lifeGained() {
        if (this.lives < 3) {
            this.lives += 1;
            this.updateLives();
        }
        else {
            this.extraHeart++;

        }
    }

    endSuper() {
        this.current_super = 0;
        this.player_stat.super_full.setAlpha(0);
    }

    updateSuper() {
        //console.log('entering update super')
        if (this.game.doughnut_group.countActive() !== 0) {

            this.current_super += this.SUPER_INCREASE;

        //console.log('update super, increase to',this.current_super, 'is this paused?', this.isPaused)


        }
        if (this.current_super >= this.MAX_SUPER) {
            this.current_super = 0;
            this.player_stat.super_full.setAlpha(1);
            this.game.enterSuperMode();
        }
    }

    updateLives() {

        for (let i = 0; i < 3; i++) {
            this.player_stat.heart[i].setAlpha(0);
        }

        for (let i = 0; i < this.lives; i++) {
            this.player_stat.heart[i].setAlpha(1);
        }


    }

    updateScore(data) {
        if(this.game.box.isOnFourPack){
            // console.log('x4 SCORE');
            data *= 4;
        }
        this.score_number += data;
        this.player_stat.score.setText(('00000000' + this.score_number).slice(-8));


    }
};