import Phaser from 'phaser';

export default class PowerUp extends Phaser.GameObjects.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.add.existing(this);
        this.scene = config.scene;

        this.scene.physics.world.enable(this);
        this.type = config.type;
        this.isExposed = config.isExposed;
        this.direction = (Phaser.Math.Between(0, 1) - 0.5) * 2 * 80;

        if (this.scene.donut) {
            if (this.scene.donut.x > config.x + 32) {
                // Donut on the right -> powerup bounces left
                this.direction = -this.direction;
            }
        }
        this.body.velocity.x = this.direction;

        // Standard sprite is 64x64 pixels with a smaller body
        this.body.setSize(12, 18);
        this.setScale(4);


        // this.body.offset.set(2, 4);
        //KEEP THIS
        //If donut is already super/mega, rainbow will spawn in place of choctop 
        if (this.type === 'choctop' && this.scene.donut && this.scene.donut.animSuffix !== 'small') {
            this.type = 'rainbow';
        }

        if (this.type === 'heart') {
            //console.log(this.scene.hudscene);
            if (this.scene.passedData.hadHeart[this.scene.passedData.level - 1] == true) {
                this.type = 'coin';
            }
        }
    

    if (this.isExposed) {
        this.direction = 0;
        this.body.setVelocity(this.direction, 0);
        this.body.allowGravity = false;
    }
    //
    else {


        if (this.type === 'choctop' || this.type === 'heart') {
            // this.direction = 80;
            this.body.setVelocity(this.direction, -450);

        }
        //
        else if (this.type === 'rainbow' || this.type === 'coin') {
            this.direction = 0;
            this.body.setVelocity(this.direction, -450);
            // this.body.setDrag(20,0);


        }

        this.scene.sfx.itemOut.play();

    }


    this.anims.play(this.type);


    this.scene.powerUps.add(this);

    this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer);


    //    this.scene.updateLoop.push(this);
    return this;
}

update() {
    //destroy 1 frame after it turns invisible.
    if (this.alpha === 0) {
        this.scene.powerUps.remove(this);
        this.destroy();
        return;
    }

    // this.scene.physics.world.collide(this, this.scene.groundLayer);
    this.scene.physics.world.overlap(this, this.scene.donut, this.collected);

    if (!this.body) {
        return;
    }

    if (this.body.velocity.x === 0) {
        this.direction = -this.direction;
        this.body.velocity.x = this.direction;
    }
    if (this.type === 'star') {
        if (this.body.blocked.down) {
            this.body.velocity.y = -300;
        }
    }
}

collected(powerUp, donut) {
    if (donut.animSuffix === 'small' && powerUp.type === 'rainbow') {
        // powerUp.type = 'choctop';
        powerUp.type = 'rainbow';

    }
    if (donut.animSuffix === 'mega' && powerUp.type === 'choctop') {
        // powerUp.type = 'choctop';
        powerUp.type = 'rainbow';

    }


    switch (powerUp.type) {
        case 'rainbow':
            donut.becomeMega();

            powerUp.scene.sfx.grow.play();

            break;
        case 'choctop':

            donut.resize(true);

            powerUp.scene.sfx.grow.play();

            break;
        case 'star':
            donut.star.active = true;
            donut.star.timer = 10000;
            // powerUp.scene.sound.playAudioSprite('sfx', 'smb_powerup');
            powerUp.scene.sfx.grow.play();

            break;
        case 'heart':
            powerUp.scene.sfx.diding.play();

            break;
        case 'coin':
            powerUp.scene.sfx.coin.play();

            break;
    }
    powerUp.scene.hudscene.updateItemHUD(powerUp.type);
    // get points
    // powerUp.scene.updateScore(1000);
    powerUp.alpha = 0;
}
}