import Phaser from 'phaser';
import {CST} from '../CST';

export class EndScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.END });
    }
    
    create(passedData) {

        // this.mainImage = this.add.image(330,330,'screen_ending').setScale(3);
        
        // this.control = this.scene.get(CST.SCENES.CONTROL);
        this.passedData = passedData;
        this.understood();    

    }

    update() {


    }

    understood() {
        // this.scene.start(CST.SCENES.VS, this.keys);
        // console.log('endScene',this.passedData);
        this.scene.start(CST.SCENES.SCORE, this.passedData);

    }
    pressAnyKey(){
        this.input.keyboard.on('keydown', function (event) {
            this.understood();
        }, this);

    }
}