import Phaser from 'phaser';
export default class Donut extends Phaser.GameObjects.Sprite {
    // export default class Donut extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.ACCEL = 1800;
        this.MAX_VELOCITY_X = 400;
        this.MAX_VELOCITY_Y = 1600;
        this.JUMP_VELOCITY = 600;
        this.JUMP_MAX_TIMER = 400;
        this.JUMP_MAX_REST_TIMER = 10;

        // this.animSuffix = 'small';
        this.animSuffix = config.startState;

        this.prefix = config.prefix;
        this.enemyPrefix = config.enemyPrefix;

        this.body.maxVelocity.x = this.MAX_VELOCITY_X;
        this.body.maxVelocity.y = this.MAX_VELOCITY_Y;

        this.bending = false;
        this.wasHurt = -1;
        this.flashToggle = false;
        this.star = {
            active: false,
            timer: -1,
            step: 0
        };

        this.anims.play('standsmall');
        this.alive = true;
        this.type = 'donut';
        this.jumpTimer = 0;
        this.jumpRestTimer = 0;
        this.jumping = false;
        this.fireCoolDown = 0;
        this.onAir = false;
        this.isOnMovingPlatform = false;
        this.platformRiding = null;
        this.isControllable = true;

        this.on('animationcomplete', () => {
            if (
                this.anims.currentAnim.key === 'growsmall' ||
                this.anims.currentAnim.key === 'growbig' ||
                this.anims.currentAnim.key === 'growmega' ||
                this.anims.currentAnim.key === 'hurt'
            ) {
                this.scene.physics.world.resume();
            } else if (
                this.anims.currentAnim.key === 'attack'

            ) {
                this.play('standmega');
            }
        }, this);

        this.setOrigin(0.5);
        this.setScale(4);

        if(this.animSuffix == 'small'){
            this.small();
        }//
        else {
            this.large();
        }

        this.collider = this.scene.physics.add.collider(this, this.scene.groundLayer, this.scene.tileCollision);
        // Moving Platform Collider
        this.mpCollider = this.scene.physics.add.collider(this, this.scene.movingPlatformGroup, this.rideMovingPlatform);



    }

    rideMovingPlatform(donut, platform) {

        if (donut.body.touching.down) {
            donut.body.velocity.y = 0;
            donut.isOnMovingPlatform = true;
            donut.platformRiding = platform;
        }


    }

    isOnTileOrPlatform(){
        // console.log('is on land');
        
        return (this.body.blocked.down || this.isOnMovingPlatform)
        // return (this.body.blocked.down || this.body.touching.down)

    }
    update(keys, time, delta) {

        // console.log("..");
        if (this.y > 3096) {
            // Really superdead, has been falling for a while.

        }
        //
        else if (this.y > 2048 && this.alive) {
            this.die();
        }



        if (!this.alive) {
            return;
        }

        // if(this.body.blocked.down){
        //      this.jumpRestTimer += delta;
        //  }

        this.fireCoolDown -= delta;


        // Just run callbacks when hitting something from below or trying to enter it
        // if (this.body.velocity.y < 0 || this.bending) {
        //     this.scene.physics.world.collide(this, this.scene.groundLayer, this.scene.tileCollision);
        // }
        // //
        // else {
        //     this.scene.physics.world.collide(this, this.scene.groundLayer);
        // }




        if (this.wasHurt > 0) {
            this.wasHurt -= delta;
            this.flashToggle = !this.flashToggle;
            this.alpha = this.flashToggle ? 0.2 : 1;
            if (this.wasHurt <= 0) {
                this.alpha = 1;
            }
        }

        if (this.star.active) {
            if (this.star.timer < 0) {
                this.star.active = false;
                this.tint = 0xFFFFFF;
            } else {
                this.star.timer -= delta;
                this.star.step = (this.star.step === 5) ? 0 : this.star.step + 1;
                this.tint = [0xFFFFFF, 0xFF0000, 0xFFFFFF, 0x00FF00, 0xFFFFFF, 0x0000FF][this.star.step];
            }
        }

        var controlPressed = { direction: "none", button: "none" };
        //
        controlPressed = this.humanControl(keys, time, delta);
        this.controlToAction(controlPressed, delta);
        // reset moving platform flags
        this.isOnMovingPlatform = false;
        this.platformRiding = null;

    }

    controlToAction(controlPressed, delta) {

        // if(this.body.blocked.down){
        //     console.log('blocked down')
        // }
        // else console.log(this.body.blocked);

        if (controlPressed.button == 'fire' &&
            this.animSuffix === 'mega' &&
            this.fireCoolDown < 0
        ) {
            this.scene.sprinkleParticleEmitter.explode(8, this.x, this.y);

            // if(controlPressed.direction == 'up')
            let fireball = this.scene.fireballs.get(this);
            if (fireball) {
                //sparkle
                fireball.fire(this.x, this.y, this.flipX, controlPressed.direction == 'up');
                this.fireCoolDown = 400;
            }
            this.anims.play('attack');
        }


        // falling
        if (this.body.velocity.y > 0) {
            this.hasFalled = true;
        }

        this.bending = false;

        this.jumpTimer -= delta;

        if (controlPressed.direction == 'left') {

            if (this.body.velocity.y === 0) {
                // ground?
                this.run(-this.ACCEL);
            } // 
            else {
                //air
                this.run(-this.ACCEL / 3);
            }
            this.flipX = true;
        }
        //
        else if (controlPressed.direction == 'right') {


            if (this.body.velocity.y === 0) {
                // ground?
                this.run(this.ACCEL);
            } else {
                //air
                this.run(this.ACCEL / 3);
            }
            this.flipX = false;
        }
        // no left/right input, on ground
        else if (this.isOnTileOrPlatform()) {
            if (Math.abs(this.body.velocity.x) < 20) {
                this.body.setVelocityX(0);
                this.run(0);
            } else {
                this.run(((this.body.velocity.x > 0) ? -1 : 1) * this.ACCEL / 2);
            }
        }
        // no left/right input, not on ground
        else if (!this.isOnTileOrPlatform()) {
            this.run(0);
        }

        if (controlPressed.button == 'jump') {


            if (!this.jumping) {
                if (this.jumpRestTimer >= this.JUMP_MAX_REST_TIMER) {
                    this.jump();
                    this.jumpRestTimer = 0;
                }

            } //
            else if (this.jumpTimer > 0) {
                this.jump();
                this.jumpRestTimer = 0;

            }





        } else if (controlPressed.button != 'jump') {
            this.jumpTimer = -1; // Don't resume jump if button is released, prevents mini double-jumps
            if (this.isOnTileOrPlatform()) {
                this.jumping = false;

                if (this.jumpRestTimer === 0 && this.animSuffix === 'mega') {
                    // this.scene.sprinkleParticleEmitter.explode(16, this.x, this.y+20);
                }
                this.jumpRestTimer += delta;

            }
        }

        let anim = null;
        if (this.body.velocity.y !== 0) {
            anim = 'jump';
        }
        // 
        else if (this.body.velocity.x !== 0) {
            anim = 'run';
            if ((controlPressed.direction == 'left' || controlPressed.direction == 'right') && ((this.body.velocity.x > 0 && this.body.acceleration.x < 0) || (this.body.velocity.x < 0 && this.body.acceleration.x > 0))) {
                anim = 'turn';
            } else if (this.animSuffix !== 'small' && controlPressed.direction == 'down' && !(controlPressed.direction == 'right' || controlPressed.direction == 'left')) {
                anim = 'bend';
            }
        }
        //
        else {
            anim = 'stand';
            if (this.animSuffix !== 'small' && controlPressed.direction == 'down' && !(controlPressed.direction == 'right' || controlPressed.direction == 'left')) {
                anim = 'bend';
            }
        }

        anim += this.animSuffix;
        if (this.anims.currentAnim.key !== anim && !this.scene.physics.world.isPaused) {
            if (this.anims.currentAnim.key !== 'attack') this.anims.play(anim);
        }

        if (controlPressed.direction == 'down' && this.body.velocity.x < 100) {
            this.bending = true;
        }

        this.physicsCheck = true;


    }


    stopMovement() {
        this.body.setVelocityX(0);
        this.run(0);
    }

    run(vel) {
        this.body.setAccelerationX(vel);
    }

    jump() {
        if (!this.isOnTileOrPlatform() && !this.jumping) {
            return;
        }

        if (!this.jumping) {
            if (this.animSuffix === 'small') {
                this.scene.sfx.hop.play();

            } else {
                this.scene.sfx.jump.play();
            }
            if (this.animSuffix === 'mega') {
                this.scene.sprinkleParticleEmitter.explode(8, this.x, this.y);

            }


        }
        if (this.body.velocity.y < 0 || this.isOnTileOrPlatform()) {
            this.body.setVelocityY(-this.JUMP_VELOCITY);
        }
        if (!this.jumping) {
            this.jumpTimer = this.JUMP_MAX_TIMER;
        }
        this.jumping = true;
    }

    enemyBounce(enemy) {
        // Force Donut y-position up a bit (on top of the enemy) to avoid getting killed
        // by neigbouring enemy before being able to bounce
        this.body.y = enemy.body.y - this.body.height;
        // TODO: if jump-key is down, add a boost value to jump-velocity to use and init jump for controls to handle.
        this.body.setVelocityY(-550);
    }

    hurtBy(enemy) {
        if (!this.alive) {
            return;
        }
        if (this.star.active) {
            enemy.starKilled(enemy, this);
        } else if (this.wasHurt < 1) {
            if (this.animSuffix !== 'small') {
                this.resize(false);
                this.scene.sfx.hurt.play();

                this.wasHurt = 2000;
            } else {
                this.die();
            }
        }
    }

    resize(large) {
        this.scene.physics.world.pause();
        if (large) {
            this.large();
            this.animSuffix = 'big';
            this.play('growbig');
        } else {
            this.small();
            this.animSuffix = 'small';
            this.play('hurt');
        }
    }

    becomeMega() {
        this.scene.physics.world.pause();
        this.large();

        // this.large();
        this.animSuffix = 'mega';
        this.play('growmega');
    }


    die() {
        this.animSuffix = 'small';
        this.scene.control.bgm.stop();
        this.play('die');
        this.scene.sfx.die.play();


        this.body.setAcceleration(0);
        this.body.setVelocity(0, -600);
        this.alive = false;
        this.collider.destroy();
        this.mpCollider.destroy();
        this.scene.hudscene.updateLife(-1);
        this.scene.hudscene.levelEnd(false);

        //     this.scene.physics.world.collide(this, this.scene.groundLayer);

    }

    setRoomBounds(rooms) {
        rooms.forEach(
            (room) => {
                if (this.x >= room.x && this.x <= (room.x + room.width)) {
                    let cam = this.scene.cameras.main;
                    let layer = this.scene.groundLayer;
                    cam.setBounds(room.x, 0, room.width * layer.scaleX, layer.height * layer.scaleY);

                    this.scene.finishLine.active = (room.x === 0);
                    //forget sky
                    // this.scene.cameras.main.setBackgroundColor(room.sky);
                }
            }
        );
    }




    humanControl(keys, time, delta) {

        if (!this.isControllable) {
            return { direction: 'none', button: 'none' };
        }

        var input = {
            left: keys.left.isDown,
            right: keys.right.isDown,
            down: keys.down.isDown,
            up: keys.up.isDown,
            fire: keys.fire.isDown,
            jump: keys.jump.isDown

        };
        var dir = 'none';
        var btn = 'none';

        if (input.left) {
            dir = 'left';
        } else if (input.right) {
            dir = 'right';
        } else if (input.up) {
            dir = 'up';
        } else if (input.down) {
            dir = 'down';
        }

        if (input.fire) {
            btn = 'fire';
        } else if (input.jump) {
            btn = 'jump';
        }
        var r = { direction: dir, button: btn };
        return r;

    }

    setCustomSize(w, h) {
        var ws = w * 4 / 4,
            hs = h * 4 / 4;
        this.body.setSize(ws, hs, false);
        this.body.setOffset((40 - ws) / 2, (40 - hs) / 2);

    }
    large() {

        // this.setCustomSize(12, 30);
        // this.body.offset.y += 4;
        this.setCustomSize(12, 26);
        this.body.offset.y += 6;

    }
    small() {

        // this.setCustomSize(12, 14);
        // this.body.offset.y += 6;
        this.setCustomSize(12, 10);
        this.body.offset.y += 8;
    }






}