import Phaser from 'phaser';

export default class Checkpoint extends Phaser.Physics.Arcade.Sprite {
    constructor(config) {
        super(config.scene, config.x, config.y, config.key, config.rotation, config.id);
        config.scene.physics.world.enable(this);
        config.scene.add.existing(this);
        this.alpha = 0;

        if(config.rotation === 90) {
            this.displayWidth = 50;
            this.displayHeight = 700;
        } else {
            this.displayWidth = 700;
            this.displayHeight = 50;
        }
        
        this.id = config.id;
        this.setCollideWorldBounds(true);
    }
};
