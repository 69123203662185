import React, { Component } from 'react'
import {inject, observer} from 'mobx-react';
import Header from './Header';
import Decorations from './Decorations';
import ApplicationWindow from './ApplicationWindow';

@inject('DataStore')
@observer
class PopUp extends Component {
  render(props) {
    const {DataStore} = this.props;
        
    return (
      <div className={`score ${DataStore.pop_up_visible ? 'showscore' : 'hidescore'}`}>
        <Header />
        <Decorations />
        {DataStore.pop_up_visible ? <ApplicationWindow datastore={DataStore} /> : null}
      </div>
    )
  }
}

export default PopUp
