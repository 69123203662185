import React, { Component } from 'react'
import commaNumber from 'comma-number';

export default class SquishyButton extends Component {
  render(props) {
    return (
      <div>
        <a className={`squishy score_inc ${this.props.game_over}`}>Enter Score<span>score:{commaNumber(this.props.score)}</span></a>
      </div>
    )
  }
}
