import React, { Component } from 'react'
import Kart from './Kart';
import DataStore from '../../Components/Store/DataStore';

export default class KartComponent extends Component {
    render(props) {
        DataStore.game_id = 1;
        DataStore.game_name = "Krispy Kart";
        DataStore.initPixel();
        let kart = new Kart();
        return (
            <div></div>
        )
    }
}
