import React, { Component } from 'react'
import Fighter from './Fighterbun';
import DataStore from '../../Components/Store/DataStore';

export default class FighterbunComponent extends Component {
    render() {
        DataStore.game_id = 2;
        DataStore.game_name = "Doughnut Fighter";
        DataStore.initPixel();
        let fighter = new Fighter();
        return (
            <div></div>
        )
    }
}
