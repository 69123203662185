import Phaser from 'phaser';
import { CST } from '../CST';

export class KrispyTitleScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.KRISPYKART_TITLE });
    }

    init(data) {
        this.user_progress = false;
        this.active = false;
        this.kart = null;
        this.kart2 = null;
        this.kart3 = null;
    }

    create() {

        let cookie_dough = this.add.sprite(330, 330, 'cookie_dough');

        this.anims.create({
            key: 'pink_intro_kart_sprite',
            frames: [
                { key: 'pink_intro_kart' },
                { key: 'pink_intro_kart_move' , duration: 50 },
            ],
            frameRate: 7,
            repeat: -1
        });

        this.anims.create({
            key: 'choc_intro_kart_sprite',
            frames: [
                { key: 'choc_intro_kart' },
                { key: 'choc_intro_kart_move' , duration: 50 },
            ],
            frameRate: 7,
            repeat: -1
        });

        this.kart = this.add.sprite(400, 440+50, 'pink_intro_kart');
        this.kart.setScale(0.5);
        this.kart.play('pink_intro_kart_sprite');

        this.kart2 = this.add.sprite(0, 472+50, 'choc_intro_kart' );
        this.kart2.play('choc_intro_kart_sprite');
        this.kart2.setScale(0.5);       

        this.kart3 = this.add.sprite(-100, 448+50, 'choc_intro_kart');
        this.kart3.play('choc_intro_kart_sprite');
        this.kart3.setScale(0.5);
        
        document.addEventListener("button_b", function (event) {
            if (!this.user_progress && this.active) {
                this.user_progress = true;
                this.understood();
            }
        }.bind(this))
    }

    update() {

        this.active = this.scene.isActive(CST.SCENES.KRISPYKART_TITLE);
        this.kart.x += 2.00;
        this.kart2.x += 2.02;
        this.kart3.x += 2.01;

        if(this.kart.x > this.sys.game.config.width + 200) {
            this.kart.x = -200;
        }

        if(this.kart2.x > this.sys.game.config.width + 200) {
            this.kart2.x = -200;
        }

        if(this.kart3.x > this.sys.game.config.width + 200) {
            this.kart3.x = -200;
        }
    }

    understood() {
        this.scene.start(CST.SCENES.INSTRUCTIONS, 'Instructions');
    }
}