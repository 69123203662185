import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import DataStore from './Components/Store/DataStore';
import { Provider } from 'mobx-react';
import { HashRouter } from 'react-router-dom';

const Root = (
    <HashRouter>
        <Provider DataStore={DataStore}>
            <App />
        </Provider>
    </HashRouter>
);

ReactDOM.render(Root, document.getElementById('root'));
serviceWorker.unregister();
