import Phaser from 'phaser';

import Box from './Sprites/Box';
import Ball from './Sprites/Ball';
import Coin from './Sprites/Coin';
import Heart from './Sprites/Heart';

import Doughnut from './Sprites/Doughnut';
import DataStore from '../../../Components/Store/DataStore';

import { CST } from '../CST';

export class BrownieScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.GAME });

        this.CustomPipeline2 = new Phaser.Class({

            Extends: Phaser.Renderer.WebGL.Pipelines.TextureTintPipeline,

            initialize:

                function CustomPipeline2(game) {
                    Phaser.Renderer.WebGL.Pipelines.TextureTintPipeline.call(this, {
                        game: game,
                        renderer: game.renderer,
                        fragShader: ["precision mediump float;",

                            "uniform float     time;",
                            "uniform vec2      resolution;",
                            "uniform sampler2D uMainSampler;",
                            "uniform vec2      mouse ;",
                            "varying vec2 outTexCoord;",

                            "float noise(vec2 pos) {",
                            "return fract(sin(dot(pos, vec2(12.9898 - time,78.233 + time))) * 43758.5453);",
                            "}",

                            "void main( void ) {",

                            "//vec2 normalPos = gl_FragCoord.xy / resolution.xy;",
                            "vec2 normalPos = outTexCoord;",
                            "vec2 pointer = mouse / resolution;",
                            "float pos = (gl_FragCoord.y / resolution.y);",
                            "float mouse_dist = length(vec2((pointer.x - normalPos.x) * (resolution.x / resolution.y), pointer.y - normalPos.y));",
                            "float distortion = clamp(1.0 - (mouse_dist + 0.1) * 3.0, 0.0, 1.0);",

                            "pos -= (distortion * distortion) * 0.1;",

                            "float c = sin(pos * 400.0) * 0.4 + 0.4;",
                            "c = pow(c, 0.2);",
                            "c *= 0.2;",

                            "vec4 pixel = texture2D(uMainSampler, outTexCoord);",

                            "gl_FragColor = pixel + vec4( 0.0, c, 0.0, 1.0 );",
                            "}"

                        ].join('\n')
                    });
                }
        });
    }

    init() {

        if (DataStore.easter) {
            this.customPipeline = this.game.renderer.addPipeline('Custom', new this.CustomPipeline2(this.game));
            this.customPipeline.setFloat2('resolution', this.game.config.width, this.game.config.height);
            this.cameras.main.setRenderToTexture(this.customPipeline);
        }

        /* =================================== */
        this.START_LVL = 1;
        /* =================================== */

        this.WIDTH = 660;
        this.HEIGHT = 660;
        this.START_POSITION = (this.HEIGHT - 98);
        this.RAD_10DEG = Math.PI / 18;
        this.MAX_ANGLE = this.RAD_10DEG * 6.5;
        this.RANDOM_ANGLE_RANGE = this.RAD_10DEG;


        this.SPEED_INCREMENT_PERCENT = 2;
        this.SUPER_TIME = 4000;
        this.MAX_SUPER_BOUNCE = 3;
        this.MAX_LEVEL = 20;
        //
        this.INTRODUCE_4PACK_LEVEL_FROM = 5; //1~20
        //
        this.pendingLevel = false;
        this.pendingStart = false;
        this.superMode = false;
        this.currentSuperBounce = 0;
        this.boxSpeed = 10;
        this.boxBonus = false;
        this.dead = false;
        this.currentLevel = this.START_LVL;
        this.baseVelocity = 310;
        this.levelVelocity = 1;
        this.hasStarted = false;
        this.pausedBySuper = false;

        this.hudscene = this.scene.get(CST.SCENES.HUD);
        this.soundscene = this.scene.get(CST.SCENES.SOUND);
        this.control = this.scene.get(CST.SCENES.CONTROL);

        this.time.delayedCall(1000, function() {
            this.pendingStart = true;
        }, [], this);


        this.anims.create({
            key: 'explosion',
            frames: this.anims.generateFrameNumbers('explosion', {
                start: 0,
                end: 3,
                first: 0

            })
        });


    }

    create() {

        this.physics.world.setBoundsCollision(true, true, true, false);
        this.physics.world.bounds = new Phaser.Geom.Rectangle(0, 50, this.WIDTH, this.HEIGHT);

        this.background = this.add.image(330, 330, 'background').setScale(1);

        //Create Particle Emitter for Hero
        this.particles = this.add.particles('spark');
        this.emitter = this.particles.createEmitter({
            speed: 50,
            scale: { start: 1, end: 1 }
        });

        this.eat_particles = this.add.particles('choc_spark')
        this.eat_emitter = this.eat_particles.createEmitter({

            x: { min: -300, max: 300, steps: 20 },
            x: { min: -300, max: 300, steps: 20 },
            speed: { min: -600, max: 600 },
            angle: { min: 0, max: 360 },
            scale: { start: 1, end: 1 },
            lifespan: 400,
            gravityY: 800,
            frequency: -1
        });

        this.super_particles = this.add.particles('spark');
        this.super_emiitter = this.super_particles.createEmitter({
            speed: 50,
            scale: { start: 1, end: 1 },
            tint: [0x584525, 0x98d477, 0xa7dde4, 0xe78437, 0xf3a7c0, 0x9462a9, 0x367ca0]
        });

        this.super_emiitter.active = false;

        this.ghostie = this.make.image({ x: -100, y: -100, key: 'ghostie' }).setScale(1);
        this.ghostieTween = this.tweens.add({
            targets: this.ghostie,
            y: this.HEIGHT / 2,
            alpha: 0,
            delay: 400,
            duration: 3000,
            ease: 'Power2',
            paused: true
        });

        this.ball = new Ball({
            scene: this,
            key: 'static',
            x: this.WIDTH / 2,
            y: this.START_POSITION
        }).setCollideWorldBounds(true).setBounce(1).setOrigin(0.5, 0.5)

        this.ball.idle();
        this.ball.body.onWorldBounds = true;

        this.physics.world.on('worldbounds', function(body) {

            this.soundscene.dding1.play();


        }, this);

        this.super_emiitter.startFollow(this.ball);
        this.emitter.startFollow(this.ball);
        this.ball.body.onCollide = true;

        this.box = new Box({
            scene: this,
            key: 'box',
            x: this.WIDTH / 2,
            y: this.HEIGHT - 30
        });

        this.doughnuts = [
            //{ key: 'choc_sprinkles' },
            { key: 'shake' },
            { key: 'bagel' },
            { key: 'choc' },
            { key: 'coffee' },
            { key: 'doughnut' },
            // { key: 'heart' },
            { key: 'original' },
            { key: 'sprinkles' },
            { key: 'sundae' },
            // { key: 'brick' },

        ];

        this.levels = [
            //test level
            // { lvl: 1, rows: 4, arrayLength: 7, speed: 1, bricks: 0 },

            { lvl: 1, rows: 1, arrayLength: 7, speed: 1.2, bricks: 0 },
            { lvl: 2, rows: 1, arrayLength: 7, speed: 1.2, bricks: 0 },
            { lvl: 3, rows: 2, arrayLength: 6, speed: 1.3, bricks: 1 },
            { lvl: 4, rows: 2, arrayLength: 6, speed: 1.4, bricks: 1 },
            { lvl: 5, rows: 3, arrayLength: 5, speed: 1.4, bricks: 1 },
            { lvl: 6, rows: 3, arrayLength: 5, speed: 1.5, bricks: 1 },
            { lvl: 7, rows: 3, arrayLength: 4, speed: 1.6, bricks: 1 },
            { lvl: 8, rows: 4, arrayLength: 4, speed: 1.4, bricks: 2 },
            { lvl: 9, rows: 4, arrayLength: 3, speed: 1.4, bricks: 2 },
            { lvl: 10, rows: 4, arrayLength: 3, speed: 1.5, bricks: 2 },

            { lvl: 11, rows: 1, arrayLength: 2, speed: 2.0, bricks: 2 },
            { lvl: 12, rows: 2, arrayLength: 2, speed: 1.6, bricks: 2 },
            { lvl: 13, rows: 3, arrayLength: 1, speed: 1.8, bricks: 3 },
            { lvl: 14, rows: 4, arrayLength: 1, speed: 2.0, bricks: 3 },
            { lvl: 15, rows: 4, arrayLength: 1, speed: 2.2, bricks: 3 },
            { lvl: 16, rows: 4, arrayLength: 1, speed: 2.4, bricks: 3 },
            { lvl: 17, rows: 4, arrayLength: 1, speed: 2.6, bricks: 3 },
            { lvl: 18, rows: 4, arrayLength: 1, speed: 2.8, bricks: 3 },
            { lvl: 19, rows: 4, arrayLength: 1, speed: 3.0, bricks: 3 },
            { lvl: 20, rows: 4, arrayLength: 1, speed: 3.4, bricks: 4 }




        ];

        this.doughnut_group = this.physics.add.staticGroup({
            collideWorldBounds: true
        });
        this.super_effect_line_group = this.add.group();
        this.super_effect_dot_group = this.add.group();

        this.drops_group = this.add.group();

        this.generateLevel();

        this.doughnutCollider = this.physics.add.collider(this.ball, this.doughnut_group, this.doughnutCollision, null, this);
        this.boxCollider = this.physics.add.collider(this.ball, this.box, this.boxCollision, null, this);
        this.boxCollider.overlapOnly = true;
        //
        // this.doughnutCollider.overlapOnly = true;

        this.boxImpact = this.add.sprite(0, 0, 'explosion').setOrigin(0.5).setAlpha(0).setScale(4);

        this.boxImpact.on('animationstart', function(anim, frame) {
            this.alpha = 1;
        }, this.boxImpact);
        this.boxImpact.on('animationcomplete', function(anim, frame) {
            this.alpha = 0;
        }, this.boxImpact);
        //DEBUG
        // this.debug_text = this.add.text(36, 46, '', {
        //     fontSize: '20px',
        //     fill: '#000000'
        // });

    }

    update() {


        // TEST button
        if (this.control.keys.test.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;
                console.log("test key pressed");

                // this.ball.body.velocity.setToPolar(0.5 * Math.PI, (this.baseVelocity * this.levelVelocity));

                // this.generateLevel();

                // this.scene.pause();

                // this.box.fourPack();

                // this.hudscene.current_super = 0;
                // this.hudscene.player_stat.super_full.setAlpha(1);
                // this.enterSuperMode();

            }
        }

        this.drops_group.getChildren().forEach((drop) => {
            if (drop.y >= 660) {
                drop.destroy();
            }
        })


        if (this.dead) {
            return;
        }
        if (this.pendingLevel) {
            return;
        }

        let box_pos = this.box.x;
        if (this.control.keys.left.isDown) {
            box_pos -= this.boxSpeed;
        } //
        else if (this.control.keys.right.isDown) {
            box_pos += this.boxSpeed;
        }
        let boxBoundaryOffset = 80;
        if (this.box.isOnFourPack) {
            boxBoundaryOffset = 56;
        } //


        this.box.x = Phaser.Math.Clamp(box_pos, 0 + boxBoundaryOffset, 660 - boxBoundaryOffset + 4);

        if (this.ball.onPaddle) {
            this.ball.x = this.box.x;
        }
        if (this.pendingStart) {
            if (this.control.keys.green.isDown || this.control.keys.pink.isDown)
                this.start();
        }


        if (this.ball.y >= (this.HEIGHT - 40)) {
            this.dead = true;
            this.ghostieRelease();
            this.soundscene.aww.play();

            this.hudscene.lifeLost();

            this.box.dead();

            if (this.superMode) {
                this.ball.superDead();
            } else {
                this.ball.dead();
            }

            this.cameras.main.shake(30, 0.005);
            this.ball.setVelocity(0);
            this.time.delayedCall(1000, function() {


                this.resetBall();
            }, [], this);
        }
        this.ball.updateAngle();

    }

    updateDebugtext(ball, box) {

        let magnitude = ball.body.velocity.length(),
            angleRad = ball.body.velocity.angle();

        let limit = box.body.halfWidth;

        let diff = Phaser.Math.Distance.Between(box.x, 0, ball.x, 0);

        let accuracyFactor = (diff / limit);
        //1 = ball hit waaay off centre. 0 + ball hit right in the cenre.

        if (accuracyFactor > 1) accuracyFactor = 1;


        if (this.debug_text != undefined) {
            this.debug_text.setText(
                'accuracy:' + accuracyFactor.toFixed(2) +
                '\nmagnitude:' + magnitude.toFixed(2) +
                '\nangle:' + Phaser.Math.RadToDeg(angleRad).toFixed(2)
            );
        }
    }
    turnOff(item) {
        if (this.box.isOnFourPack) {

            let fourX = this.add.image(item.x, item.y, '4x').setScale(2.4);
            let fourXTween = this.tweens.add({
                targets: fourX,
                scaleX: 1.2,
                scaleY: 1.2,
                duration: 300,
                ease: 'Power2',
                onComplete: function() {
                    fourX.destroy();
                }
                // onCompleteScope: this
            });
        }
        this.eat_emitter.setPosition(item.x, item.y);
        this.eat_emitter.explode(50);

        // item.destroy(); //<-- DO NOT USE destroy. will strangely cause confusion on children index
        item.disableBody(true, true);
    }


    chainReaction(doughnut, doughnut_type) {

        let tempCoord = null;
        let startCoord = null,
            endCoord = null;

        let thisDonutCoord = null;
        let trueStartCoord = new Phaser.Math.Vector2(doughnut.x, doughnut.y);
        let ar = this.doughnut_group.children.entries;
        let targetNumber = 0;
        let trueStartIndex = -1,
            topLeftIndex = -1;

        let t_line = 0,
            t_dot = 0,
            t_increment = 100;
        for (let i = 0; i < ar.length; i++) {
            if (ar[i].texture.key === doughnut_type && ar[i].body.enable) {
                targetNumber++;
                if (topLeftIndex == -1) {
                    topLeftIndex = i;
                }

                if (ar[i].x == trueStartCoord.x && ar[i].y == trueStartCoord.y) {

                    trueStartIndex = i;

                }

            }

        }

        if (targetNumber > 1) {

            //move start point donut to the beginning of the array
            var temp = ar[topLeftIndex];
            ar[topLeftIndex] = ar[trueStartIndex];
            ar[trueStartIndex] = temp;
            this.soundscene.attack.play('',{delay:0.2, loop:true});

            //
            // this.soundscene.yah(targetNumber);

            // Mass destruction begins
            this.doughnut_group.getChildren().forEach((child_doughnut) => {

                if (child_doughnut.texture.key === doughnut_type && child_doughnut.body.enable) {
                    if (doughnut_type === 'brick') {
                        this.hudscene.updateScore(2000);
                    } //
                    else {
                        this.hudscene.updateScore(1000);
                    }


                    //
                    thisDonutCoord = new Phaser.Math.Vector2(child_doughnut.x, child_doughnut.y);

                    if (tempCoord === null) { //starting coord
                    } //
                    else {
                        startCoord = tempCoord.clone();
                    }
                    tempCoord = thisDonutCoord.clone();;
                    endCoord = thisDonutCoord.clone();
                    t_line += t_increment;
                    this.drawChain(startCoord, endCoord, t_line);

                    this.time.delayedCall(t_line + 150, function() {

                        this.turnOff(child_doughnut);
                    }, [], this);

                } //

            });

            let superSonicBall = this.add.image(this.ball.x, this.ball.y, 'super_nut');

            this.doughnut_group.getChildren().forEach((child_doughnut) => {

                if (child_doughnut.texture.key === doughnut_type && child_doughnut.body.enable) {

                    t_dot += t_increment;

                    let dot = this.add.image(child_doughnut.x, child_doughnut.y, 'dot').setScale(0);
                    this.super_effect_dot_group.add(dot);

                    this.tweens.timeline({

                        tweens: [ //

                            {
                                targets: superSonicBall,
                                alpha: 1,
                                duration: 20,
                                delay: t_dot


                            },
                            {
                                targets: superSonicBall,
                                rotation: (Phaser.Math.Angle.Between(superSonicBall.x, superSonicBall.y, dot.x, dot.y) + (Math.PI * 0.5)),
                                duration: 2,
                                onStart: function() {
                                    // console.log(superSonicBall.x, superSonicBall.y, dot.x, dot.y, Phaser.Math.Angle.Between(superSonicBall.x, superSonicBall.y, dot.x, dot.y) + (Math.PI * 0.5));
                                },
                                onComplete: function() {
                                    superSonicBall.setRotation(Phaser.Math.Angle.Between(superSonicBall.x, superSonicBall.y, dot.x, dot.y) + (Math.PI * 0.5));
                                    // console.log(superSonicBall.x, superSonicBall.y, dot.x, dot.y, Phaser.Math.Angle.Between(superSonicBall.x, superSonicBall.y, dot.x, dot.y) + (Math.PI * 0.5));
                                }

                            },
                            {
                                targets: superSonicBall,
                                x: dot.x,
                                y: dot.y,
                                duration: 100

                            },

                            {
                                targets: dot,
                                scaleX: 2.4,
                                scaleY: 2.4,
                                ease: 'Power2',
                                duration: 60
                                // ,
                                // delay: t_dot

                            },
                            {
                                targets: dot,
                                scaleX: 0,
                                scaleY: 0,
                                ease: 'Power2',
                                duration: 120
                            }
                        ]

                    });

                } //
            });
            this.ball.setAlpha(0);

            this.pausedBySuper = true;
            this.physics.world.pause();

            this.time.delayedCall(t_line + t_increment + 200+100, function() {
                superSonicBall.setRotation(Phaser.Math.Angle.Between(superSonicBall.x, superSonicBall.y, this.ball.x, this.ball.y) + (Math.PI * 0.5));

                this.tweens.add({
                    targets: superSonicBall,
                    x: this.ball.x,
                    y: this.ball.y,
                    // ease: 'Power2',
                    duration: 250+100,
                    onComplete: function() {

                        this.soundscene.attack.stop();

                        this.ball.setAlpha(1);
                        this.ball.body.velocity.setToPolar(0.5 * Math.PI, (this.baseVelocity * this.levelVelocity));

                        superSonicBall.destroy();

                        this.pausedBySuper = false;
                        this.physics.world.resume();
                        this.super_effect_dot_group.clear(true, true);
                        this.super_effect_line_group.clear(true, true);

                        this.checkLeftOvers();

                    },
                    onCompleteScope: this


                });

            }, [], this);

        } // only 1 target. No fancy chain reaction.
        else {
            this.turnOff(doughnut);
            if (doughnut_type === 'brick') {
                this.hudscene.updateScore(2000);
            } //
            else {
                this.hudscene.updateScore(1000);
            }


        }

        this.currentSuperBounce++;
        if (this.currentSuperBounce === this.MAX_SUPER_BOUNCE) {
            this.exitSuperMode();

        }

    }

    drawChain(dot1, dot2, t) {

        if (dot1 == null) {
            return;
        }

        //line image is 10px wide
        //d is distance in pixels

        let d = Phaser.Math.Distance.Between(dot1.x, dot1.y, dot2.x, dot2.y);
        let s = d * 0.1;
        //r is angle in radians
        let r = Phaser.Math.Angle.Between(dot1.x, dot1.y, dot2.x, dot2.y);

        let line = this.add.image(dot1.x, dot1.y, 'line').setScale(0, 2).setOrigin(0, 0.5).setRotation(r);

        this.super_effect_line_group.add(line);

        // this.time.delayedCall(t, function() {
        //     line.setScale(s, 1);
        // }, [], this);
        // this.tweens.add({
        //     targets: line,
        //     scaleX: s,
        //     duration: 100,
        //     delay: t
        // });


        this.tweens.timeline({

            tweens: [{

                    targets: line,
                    scaleX: s,
                    ease: 'Power2',
                    duration: 100,
                    delay: t

                },
                {
                    targets: line,
                    scaleY: 0,
                    ease: 'Power2',
                    duration: 100
                }
            ]

        });

    }
    doughnutCollision(ball, doughnut) {

        this.soundscene.ddong.play();
        let doughnut_type = doughnut.texture.key;

        //SUPER MODE
        if (this.superMode) {
            this.chainReaction(doughnut, doughnut_type);
        }
        //
        // NOT SUPER MODE
        else {
            if (doughnut_type === 'brick') {
                return;
            }
            //
            else if (doughnut_type === "sundae") {
                this.doughnut_group.create(doughnut.x, doughnut.y, "sundae_1").setScale(.7);

            } //
            else if (doughnut_type === "sundae_1") {
                this.doughnut_group.create(doughnut.x, doughnut.y, "sundae_2").setScale(.6);
            }

            this.turnOff(doughnut);
            this.hudscene.updateScore(1000);
            this.hudscene.updateSuper();

        }

        if (doughnut_type === "choc_sprinkles") {
            if (this.doughnut_group.countActive() !== 0) {
                let coin = new Coin({
                    scene: this,
                    key: 'coin_front',
                    x: doughnut.x,
                    y: doughnut.y
                });
                this.drops_group.add(coin);
            }
        } //
        else if (doughnut_type === "heart") {
            //deploy heart

            if (this.doughnut_group.countActive() !== 0) {
                let heart = new Heart({
                    scene: this,
                    key: 'heart',
                    x: doughnut.x,
                    y: doughnut.y
                });
                this.drops_group.add(heart);
            }
        } //

        this.checkLeftOvers();

    }

    checkLeftOvers() {
        if (this.doughnut_group.countActive() === 0) {
            //Level cleared
            this.currentLevel += 1;
            if (this.currentLevel > this.MAX_LEVEL) this.currentLevel = this.MAX_LEVEL; // MAX LEVEL 20

            this.pendingLevel = true;
            this.resetBall();
            this.soundscene.nextlevel.play('', { delay: 0.4 });
            this.hudscene.nextLevel();
            this.nextLevel();

        }
    }
    nextLevel() {
        this.soundscene.bgm.pause();
        this.soundscene.bgm.play('', { seek: 0, delay: 1.6 });

        this.resetBall();
        this.levelVelocity = this.levels[this.currentLevel].speed;
        this.time.delayedCall(500, function() {
            this.generateLevel();
        }, [], this);

        this.time.delayedCall(1800, function() {
            this.pendingLevel = false;

        }, [], this);




    }

    generateLevel() {
        this.drops_group.clear(true, true);
        this.doughnut_group.clear(true, true);
        this.super_effect_dot_group.clear(true, true);
        this.super_effect_line_group.clear(true, true);

        let isHeartAdded = false;

        let heartLocation = { i: Phaser.Math.Between(0, 7), j: Phaser.Math.Between(0, this.levels[this.currentLevel - 1].rows) }

        let itemLocation = this.generateItemLocation();
        for (var i = 0; i < 8; i++) {
            for (var j = 0; j < this.levels[this.currentLevel - 1].rows; j++) {

                var dn = new Doughnut({
                    scene: this,
                    x: 50 + i * 80,
                    y: 130 + j * 80
                }).setBounce(1);


                let levelObj = this.levels[this.currentLevel - 1];
                let doughnutObj = this.doughnuts[Phaser.Math.Between(0, this.doughnuts.length - levelObj.arrayLength)];

                let randomKey = doughnutObj.key;


                if (this.addBrick(i, j, levelObj)) {
                    randomKey = 'brick';
                }


                if (i === itemLocation.heart.i && j === itemLocation.heart.j) {
                    randomKey = 'heart';
                } //
                else if (i === itemLocation.chocSprinkles.i && j === itemLocation.chocSprinkles.j) {
                    if (this.currentLevel >= this.INTRODUCE_4PACK_LEVEL_FROM) {
                        randomKey = 'choc_sprinkles';
                    }
                }

                this.doughnut_group.create(dn.x, dn.y,
                    randomKey, "", true, (randomKey === 'brick' ? false : true)
                ).setBounce(1).setScale(.8);
            }
        }


    }
    generateItemLocation() {
        let i, j, k, l;
        i = j = k = l = 0;


        while (i === k && j === l) {
            //loops while condition is true
            i = Phaser.Math.Between(0, 7);
            j = Phaser.Math.Between(0, this.levels[this.currentLevel - 1].rows - 1);
            k = Phaser.Math.Between(0, 7);
            l = Phaser.Math.Between(0, this.levels[this.currentLevel - 1].rows - 1);
        }

        return {
            heart: {
                i: i,
                j: j
            },
            chocSprinkles: {
                i: k,
                j: l
            },
        };

    }

    addBrick(col, row, levelObj) {

        // brickChance hurdle
        // 110, 90, 70, 50, 30
        // 0%, 10%, 30%, 50%, 70% 

        let brickChance = levelObj.bricks; //0~4

        let brickChanceHurdle = (4 - brickChance) * 20 + 30; //110, 90, 70, 50, 30
        let isBrick = false;
        let randNum = 0;
        if (row !== 1 && row !== 3) {
            if (col !== 2 && col !== 5) {
                randNum = Phaser.Math.Between(0, 100);
                isBrick = (randNum > brickChanceHurdle) ? true : false;
                if (isBrick) {
                    // console.log(col, row, 'random and hurdle:', randNum, brickChanceHurdle, 'brick generated:', isBrick)


                }
            }
        }
        return isBrick;

    }


    boxCollision(ball, box) {
        this.boxImpact.setPosition(ball.x, box.y - box.body.halfHeight);
        this.boxImpact.play('explosion');

        ball.y -= 4;
        this.soundscene.dding2.play();

        let magnitude = ball.body.velocity.length() * (1 + 0.01 * this.SPEED_INCREMENT_PERCENT);
        let angleRad = ball.body.velocity.angle();


        let limit = box.body.halfWidth;
        let diff = Phaser.Math.Distance.Between(box.x, 0, ball.x, 0);
        let accuracyFactor = (diff / limit).toFixed(2);

        //1 = ball hit waaay off centre. 0 = ball hit right in the centre.
        if (accuracyFactor > 1) accuracyFactor = 1;

        angleRad = (ball.x < box.x) ? -0.5 * Math.PI - accuracyFactor * this.MAX_ANGLE : angleRad = -0.5 * Math.PI + accuracyFactor * this.MAX_ANGLE;

        angleRad += Phaser.Math.Between(-this.RANDOM_ANGLE_RANGE * 1000, this.RANDOM_ANGLE_RANGE * 100) / 1000;

        ball.body.velocity.setToPolar(angleRad, magnitude);

        // console.log('OUT accuracy:', accuracyFactor, 'magnitude:', magnitude, 'angle:', Phaser.Math.RadToDeg(angleRad));
    }

    resetBall() {
        this.exitSuperMode();
        this.hudscene.isNotPaused();

        this.hasStarted = false;
        this.dead = false;
        this.boxBonus = false;
        this.boxSpeed = 10;
        this.box.twelvePack();
        // this.doughnutCollider.overlapOnly = false;

        this.ball.setVelocity(0);
        this.ball.setPosition(this.box.x, this.START_POSITION);
        this.ball.onPaddle = true;

        this.ball.idle();
    }

    start() {
        if (this.hasStarted) {
            return;
        }
        this.levelVelocity = this.levels[this.currentLevel - 1].speed;
        this.hasStarted = true;
        this.ball.flying();
        if (this.superMode) {
            this.ball.super();
        }
        this.soundscene.woosh.play();

        this.boxBonus = false;
        if (this.ball.onPaddle) {
            // this.ball.setVelocity(-75, (this.baseVelocity * this.levelVelocity) * -1);
            this.ball.body.velocity.setToPolar(-0.5 * Math.PI, (this.baseVelocity * this.levelVelocity));
            this.ball.onPaddle = false;
        }
    }

    end(score) {

        let passingData = {
            score: {
                base: this.hudscene.score_number,
                completionBonus: this.currentLevel * 1000,
                heartBonus: this.hudscene.extraHeart * 3000
            }

        };

        this.scene.stop(CST.SCENES.HUD);
        // this.scene.start(CST.SCENES.SCORE, { score: score });
        this.scene.start(CST.SCENES.SCORE, passingData);
    }

    ghostieRelease() {
        this.ghostie.setAlpha(1).setPosition(this.ball.x, this.ball.y);
        this.ghostieTween.restart();

    }

    enterSuperMode() {
        this.superMode = true;
        this.ball.super();
        // Super Doughnut travels THROUGH and does not bounce back
        // this.doughnutCollider.overlapOnly = true;

        this.super_emiitter.active = true;
        this.super_emiitter.on = true;
        this.soundscene.bgm.rate = 1.2;

        this.soundscene.super.play('', { delay: 0.5 });

        // this.time.delayedCall(this.SUPER_TIME, function() {
        //     this.exitSuperMode();
        // }, [], this)
    }
    exitSuperMode() {

        if (this.superMode) {
            this.superMode = false;

            // this.doughnutCollider.overlapOnly = false;
            this.currentSuperBounce = 0;
            this.soundscene.bgm.rate = 1;
            this.ball.idle();
            this.super_emiitter.on = false;
            //
            this.hudscene.endSuper();
        }


    }


}