import Phaser from 'phaser';
import { CST } from '../CST';
import Bun from '../Sprites/Bun';
import Background from '../Sprites/Background';

export class FighterbunScene extends Phaser.Scene {

    constructor() {
        super({ key: CST.SCENES.GAME });
    }

    create() {

        this.control = this.scene.get(CST.SCENES.CONTROL);

        /* World */
        this.BG_W = 960;
        this.BG_H = 720;
        this.BG_CENTRE_X = this.BG_W * 0.5;
        this.BG_CENTRE_Y = this.BG_H * 0.5;

        this.SCREEN_W = 660;
        this.SCREEN_H = 660;
        this.SCREEN_CENTRE_W = this.SCREEN_W * 0.5;
        this.SCREEN_CENTRE_H = this.SCREEN_H * 0.5;

        /* Camera */
        this.ENABLE_ZOOM = true;
        this.MAX_ZOOM_SCALE = 1.2;
        this.MIN_ZOOM_SCALE = 1;
        this.MAX_ZOOM_BUNDISTANCE = 140;
        this.MIN_ZOOM_BUNDISTANCE = 320;

        /* Impact particle numbers*/
        this.IMPACT_PARTICLE_NUM = 6;

        /* HUD Reference */
        this.hudscene = this.scene.get(CST.SCENES.HUD);

        this.bg = new Background({
            scene: this,
            x: this.BG_CENTRE_X,
            y: this.BG_CENTRE_Y
        });


        this.focalpoint = this.add.sprite(this.BG_CENTRE_X, this.BG_CENTRE_Y);


        // Create Characters
        this.enemybun = new Bun({
            scene: this,
            key: 'bun',
            prefix: 'char4',
            enemyPrefix: 'char3',
            x: this.BG_CENTRE_X + 200,
            y: this.BG_CENTRE_Y + 20,
            isAI: true, //AI
            isDummy: false,
            difficulty: 3 //0~5
        });

        this.playerbun = new Bun({
            scene: this,
            key: 'bun',
            prefix: 'char3',
            enemyPrefix: 'char4',
            x: this.BG_CENTRE_X - 200,
            y: this.BG_CENTRE_Y + 20,
            isAI: false, //Player
            isDummy: false
        });

        // Camera follows Bun
        this.cameras.main.setBounds(0, 0, 960, 720);
        this.cameras.main.startFollow(this.focalpoint, true, 0.9, 0.9, 0, );


        // Impact particle
        var impactParticleManager = this.add.particles('impact_particle'); //create particle manager
        this.impactParticleEmitter = impactParticleManager.createEmitter({
            lifespan: 200,
            speed: { min: 1500, max: 400 },
            gravityY: 0,
            scale: { min: 0.3, max: 1.8 },
            frequency: -1 //explode mode
        }); // create particle emitter




        this.imp = [this.add.sprite(0, 0, 'impact'),
            this.add.sprite(0, 0, 'impact'),
            this.add.sprite(0, 0, 'impact'),
            this.add.sprite(0, 0, 'impact'),
            this.add.sprite(0, 0, 'impact')
        ];

        this.imp.forEach(function(elem, index) {
            elem.setScale(Phaser.Math.Between(30, 50) * 0.1);
            elem.setOrigin(0.5, 0.5);
            elem.setDepth(elem.depth + 2);
            elem.on('animationcomplete', function() { this.alpha = 0; }, elem);
        });

        this.impIndex = 0;

        this.cameras.main.fadeIn(1000);



        this.hudscene.events.once('end_round', function(winner, loser, playerWon) {
            this.roundEnd(winner, loser, playerWon);
        }, this);

    }

    update(time, delta) {

        // TEST button
        if (this.control.keys.test.isDown) {
            this.control.keyPressed = true;
        } else {
            if (this.control.keyPressed) {
                this.control.keyPressed = false;
                console.log("test key pressed");
                
                // this.bg.superFinishBg();
                // this.events.emit('building_rage', { rage: 100, isAI: false });
                // this.generateImpact(this.enemybun);
                // this.events.emit('test_HUD_event', 'event test');

            }


        }


        if (this.physics.world.isPaused) {
            return;
        }

        // Run the update method of characters

        this.playerbun.update(this.control.keys, time, delta, this.enemybun, this);
        this.enemybun.update(this.control.keys, time, delta, this.playerbun, this);

        // Focal point for camera
        this.focalpoint.x = Phaser.Math.Average([this.playerbun.x, this.enemybun.x]);
        this.focalpoint.y = Phaser.Math.Average([this.playerbun.y, this.enemybun.y]);

        this.bg.update(time, delta, this.cameras.main.getWorldPoint(this.SCREEN_CENTRE_W, this.SCREEN_CENTRE_H), this);

        this.updateCameraZoom(this.playerbun, this.enemybun);

    }

    updateScore(score) {
        this.score.pts += score;
        this.score.textObject.setText(('' + this.score.pts).padStart(6, '0'));
    }

    generateImpact(victim) {

        this.cameras.main.shake(60, 0.02);

        // var impactX = (this.playerbun.x + this.enemybun.x) * 0.5 * Phaser.Math.Between(96, 104) * 0.01;
        // var impactY = (this.playerbun.y + this.enemybun.y) * 0.5 * Phaser.Math.Between(96, 104) * 0.01;

        var impactX = victim.x;
        var impactY = victim.y;

        var randX = Phaser.Math.Between(30, 50);
        var randY = Phaser.Math.Between(0, 60) - 30;

        impactX += victim.flipX ? -randX : randX;
        impactY += randY;

        var imp = this.imp[this.impIndex];
        imp.setPosition(impactX, impactY);
        imp.flipX = !victim.flipX;
        imp.alpha = 1;
        imp.anims.play('pow');
        this.impIndex++;
        if (this.impIndex == this.imp.length) {
            this.impIndex = 0;
        }

        this.impactParticleEmitter.explode(this.IMPACT_PARTICLE_NUM, impactX, impactY);

    }


    roundEnd(winner, loser, playerWon) {
        loser.roundLose();
        winner.roundWin();

        this.hudscene.events.off('end_round');

        // this.events.off('combo_end');
        this.events.off('took_damage');
        this.events.off('show_combo');
        this.events.off('building_rage');


    }

    updateCameraZoom(a, b) {

        if (this.ENABLE_ZOOM) {

            let bunDistance = Math.abs(a.x - b.x);

            let updatedZoomScale = this.MIN_ZOOM_SCALE;

            if (bunDistance <= this.MAX_ZOOM_BUNDISTANCE) {
                updatedZoomScale = this.MAX_ZOOM_SCALE;
            } else if (bunDistance < this.MIN_ZOOM_BUNDISTANCE) {

                updatedZoomScale = (this.MIN_ZOOM_BUNDISTANCE - bunDistance) /
                    (this.MIN_ZOOM_BUNDISTANCE - this.MAX_ZOOM_BUNDISTANCE) * (this.MAX_ZOOM_SCALE - this.MIN_ZOOM_SCALE) +
                    this.MIN_ZOOM_SCALE;

            }

            this.cameras.main.zoom = updatedZoomScale;
        }

    }

}